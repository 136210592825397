import React, { useEffect, useState, useContext } from "react";

import MUIDataTable from "mui-datatables";
import { connect } from "react-redux";
//import * as actions from "../../../../actions/class";
import * as actions from "../../../../actions/course";

import FormDialogAddCourses from "../formDialog/FormDialogAddCourses";
import FormDialogEditCourses from "../formDialog/FormDialogEditCourses";
import FormDialogDeleteCourses from "../formDialog/FormDialogDeleteCourses";
import moment from 'moment/min/moment-with-locales';
import { Link, useNavigate, useLocation } from "react-router-dom";
import PlayCircle from '@mui/icons-material/PlayCircle';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import sha1 from 'crypto-js/sha1'
import { AuthContext } from "../../../../context/AuthContext";
import _ from 'lodash'
import { notInitialized } from "react-redux/es/utils/useSyncExternalStore";

const CoursesTable = ({ classes, ...props }) => {
    let location = useLocation();
    const { getAuthUser } = useContext(AuthContext)
    const authUser = getAuthUser()
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(10)

    const [type, setType] = useState("")


    useEffect(() => {
        // if (location?.pathname === "/commercial/classes") {
        //     props.fetchPagination(1, rowsPerPage, null, 'single')
        //     setType('single')
        // }
        if (location?.pathname === "/commercial/courses") {
            props.fetchPagination(1, rowsPerPage, null, 'multiple')
            setType('multiple')
        }

    }, [location?.pathname])
    useEffect(() => {
       console.log('3344',props)
    }, [props])

    function return_query_string_from_json_object(obj, without_qm) {
        let s = "";
        if (_.isObject(obj)) {
            for (var key in obj) {
                if (s !== "") {
                    s += "&";
                }
                s += (key + "=" + encodeURIComponent(obj[key]));
            }
        }
        return (without_qm ? "" : "?") + s + "";
    }
    const joinMeeting = async (bbb) => {

        let Obj2Use = {
            "fullName": authUser.firstname,
            "meetingID": bbb.meetingID,
            "password": authUser.role==='commercial'?bbb.moderatorPW:bbb.attendeePW,
            "userID": authUser.id,
            "redirect": true
        };
        let apiCallName = 'join';
        let apiCallParams = return_query_string_from_json_object(Obj2Use, true);
        let sharedSecret = process.env.REACT_APP_BBB_SECRET;
        let checksum = sha1(apiCallName + apiCallParams + sharedSecret).toString();
        
        let finalUrl2Call = process.env.REACT_APP_BBB_URL + apiCallName + '?' + apiCallParams + '&checksum=' + checksum;

        console.log('2323',Obj2Use, checksum,finalUrl2Call)
        window.open(finalUrl2Call);
    };

    const handleChangePage = async (newPage) => {
        await setPage(newPage);
        props.fetchPagination(newPage + 1, rowsPerPage, null, type)
    };

    const handleChangeRowsPerPage = async (rowsPerPage) => {
        await setRowsPerPage(rowsPerPage);
        await setPage(0);
        props.fetchPagination(1, rowsPerPage, null, type)

    };

    const handleSearch = async (searchText) => {
        await setPage(0);
        props.fetchPagination(1, rowsPerPage, searchText, type)
    };

    const handleFilterChange = async (title) => {
        await setPage(0);
        props.fetchPagination(1, rowsPerPage, title, type)
    };

    const refresh = async () => {
        await setPage(0);
        props.fetchPagination(1, rowsPerPage, null, type)
    }

    const popover = (classes) => (
      
                <table className="table table-bordered fw-normal small mb-0">
                    <tbody>
                        {classes?.map((item, index) =>

                            <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{item.title}</td>
                                <td>{moment(item.datetime).local().locale('es').format('lll')}</td>
                                <td>{moment(item.datetime).add(4, 'hours')< moment() && <a className="btn bg-yellow btn-sm text-white rounded-0" onClick={()=>joinMeeting(item.bbb)}><PlayCircle style={{ fontSize: "18px" }} /> Ir a Aula &nbsp;&nbsp;&nbsp;&nbsp;</a>}</td>
                            </tr>

                        )}
                    </tbody>
                </table>

         
    );

    const columns = [
        {
            name: "id",
            label: "ID",
            options: {
                display: false,
                filter: false,
                sort: false,
            }
        },
        {
            // left side of first column is too close with the container, give more space on it
            name: "title",
            label: "Curso",
            options: {
                filter: true,
                sort: false

            },

        },

        {
            name: "description",
            label: "Description",
            options: {
                filter: true,
                sort: false,
                display: false
            }
        },
        {
            name: "image",
            label: "Image",
            options: {
                filter: false,
                sort: false,
                display: false,
            },

        },
        {
            name: "keywords",
            label: "keywords",
            options: {
                filter: false,
                sort: false,
                display: false,
            },

        },
        {
            name: "price",
            label: "price",
            options: {
                filter: false,
                sort: false,
                display: false,
            },

        },
        {
            name: "intake",
            label: "intake",
            options: {
                filter: false,
                sort: false,
                display: false,
            },

        },
        {
            name: "status",
            label: "status",
            options: {
                filter: false,
                sort: false,
                display: false,
            },

        },

        {
            name: "datetime",
            label: "Date and Time",
            options: {
                filter: true,
                sort: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return moment(value).format('lll');
                },
                display: type === 'single'
            }
        },
        {
            name: "link",
            label: "Enlace",
            options: {
                filter: false,
                sort: false,
                display: false
            },

        },
        {
            name: "lessons",
            label: "Clase",
            options: {
                filter: false,
                sort: false,
                display: type === 'multiple',
                customBodyRender: (value, tableMeta, updateValue) => {
                    return popover(value)
                }
            },

        },
        {
            name: "type",
            label: "Tipo",
            options: {
                filter: false,
                sort: false,
                display: false,
            },

        },
        {
            name: "commercial",
            label: "commercial",
            options: {
                filter: false,
                sort: false,
                display: false,
            },

        },
        {
            name: "createdBy",
            label: "createdBy",
            options: {
                filter: false,
                sort: false,
                display: false,
            },

        },
        {
            name: "createdOn",
            label: "Created on",
            options: {
                filter: true,
                sort: false,
                display: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return moment(value).format('lll');
                }
            }
        },

        {
            name: "",
            options: {
                filter: false,
                sort: false,
                empty: true,
              
                customHeadRender: (columnMeta, handleToggleColumn) => {
                    return (
                        <th key={columnMeta.index} style={{ paddingRight: "16px" }}>
                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end" }}>
                                <FormDialogAddCourses
                                    create={props.create}
                                    refresh={refresh}
                                    type={type}
                                />
                            </div>
                        </th>
                    );
                },
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end" }}>

                            {/* {type === 'multiple' && <FormDialogEditCourses
                                dataCourses={tableMeta.rowData}
                                update={props.update}
                                type={type}
                            />} */}
                            <FormDialogDeleteCourses
                                dataCourses={tableMeta.rowData}
                                delete={props.delete}
                                refresh={refresh}
                            />
                            {/* <a className="btn bg-yellow text-white rounded-0" ><PlayCircle style={{ fontSize: "18px" }} /> Ir a Aula &nbsp;&nbsp;&nbsp;&nbsp;</a> */}

                        </div>
                    );
                }
            }
        }
    ];

    const options = {

        elevation: 1,
        search: false,
        filter: false,
        filterType: 'textField',
        responsive: 'stacked',
        selectableRows: 'none',
        rowsPerPageOptions: [5, 10, 25],
        serverSide: true,
        viewColumns: false,
        print: false,
        download: false,
        rowsPerPage: rowsPerPage,
        count: props?.meta?.totalDocs || 0,
        page: page,
        textLabels: {
            pagination: {
                next: "Siguiente página",
                previous: "Pagina anterior",
                rowsPerPage: "Entradas por pagina:",
                displayRows: "de",
              },
        },

        onTableChange: (action, tableState) => {
            switch (action) {
                case 'changePage':
                    handleChangePage(tableState.page)
                    break;

                case 'changeRowsPerPage':
                    handleChangeRowsPerPage(tableState.rowsPerPage)
                    break;

                case 'search':
                    handleSearch(tableState.searchText)
                    break;

                case 'filterChange':
                    handleFilterChange(tableState.filterList[1], tableState.filterList[2])
                    break;

                case 'resetFilters':
                    handleSearch("")
                    break;

                default:
                    break;
            }
        },
    };

    return (
        <div style={{zIndex:1}}>
<MUIDataTable
            // title="Clases"
            data={type === 'single' ? props.allclasses : props.allcourses}
            columns={columns}
            options={options}
           
        />
        </div>
        
    );
}

const mapStateToProps = state => ({
    allcourses: state?.course?.courses,
    allclasses: state?.course?.classes,
    meta: state?.courses?.metaCourse,

})

const mapActionToProps = {
    fetchPagination: actions.Pagination,
    create: actions.create,
    update: actions.update,
    delete: actions.Delete,
}

export default connect(mapStateToProps, mapActionToProps)(CoursesTable);