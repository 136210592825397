import React, { useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from '@mui/material/Dialog';
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { IconButton, Select, MenuItem, InputLabel, FormControl, Checkbox, FormControlLabel } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { toast } from 'react-toastify';
import Grow from '@mui/material/Grow';
import API from "../../../../utils/api";
import moment from "moment";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Avatar from '@mui/material/Avatar';


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Grow ref={ref} {...props} />;
});

const initialFormState = {
  id: null,
  firstname: "",
  lastname: "",
  email: "",
  birthday: "",
  image: "",
  subjects: "",
  experience: "",
  mobile: "",
  createdDate: "",
  approved: "",
  role: "",
}

const FormDialogEditUser = (props) => {
  const [open, setOpen] = useState(false);
  const [user, setUser] = useState(initialFormState);
  const [errors, setErrors] = useState({})

  const handleClickOpen = () => {
    setErrors({});
    setUser({
      id: props.dataUser[0],
      firstname: props.dataUser[1],
      lastname: props.dataUser[2],
      email: props.dataUser[3],
      birthday: props.dataUser[4],
      image: props.dataUser[5],
      subjects: props.dataUser[6],
      experience: props.dataUser[7],
      mobile: props.dataUser[8],
      createdDate: props.dataUser[9],
      approved: props.dataUser[10],
      role: props.dataUser[11],
    })
    setOpen(true);
  }

  const handleClose = () => {
    setOpen(false);
  }

  const handleInputChange = event => {
    const { name, value } = event.target
    setUser({ ...user, [name]: value })
  }

  const validate = () => {
    let tempErrors = {};
    let formIsValid = true;



    let regexp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    // let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (!regexp.test(user.email)) {
      formIsValid = false;
      tempErrors["email"] = "Email is not valid";
    }

    setErrors(tempErrors);
    return formIsValid;
  }

  const handleSubmit = (e) => {
    const onSuccess = () => {
      setOpen(false);
      toast.success('Data succesfully updated');
    }
    e.preventDefault();

    if (validate()) {
      props.update(user.id, user, onSuccess)
    }
  }

  return (
    <div>
      <IconButton color="primary" onClick={handleClickOpen}>
        <EditIcon />
      </IconButton>
      <Dialog
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
        aria-labelledby="form-dialog-title"
        fullWidth
        disablePortal
      >
        <DialogTitle id="form-dialog-title" style={{ padding: "30px 30px 0px 30px" }}>Details</DialogTitle>

        <DialogContent style={{ padding: "30px 30px 10px 30px" }}>
          <div className="row">
            <div className="col-xl-3">
              <Avatar
                src={user.image?(process.env.REACT_APP_IMG_URL + user.image):''}
                sx={{ width: 100, height: 100 }} 				onError={({ currentTarget }) => {
                  currentTarget.onerror = null; // prevents looping
                  currentTarget.src = process.env.REACT_APP_IMG_URL +"uploads/user.jpg";
            }}

              />
            </div>
            <div className="col-xl-9 mb-3">

              <ul className="list-group list-group-flush">
                <li className="list-group-item"><strong>Name:</strong> {user.firstname}</li>
                <li className="list-group-item"><strong>Last Name:</strong> {user.lastname}</li>
                <li className="list-group-item"><strong>Email:</strong> {user.email}</li>
                <li className="list-group-item"><strong>Mobile:</strong> {user.mobile}</li>
                <li className="list-group-item"><strong>Subjects:</strong> {user.subjects}</li>
                <li className="list-group-item"><strong>Experience:</strong> {user.experience}</li>
              </ul>
            </div>
          </div>

          {/* <br />
          <TextField
            autoFocus
            name="firstName"
            label="First Name"
            value={user.firstname}
            fullWidth
            disabled
            onChange={handleInputChange}
            {...(errors.firstName && { error: true, helperText: errors.firstName })}
          />
          <br /><br />
          <TextField

            name="lastName"
            label="Last Name"
            value={user.lastname}
            fullWidth
            onChange={handleInputChange}
            {...(errors.lastName && { error: true, helperText: errors.lastName })}
          />

          <br /><br />

          <TextField
            name="email"
            label="Email"
            value={user.email}
            fullWidth
            onChange={handleInputChange}
            {...(errors.email && { error: true, helperText: errors.email })}
          />
          <br /><br /> */}
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Verification Status</InputLabel>
            <Select
              name="approved"
              value={user.approved}
              label="Verification Status"
              onChange={handleInputChange}
            >
              <MenuItem value={true}>Accept</MenuItem>
              <MenuItem value={false}>Reject</MenuItem>
            </Select>
          </FormControl>
        </DialogContent>

        <DialogActions style={{ padding: 30 }}>
          <Button variant="contained" onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button variant="contained" onClick={handleSubmit} color="secondary">
            Save
          </Button>
        </DialogActions>

      </Dialog>
    </div>
  );
}

export default FormDialogEditUser;