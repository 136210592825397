import { ACTION_TYPES } from "../actions/order";

const initialState = {
    orders: [],
    metaOrder: {},
    metaCount: {},
    sum: 0
}

export const order = (state = initialState, action) => {
    switch (action.type) {
        case ACTION_TYPES.ORDER_FETCH_ALL:
            return {
                ...state,
                orders: [...action.payload]
            }
        case ACTION_TYPES.ORDER_CREATE:
            return {
                ...state,
                orders: [...state.orders, action.payload]
            }
        case ACTION_TYPES.ORDER_UPDATE:
            return {
                ...state,
                orders: state.orders.map(x => x.id === action.payload.id ? action.payload : x)
            }
        case ACTION_TYPES.ORDER_DELETE:
            return {
                ...state,
                orders: state.orders.filter(x => x.id !== action.payload)
            }
        case ACTION_TYPES.ORDER_PAGINATION:

            return {
                ...state,
                orders: [...action.payload.orders],
                metaOrder: action.payload.meta
            }
        case ACTION_TYPES.ORDER_EARNINGS:

            return {
                ...state,
                orders: [...action.payload.orders],
                metaOrder: action.payload.meta,
                sum: action.payload.sum[0].sum
            }
        case ACTION_TYPES.ORDER_GETMYORDERS:
            return {
                ...state,
                orders: [...action.payload]
            }

        case ACTION_TYPES.ORDER_COUNT:
            return {
                ...state,
                metaCount: action.payload
            }
        default:
            return state;
    }
}