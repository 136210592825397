import React from "react";
import API from "../utils/api"
import { jwtDecode as jwt } from 'jwt-decode'
import { toast } from 'react-toastify';
import Swal from "sweetalert2";

let AuthContext;
const { Provider, Consumer } = (AuthContext = React.createContext());

class AuthProvider extends React.PureComponent {

  state = {
    token: null,
    authUser: null,
    errorMsg: null
  };

  isAuthenticated = () => {
    const tken = this.state.token ? this.state.token : localStorage.getItem("token")
    if (!tken) return false

    const decoded = jwt(tken);

    if (Date.now() / 1000 > decoded.exp - 5) {
      localStorage.clear();
      toast.error('Session has expired, please re-login');
      return false
    }
    return true
  }

  getAuthUserIdFromJwt = () => {
    const tken = this.state.token ? this.state.token : localStorage.getItem("token")
    if (!tken) return false

    const decoded = jwt(tken);

    const user = this.state.authUser ? this.state.authUser : localStorage.getItem("authUser");
    let user_id;
    if (!user) {
      //#region get users data from server
      user_id = decoded.sub;
      const data = {
        token: tken,
         id: user_id 
      };
      this.setLogin(data);
      //#endregion
    } else {
      try {
        const parsed = JSON.parse(user);
        user_id = parsed?.id || parsed?._id;
      } catch (err) {

      }
    }
    return user_id
  }

  getAuthUser = () => {
    const usr = this.state.authUser ? this.state.authUser : localStorage.getItem("authUser")
    if (!usr) return null

    try {
      const parsed = JSON.parse(usr)
      return parsed
    }
    catch (err) {
      return usr
    }
  }

  setLogin = (data) => {
    if (data) {
      localStorage.setItem('token', data.token);
        localStorage.setItem('authUser', JSON.stringify(data));
      this.setState({
        token: data.token,
        authUser: data
      })
    }
    else {
      this.setState({
        token: null,
        authUser: null
      })
      localStorage.removeItem("token");
      localStorage.removeItem("authUser");
    }
  }

  login = (email, password, history, setIsLoading, setErrorMessage) => {
    setIsLoading(true);
    this.setLogin(null)


    if (!!email && !!password) {
      API.auth().login({ email, password })
        .then(res => {
          console.log("res : ", res.data)

          if (res.status === 200 && res.data) {
            this.setLogin(res.data)
            setIsLoading(false)
            setErrorMessage('success')

            if (res.data.role === "admin") {
              history('/admin/dashboard')
            }

            if (res.data.role === "commercial") {
              history('/commercial/dashboard')
            }
            if (res.data.role === "residential") {
              history('/residential/dashboard')
            }

            return true
          }
          else {
            setIsLoading(false);
            Swal.fire("Oops!", res.data.message, 'warning')
            setErrorMessage(res.data.message)
            return false
          }
        })
        .catch(err => {
          setIsLoading(false);

          console.log(err)
          if (err.response) {
            Swal.fire("Oops!", err.response.data.message, 'warning')
            setErrorMessage(err.response.data.message)
          }
          else {
            setErrorMessage(err)
          }

          return false
        });
    } else {
      setIsLoading(false);
      Swal.fire("Oops!", "email and password is empty", 'warning')
      setErrorMessage("email and password is empty")
      return false
    }
  }

  sociallogin = (email, history, setIsLoading, setErrorMessage) => {
    setIsLoading(true);
    this.setLogin(null)


    if (!!email) {
      API.auth().sociallogin({ email })
        .then(res => {
          console.log("res social : ", res.data)

          if (res.status === 200 && res.data) {
            this.setLogin(res.data)
            setIsLoading(false)
            setErrorMessage('success')

            // if(res.data.role==="admin"){
            //   history('/admin/commercials')
            // }else{

            //   history('/')
            // }


            return true
          }
          else {
            setIsLoading(false);
            Swal.fire("Oops!", res.data.message, 'warning')
            setErrorMessage(res.data.message)
            return false
          }
        })
        .catch(err => {
          setIsLoading(false);

          console.log(err)
          if (err.response) {
            Swal.fire("Oops!", err.response.data.message, 'warning')
            setErrorMessage(err.response.data.message)
          }
          else {
            setErrorMessage(err)
          }

          return false
        });
    } else {
      setIsLoading(false);
      Swal.fire("Oops!", "email and password is empty", 'warning')
      setErrorMessage("email and password is empty")
      return false
    }
  }

  register = (email, name, password, history, setIsLoading, setErrorMessage) => {
    setIsLoading(true);

    if (!!email && !!name && !!password) {
      API.auth().register({ email, name, password })
        .then(res => {
          // console.log(res)
          setErrorMessage(null)
          setIsLoading(false);
          history('/admin/login')
        })
        .catch(err => {
          console.log(err.response.data)
          setErrorMessage(err.response.data.error)
          setIsLoading(false);
        });
    } else {
      setErrorMessage("All field is required")
      setIsLoading(false);
    }
  }

  logout = (history) => {
    this.setState({
      token: null,
      authUser: null
    });
    localStorage.removeItem("token");
    localStorage.removeItem("authUser");
    history("/login");
  }

  render() {
    return (
      <Provider
        value={{
          ...this.state,
          sociallogin: this.sociallogin,
          login: this.login,
          register: this.register,
          logout: this.logout,
          isAuthenticated: this.isAuthenticated,
          getAuthUser: this.getAuthUser,
          getAuthUserIdFromJwt: this.getAuthUserIdFromJwt
        }}
      >
        {this.props.children}
      </Provider>
    );
  }
}

export { AuthProvider, Consumer, AuthContext };
