import React from "react";

import InspectionsTable from "./table/InspectionsTable"



const Inspections = ({ inspections, ...props }) => {
    return (
        <React.Fragment>
            <div className='container ptop px-3'>
                <div className="row mb-4">
                <InspectionsTable />
                </div>
                
            </div>

        </React.Fragment>
    );
}


export default Inspections;