import React, { useState, useContext } from "react";

import TextField from "@mui/material/TextField";
import Dialog from '@mui/material/Dialog';
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { IconButton, Select, MenuItem, InputLabel, FormControl, Checkbox, FormControlLabel } from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { toast } from 'react-toastify';
import Grow from '@mui/material/Grow';
import API from "../../../../utils/api";
import moment from "moment";
import { AuthContext } from "../../../../context/AuthContext";
import { Col, Form } from "react-bootstrap";
import RichTextEditor from 'react-rte';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Grow ref={ref} {...props} />;
});

const initialFormState = {
  id: null,
  title: "",
  description: "",
  image: 'view-media/class.jpg',
  keywords: [],
  price: "",
  intake: "",
  status: "pending",
  link: "", 
  datetime: "",
  lessons: [],
  type: "",
  commercial: "",
  createdBy: ""
}

const FormDialogAddClasses = (props) => {
  const { getAuthUser, isAuthenticated } = useContext(AuthContext)
  const authUser = getAuthUser()
  const [open, setOpen] = useState(false);
  const [classes, setClasses] = useState(initialFormState);
  const [errors, setErrors] = useState({})
  const [lessontitle, setLessontitle] = useState("")
  const [lessondatetime, setLessondatetime] = useState("")
  const [htmlDesc, setHtmlDesc] = useState(RichTextEditor.createValueFromString('', 'html'))

  const [keywordArr, setKeywordArr] = useState(["Idiomas", "Ingles", "Francés", "Portugués", "Gallego", "Ruso", "Italiano", "Alemán", "Chino mandarín", "Árabe", "Japonés", "Coreano", "Nivel inicial", "Nivel básico", "Nivel medio", "Nivel avanzado", "Matemáticas", "Física", "Biología", "Tecnología"])

  const handleClickOpen = () => {
    setErrors({});
    setClasses({ ...initialFormState, type: props.type, commercial: authUser.id, createdBy: authUser.id });
    setOpen(true);
  }

  const handleClose = () => {
    setOpen(false);
  }

  const handleInputChangeformultiselect = event => {
    const { name, value } = event.target

    if(classes[name].includes(value)){
      setClasses({ ...classes, [name]: classes[name].filter(v => v !== value) })

    }else{
      setClasses({ ...classes, [name]: [...classes[name],value] })
    }
   

  }

  const handleInputChange = event => {
    const { name, value } = event.target

    setClasses({ ...classes, [name]: value })

  }

  const handleHtmlInputChange = value => {

    // setHtmlDesc(value)
    setClasses({ ...classes, description: value.toString('html') })
   
  }

  const handleInputChangeForFileType = event => {

    if(event.target.files[0].size/ 1024 >200){
      toast.warning('tamaño máximo permitido 200 kb');
      return
    }
    var formdata = new FormData();
    formdata.append("file", event.target.files[0]);
    formdata.append("documentId", Date.now());
    API.utility().upload(formdata)
      .then(res => {
        setClasses({ ...classes, image: res.data.data.path })
      })

  }

  const validate = () => {
    let tempErrors = {};
    let formIsValid = true;

    if (!classes.title) {
      formIsValid = false;
      tempErrors["title"] = "Cannot be empty";
    }

    if (!classes.description) {
      formIsValid = false;
      tempErrors["description"] = "Cannot be empty";
    }

    if(classes.image=== 'view-media/class.jpg'){
      formIsValid = false;
      tempErrors["image"] = "Cannot be empty";
      toast.warning('Miniatura no puede estar vacío')
    }

    setErrors(tempErrors);
    return formIsValid;
  }

  const handleSubmit = (e) => {
   // setClasses({ ...classes, datetime: new Date(classes.datetime).toISOString() })
    let clas = classes
    clas.datetime = moment(clas.datetime).toDate().toString()
 
    const onSuccess = () => {
      setTimeout(
        () => props.refresh(), 
        1000
      );
      
      setOpen(false);
      toast.success('Clase guardada');
    }
    
      
    if (validate()) {
      props.create(clas, onSuccess)
    }
    e.preventDefault();
  }

  const addLesson = () => {
    setClasses({ ...classes, lessons: [...classes.lessons,{ title: lessontitle, datetime: lessondatetime }] })
    setLessontitle("")
    setLessondatetime("")
  }

  return (
    <>
      <button className="btn bg-green text-white rounded-0 mt-2" onClick={handleClickOpen}><AddCircleIcon style={{ fontSize: "18px" }} /> {props.type === 'single' ? 'Crear Clase' : 'Crear Curso'}</button>
      <form onSubmit={(e)=>{e.preventDefault();handleSubmit(e)}}>
        <Dialog
          open={open}
          onClose={handleClose}
          TransitionComponent={Transition}
          aria-labelledby="form-dialog-title"
          fullWidth
          disablePortal
        >

          <DialogTitle id="form-dialog-title">{props.type === 'single' ? 'Crear Clase' : 'Crear Curso'}</DialogTitle>

          <DialogContent >

            <div className="col-lg-12 col-12 mb-4">
              <div className="row">
                <div className="col-lg-12 col-12 mb-4">
                  <div className="mb-3">
                    <label className="form-label fw-bold">Título de la clase* </label>
                    <input onChange={handleInputChange} value={classes?.title} name="title" type="text" className="form-control border-green rounded-0 mb-3" required />
                  </div>

                  <div className="mb-3">
                    <label className="form-label fw-bold">Descripción del contenido de la clase* (Máximo 100 palabras) </label>
                    {/* <textarea onChange={handleInputChange} value={classes?.description} name="description" type="text" className="form-control border-green rounded-0 mb-3" required /> */}
                    <RichTextEditor
                value={htmlDesc}
                onChange={handleHtmlInputChange}
                toolbarConfig = {{ display: [] }}
              />
                  </div>

                  <div className="mb-3">
                    {/* <label className="form-label fw-bold">Palabras clave* </label>
                    <input onChange={handleInputChange} value={classes?.keywords} name="keywords" type="text" className="form-control border-green rounded-0 mb-3" required /> */}

                    <Form.Group as={Col} controlId="my_multiselect_field">
                      <Form.Label>Palabras clave*</Form.Label>
                      <Form.Control as="select" multiple name="keywords" value={classes?.keywords} onChange={handleInputChangeformultiselect}>
                      {keywordArr.map(option => (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      ))}
               
                      </Form.Control>
                    </Form.Group>
                  </div>

                  {props.type === 'single' && <div className="mb-3">
                    <label className="form-label fw-bold">Programa de la clase* </label>
                    <input onChange={handleInputChange} value={classes?.datetime} name="datetime" type="datetime-local" className="form-control border-green rounded-0 mb-3" required min={moment().format("YYYY-MM-DDThh:mm")} />
                  </div>}

                  {props.type === 'multiple' && <div className="row mb-3">
                    <div className="col-lg-12 col-12"><label className="form-label fw-bold">Programa de la clase* </label></div>
                    <div className="col-lg-5 col-12">
                      <label className="form-label fw-bold">Título de la clase </label>
                      <input onChange={(e) => setLessontitle(e.target.value)} value={lessontitle} name="title" type="text" className="form-control border-green rounded-0 mb-3"  />
                    </div>
                    <div className="col-lg-5 col-12">
                      <label className="form-label fw-bold">Date and Time </label>
                      <input onChange={(e) => setLessondatetime(e.target.value)} value={lessondatetime} name="datetime" type="datetime-local" className="form-control border-green rounded-0 mb-3"  min={moment().format("YYYY-MM-DDThh:mm")} />
                    </div>
                    <div className="col-lg-2 col-12">

                      <button type="button" className="btn btn-link mt-4" onClick={addLesson}><AddCircleIcon style={{ fontSize: "35px" }} /></button>
                    </div>

                    <div className="col-lg-12 col-12">
                    <table className="table table-bordered fw-normal small">
                    <tbody>
                      {classes?.lessons?.map((item, index) =>

                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{item.title}</td>
                          <td>{item.datetime}</td>
                       
                        </tr>

                      )}
                       </tbody>
                      </table>
                    </div>
                  </div>}

                  <div className="mb-3">
                    <label className="form-label fw-bold">Número máximo de alumnos por clase* </label>
                    <input onChange={handleInputChange} value={classes?.intake} name="intake" type="number" className="form-control border-green rounded-0 mb-3" required />
                  </div>

                  <div className="mb-3">
                    <label className="form-label fw-bold">Precio de la clase por alumno (euros)* </label>
                    <input onChange={handleInputChange} value={classes?.price} name="price" type="number" className="form-control border-green rounded-0 mb-3" required />
                  </div>

                  <div>

                    <input type="file" id="file" accept=".png,.jpg,.gif" className='d-none' onChange={handleInputChangeForFileType} />

                    <label htmlFor="file" className='btn bg-green text-white rounded-0 mt-2'>Miniatura* </label>

                    <small className="text-muted small fw-light ps-2">tamaño máximo permitido 200 kb y el tipo debe ser png, jpg o gif</small>
                    <div className="text-muted small fw-light mt-2">Sube una imagen que refleje el contenido de tu clase</div>
                    {classes.image && <img src={process.env.REACT_APP_IMG_URL + classes.image} height="100" />}
                  </div>



                </div>


              </div>
            </div>




          </DialogContent>

          <DialogActions >
            <button type="button" className="btn bg-green text-white rounded-0" onClick={handleClose} color="primary">
              Cancel
            </button>
            <button type='submit' className="btn bg-yellow text-white rounded-0" >
              Guardar
            </button>
          </DialogActions>

        </Dialog>
      </form>
    </>
  );
}

export default FormDialogAddClasses;