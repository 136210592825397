import React, { useState, useContext } from "react";

import TextField from "@mui/material/TextField";
import Dialog from '@mui/material/Dialog';
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { IconButton, Select, MenuItem, InputLabel, FormControl, Checkbox, FormControlLabel } from '@mui/material';
import { EnumTemplatesFieldType, EnumTemplatesFieldTypeWithLabel, initialFormStateInspections, EnumTemplateFieldConditionalAction } from "../../../../utils/enums";
import { handleFormUploadSchemaEvent, handleFormSubmitedSchemaEvent } from "../../../../utils/templates_util";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { toast } from 'react-toastify';
import Grow from '@mui/material/Grow';
import API from "../../../../utils/api";
import moment from "moment";
import { AuthContext } from "../../../../context/AuthContext";
import RichTextEditor from 'react-rte';
import EditIcon from '@mui/icons-material/Edit';
import './../style.css';
import { Form } from '@flipbyte/formik-json';
import _ from 'lodash'
import { CloseOutlined } from "@mui/icons-material";
import { useDrawingArea } from '@mui/x-charts/hooks';
import { styled } from '@mui/material/styles';
import { PieChart } from '@mui/x-charts/PieChart';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Grow ref={ref} {...props} />;
});


const FormDialogAddInspections = (props) => {
  const { getAuthUser } = useContext(AuthContext)
  const authUser = getAuthUser();
  const [cavatar, setCAvatar] = useState(authUser?.image);
  const [existingData, setInspections] = useState(initialFormStateInspections);
  const [open, setOpen] = useState(false);
  const [errors, setErrors] = useState({});
  const [avatar, setAvatar] = useState(existingData?.image);
  const [currTemp, setCurrTemp] = useState('');
  const [serverActionIsInProgress, setServerActionIsInProgress] = useState(false);

  const onError = (err) => {
    setServerActionIsInProgress(false);
    if (err?.show_message2user) {
      props.refresh();
      setOpen(false);
      toast.error(err.message);
    }
  };

  const updateObjectFieldsForCustomDropFn = (_temp_existingData) => {
    for (var prop in _temp_existingData) {
      //console.log(prop);
      switch (prop) {
        case 'elements':
          _temp_existingData[prop] = updateObjectFieldsForCustomDropFn(_temp_existingData[prop]);
          break;
        case 'fieldType':
          switch (_temp_existingData[prop]) {
            case "file-uploader":
              if (!_temp_existingData.options) {
                _temp_existingData.options = {};
              }
              _temp_existingData.options.onDrop = handleFormUploadSchemaEvent;
              break;
          }
          break;
        default:
          if (_temp_existingData[prop] && _temp_existingData[prop]?.renderer) {
            _temp_existingData[prop] = updateObjectFieldsForCustomDropFn(_temp_existingData[prop]);
          }
          break;
      }
    }
    return _temp_existingData;
  }

  const handleClickOpen = () => {
    setErrors({});
    //setInspections({ ...existingData, createdBy: authUser.id });
    setCurrTemp("");
    setInspections({ ...initialFormStateInspections, createdBy: authUser.id });
    setOpen(true);
  };
  const updateTemplatesDataInInspection = (event) => {
    const _selectedId = event.target.value;
    if (_selectedId) {
      let _temp_existingData = _.cloneDeep(props.alltemplates.filter((obj) => { return obj.id === _selectedId })[0]);
      if (_temp_existingData?.id) {
        setCurrTemp(_selectedId);
        // existingData.title = _temp_existingData.title;
        // existingData.description = _temp_existingData.description;
        // existingData.image = _temp_existingData.image;
        _temp_existingData.id = null;
        _temp_existingData.template_schema = updateObjectFieldsForCustomDropFn(_temp_existingData.template_schema);
        _temp_existingData.createdBy = authUser.id;
        setInspections(_temp_existingData);
        console.log(existingData, _selectedId);
        return;
      }
    }
    setCurrTemp("");
    setInspections(initialFormStateInspections);
  }

  const handleClose = () => {
    setOpen(false);
  };

  const handleInputChangeformultiselect = event => {
    const { name, value } = event.target

    if (existingData[name].includes(value)) {
      setInspections({ ...existingData, [name]: existingData[name].filter(v => v !== value) })

    } else {
      setInspections({ ...existingData, [name]: [...existingData[name], value] })
    }

  };

  const handleInputChange = event => {
    const { name, value } = event.target
    setInspections({ ...existingData, [name]: value })

  };


  const handleInputChangeForFileType = event => {


    if (event.target.files[0].size / 1024 > 200) {
      toast.warning('maximum size allowed 200 kb');
      return
    }

    var formdata = new FormData();
    formdata.append("file", event.target.files[0]);
    formdata.append("documentId", Date.now());
    console.log('res/data', event.target.files[0])
    API.utility().upload(formdata)
      .then(res => {

        if (res?.data?.data?.path) {
          setInspections({ ...existingData, image: res.data.data.path });
          setAvatar(res.data.data.path);
        }
      });
  };

  const validate = () => {
    let tempErrors = {};
    let formIsValid = true;

    if (!existingData.title) {
      formIsValid = false;
      tempErrors["title"] = "Cannot be empty";
    }

    if (!existingData.description) {
      formIsValid = false;
      tempErrors["description"] = "Cannot be empty";
    }

    if (existingData.image === 'view-media/class.jpg') {
      // formIsValid = false;
      // tempErrors["image"] = "Cannot be empty";
      // toast.warning('Thumbnail cannot be empty');
    }

    setErrors(tempErrors);
    return formIsValid;
  }

  const handleSubmit = (formValues, event) => {

    existingData.template_data = formValues;
    existingData.report_title = (formValues?.page_question_report_title) || (existingData.report_title || "");

    const onSuccess = () => {
      setServerActionIsInProgress(false);
      setTimeout(
        () => props.refresh(),
        1000
      );
      setOpen(false);
      toast.success('Inspections data added');
      // Swal.fire({
      //   title: 'Yay!',
      //   text: 'Template added',
      //   icon: 'success',
      //   showConfirmButton: false,
      //   timer: 2000
      // });
    };
    if (serverActionIsInProgress == false) {
      setServerActionIsInProgress(true);
      if (existingData.id) {
        props.update(existingData.id, existingData, authUser.id, onSuccess, onError);
      } else {
        props.create(existingData, onSuccess, onError);
      }
    }
  }


  const handleFormBtnEvent = (type) => {
    switch (type) {
      case 'submitted':
        return handleSubmit.bind(this);
      case 'fileupload':
        return handleFormUploadSchemaEvent.bind(this);
        break;
        break;
      default:
        return console.log.bind(console, type);
        break;
    }
  };
  //#region pie chart
  const _return_if_enabled_then_single_point_value_out_of_100_for_question_points_enabled = () => {
    let _total_questions_with_points_found = 0;

    //#region here value is 1 so calculate from rest all points
    let _list_of_all_pages = _.values(_.get(existingData, "template_schema.elements"));
    for (let _single_page of _list_of_all_pages) {
      for (let _single_question of _.values(_single_page.elements)) {
        if (_single_question?.points) {
          _total_questions_with_points_found = _total_questions_with_points_found + 1;
        }
      }
    }
    if (_total_questions_with_points_found) {
      return (100 / _total_questions_with_points_found).toFixed(2);
    } else {
      return 0;
    }
  }

  const _return_total_point_value_out_of_100_for_question_points_enabled = (percentage_string_enabledT) => {
    let _total_questions_with_points_found_ids = [];
    //#region here value is 1 so calculate from rest all points
    let _list_of_all_pages = _.values(_.get(existingData, "template_schema.elements"));
    for (let _single_page of _list_of_all_pages) {
      for (let _single_question of _.values(_single_page.elements)) {
        if (_single_question?.points) {
          _total_questions_with_points_found_ids.push(_single_question.id || _single_question.name);
        }
      }
    }
    let _list_of_all_questions_answered = _.keys(_.get(existingData, "template_data"));
    let _total_questions_with_points_found = 0;
    for (let _single_question of _total_questions_with_points_found_ids) {
      if (_list_of_all_questions_answered.includes(_single_question)) {
        _total_questions_with_points_found = _total_questions_with_points_found + 1;
      }
    }

    let _return_percentage_value = 0;

    if (_total_questions_with_points_found) {
      _return_percentage_value = (_total_questions_with_points_found * 1) / _total_questions_with_points_found_ids.length;
    }
    //#endregion here value is 1 so calculate from rest all points

    if (percentage_string_enabledT) {
      return (_return_percentage_value * 100) + "%";
    } else {
      return _return_percentage_value;
    }

  }

  const _return_data_for_point_value_out_of_100_for_question_points_enabled = () => {
    let _success_color = 'green';
    let _failure_color = 'gray';

    let _total_questions_with_points_found_ids_obj = [];
    //#region here value is 1 so calculate from rest all points
    let _list_of_all_pages = _.values(_.get(existingData, "template_schema.elements"));
    for (let _single_page of _list_of_all_pages) {
      for (let _single_question of _.values(_single_page.elements)) {
        if (_single_question?.points) {
          _total_questions_with_points_found_ids_obj.push({ name: _single_question.name, label: _single_question.label });
        }
      }
    }

    let _single_question_percentage = (100 / _total_questions_with_points_found_ids_obj.length).toFixed(2);

    let _list_of_all_questions_answered = _.keys(_.get(existingData, "template_data"));
    let _total_questions_with_points_found = 0;
    let _data_set_for_chart_fill_questions = [];
    for (let _single_question of _total_questions_with_points_found_ids_obj) {
      let _single_data = { id: _single_question.name, value: _single_question_percentage, label: _single_question.label, color: _failure_color }
      if (_list_of_all_questions_answered.includes(_single_question.name)) {
        _total_questions_with_points_found = _total_questions_with_points_found + 1;
        _single_data.color = _success_color;
      }
      _data_set_for_chart_fill_questions.push(_single_data);
    }
    //#endregion here value is 1 so calculate from rest all points
    return _data_set_for_chart_fill_questions;

  }

  const PieCenterLabel = ({ children }) => {
    const { width, height, left, top } = useDrawingArea();
    return (
      <StyledText x={left + width / 2} y={top + height / 2}>
        {children}
      </StyledText>
    );
  }
  const StyledText = styled('text')(({ theme }) => ({
    fill: theme.palette.text.primary,
    textAnchor: 'middle',
    dominantBaseline: 'central',
    fontSize: 20,
  }));

  //#endregion pie chart

  return (
    <>
      <button className="btn rounded-0 mt-2" onClick={handleClickOpen}><AddCircleIcon style={{ fontSize: "18px" }} /> {'New'}</button>

      <Dialog
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth="md"
        disablePortal
        sx={{
          '.MuiPaper-root': {
            padding: 0,
            margin: 0,
            width: '100%',
            maxHeight: '100%',
            height: '100%'
          },
        }}
      >

        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          New Inspection  <button type="button" className="btn btn-link float-end p-0" onClick={handleClose} color="primary">
            <CloseOutlined />
          </button>
        </DialogTitle>
        <DialogContent >

          <div className="row">
            <div className="col-lg-12 col-12 mb-4">
              <select class="form-select text-capitalize" onChange={updateTemplatesDataInInspection}>
                <option value="" selected>Choose template</option>

                {props.alltemplates.map((item) => {
                  return (<option value={item.id} >{item.title}  ({item.type})</option>)
                })}
              </select>
              {/* <FormControl
                fullWidth={true}
              >
                <InputLabel id="demo-simple-select-label">Choose Template</InputLabel>
                <Select

                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={currTemp}
                  onChange={updateTemplatesDataInInspection}

                >
                  {props.alltemplates.map(item => (
                    <MenuItem value={item.id}>{item.title}</MenuItem>
                  ))}

                </Select>
              </FormControl>
           */}
            </div>



          </div>

          {currTemp !== '' && <div className="col-lg-12 col-12 mb-4">
            <div className="row">
              {existingData.image && <div className="col-lg-4 col-4 mb-4">
                <img src={process.env.REACT_APP_IMG_URL + existingData.image} className="avatarprofle me-2" onError={({ currentTarget }) => {
                  currentTarget.onerror = null; // prevents looping
                  currentTarget.src = process.env.REACT_APP_IMG_URL + "uploads/logo.png";
                }} />
              </div>}
              <div className="col-lg-6 col-6 mb-4">
                <div className="mb-3">
                  <h4>
                    {existingData?.title}
                  </h4>
                  <p>
                    {existingData?.description}
                  </p>
                </div>
              </div>
              <div className="col-lg-2 col-2 mb-4">
                <img src={process.env.REACT_APP_IMG_URL + cavatar} className="avatarprofle me-2" onError={({ currentTarget }) => {
                  currentTarget.onerror = null; // prevents looping
                  currentTarget.src = process.env.REACT_APP_IMG_URL + "uploads/company.jpg";
                }} />
              </div>
              {/* <div className="col-lg-12 col-12">
                {_return_if_enabled_then_single_point_value_out_of_100_for_question_points_enabled() > 0 &&
                  <>
                    <progress value={_return_total_point_value_out_of_100_for_question_points_enabled()} className="form-control progress-bar" />
                    <PieChart
                      series={[
                        {
                          data: _return_data_for_point_value_out_of_100_for_question_points_enabled(),
                          innerRadius: 30
                        },
                      ]}
                      width={200}
                      height={100}
                      slotProps={{
                        legend: { hidden: true },
                      }}
                    >
                      <PieCenterLabel>{_return_total_point_value_out_of_100_for_question_points_enabled(true)}</PieCenterLabel>
                    </PieChart>
                  </>
                }

              </div> */}
              <div className="col-lg-12 col-12 mb-4">
                {existingData?.template_schema && <Form
                  initialValues={existingData?.template_data}
                  schema={existingData?.template_schema}
                  // onChange={handleFormBtnEvent('fileupload')}
                  // onUpdate={handleFormBtnEvent('fileupload')}
                  onSubmit={handleFormBtnEvent('submitted')}
                  onError={handleFormBtnEvent('errors')}
                />}



              </div>


            </div>
          </div>}


        </DialogContent>



      </Dialog>

    </>
  );
}

export default FormDialogAddInspections;