import React, { useEffect, useState, useContext } from "react";

import MUIDataTable from "mui-datatables";
import { connect } from "react-redux";
import * as actions from "../../../../actions/order";
// import FormDialogAddClasses from "../formDialog/FormDialogAddClasses";
// import FormDialogEditClasses from "../formDialog/FormDialogEditClasses";
// import FormDialogDeleteClasses from "../formDialog/FormDialogDeleteClasses";
import moment from 'moment/min/moment-with-locales';
import { Link, useNavigate, useLocation } from "react-router-dom";
import PlayCircle from '@mui/icons-material/PlayCircle';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import { AuthContext } from "../../../../context/AuthContext";
import sha1 from 'crypto-js/sha1'
import _ from 'lodash'

const ClassesTable = ({ classes, ...props }) => {
    let location = useLocation();
    const { getAuthUser } = useContext(AuthContext)
    const authUser = getAuthUser()
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(10)

    useEffect(() => {
        props.fetchPagination(1, rowsPerPage, authUser.id, location?.pathname === "/residential/subscriptions"?"true":"false")

    }, [location?.pathname])
    function return_query_string_from_json_object(obj, without_qm) {
        let s = "";
        if (_.isObject(obj)) {
            for (var key in obj) {
                if (s !== "") {
                    s += "&";
                }
                s += (key + "=" + encodeURIComponent(obj[key]));
            }
        }
        return (without_qm ? "" : "?") + s + "";
    }
    const joinMeeting = async (bbb) => {

        let Obj2Use = {
            "fullName": authUser.firstname,
            "meetingID": bbb.meetingID,
            "password": authUser.role==='commercial'?bbb.moderatorPW:bbb.attendeePW,
            "userID": authUser.id,
            "redirect": true
        };
        let apiCallName = 'join';
        let apiCallParams = return_query_string_from_json_object(Obj2Use, true);
        let sharedSecret = process.env.REACT_APP_BBB_SECRET;
        let checksum = sha1(apiCallName + apiCallParams + sharedSecret).toString();
        
        let finalUrl2Call = process.env.REACT_APP_BBB_URL + apiCallName + '?' + apiCallParams + '&checksum=' + checksum;

        console.log('2323',Obj2Use, checksum,finalUrl2Call)
        window.open(finalUrl2Call);
    };
    const handleChangePage = async (newPage) => {
        await setPage(newPage);
        props.fetchPagination(newPage + 1, rowsPerPage, authUser.id, "true")
    };

    const handleChangeRowsPerPage = async (rowsPerPage) => {
        await setRowsPerPage(rowsPerPage);
        await setPage(0);
        props.fetchPagination(1, rowsPerPage, authUser.id, "true")

    };

    const handleSearch = async (searchText) => {
        await setPage(0);
        props.fetchPagination(1, rowsPerPage, authUser.id, "true")
    };

    const handleFilterChange = async (title) => {
        await setPage(0);
        props.fetchPagination(1, rowsPerPage, authUser.id, "true")
    };

    const refresh = async () => {
        await setPage(0);
        props.fetchPagination(1, rowsPerPage, authUser.id, "true")
    }

    const popover = (classes) => (
        <Popover id="popover-basic">

            <Popover.Body>
                <table className="table table-bordered fw-normal small mb-0">
                    <tbody>
                        {classes?.map((item, index) =>

                            <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{item?.title}</td>
                                <td>{moment(item?.datetime).local().locale('es').format('lll')}</td>

                            </tr>

                        )}
                    </tbody>
                </table>

            </Popover.Body>
        </Popover>
    );

    const columns = [
        {
            name: "id",
            label: "ID",
            options: {
                display: false,
                filter: false,
                sort: false,
            }
        },
        {
            // left side of first column is too close with the container, give more space on it
            name: "class",
            label: "Clase",
            options: {
                filter: true,
                sort: false,
                
                customBodyRender: (value, tableMeta, updateValue) => {
                    return value?.title;
                }
            },

        },

        {
            // left side of first column is too close with the container, give more space on it
            name: "class",
            label: "Date and Time",
            options: {
                filter: true,
                sort: false,
                
                customBodyRender: (value, tableMeta, updateValue) => {
                    return moment(value?.datetime).local().format('DD/MM/yyy HH:mm');
                }
            },

        },
        {
            name: "orderID",
            label: "ID",
            options: {
                filter: false,
                sort: false,
    
            },

        },
        {
            name: "amount",
            label: "Amount",
            options: {
                filter: false,
                sort: false,
            },

        },

        {
            // left side of first column is too close with the container, give more space on it
            name: "class",
            label: "Enlace",
            options: {
                filter: true,
                sort: false,
                display:location?.pathname === "/residential/subscriptions"?"true":"false",
                customBodyRender: (value, tableMeta, updateValue) => {
                    return <a className="btn bg-yellow text-white rounded-0" onClick={()=>joinMeeting(value.bbb)}><PlayCircle style={{ fontSize: "18px" }} /> Ir a Aula &nbsp;&nbsp;&nbsp;&nbsp;</a>
                }
            },

        },
        {
            name: "createdBy",
            label: "createdBy",
            options: {
                filter: false,
                sort: false,
                display: false,
            },

        },
        {
            name: "createdOn",
            label: "Created on",
            options: {
                filter: true,
                sort: false,
                display: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return moment(value).format('lll');
                }
            }
        },

        // {
        //     name: "",
        //     options: {
        //         filter: false,
        //         sort: false,
        //         empty: true,
        //         display: location?.pathname === "/residential/subscriptions"?true:false,
        //         customHeadRender: (columnMeta, handleToggleColumn) => {
        //             return (
        //                 <th key={columnMeta.index} style={{ paddingRight: "16px" }}>
        //                     <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end" }}>
        //                         {/* <FormDialogAddClasses
        //                             create={props.create}
        //                             refresh={refresh}
        //                             type={type}
        //                         /> */}
        //                     </div>
        //                 </th>
        //             );
        //         },
        //         customBodyRender: (value, tableMeta, updateValue) => {
        //             console.log('111',tableMeta.rowData)
        //             return (
        //                 <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end" }}>

        //                     {/* {type === 'multiple' && <FormDialogEditClasses
        //                         dataClasses={tableMeta.rowData}
        //                         update={props.update}
        //                         type={type}
        //                     />} */}
        //                     {/* <FormDialogDeleteClasses
        //                         dataClasses={tableMeta.rowData}
        //                         delete={props.delete}
        //                         refresh={refresh}
        //                     /> */}
                           

        //                 </div>
        //             );
        //         }
        //     }
        // }
    ];

    const options = {

        elevation: 1,
        search: false,
        filter: false,
        filterType: 'textField',
        responsive: 'stacked',
        selectableRows: 'none',
        rowsPerPageOptions: [5, 10, 25],
        serverSide: true,
        viewColumns: false,
        print: false,
        download: false,
        rowsPerPage: rowsPerPage,
        count: props?.meta?.totalDocs || 0,
        page: page,
        textLabels: {
            pagination: {
                next: "Next",
                previous: "previous",
                rowsPerPage: "Page per rows:",
                displayRows: "Page",
              },
        },

        onTableChange: (action, tableState) => {
            switch (action) {
                case 'changePage':
                    handleChangePage(tableState.page)
                    break;

                case 'changeRowsPerPage':
                    handleChangeRowsPerPage(tableState.rowsPerPage)
                    break;

                case 'search':
                    handleSearch(tableState.searchText)
                    break;

                case 'filterChange':
                    handleFilterChange(tableState.filterList[1], tableState.filterList[2])
                    break;

                case 'resetFilters':
                    handleSearch("")
                    break;

                default:
                    break;
            }
        },
    };

    return (
        <MUIDataTable
            // title="Clases"
            data={props.orders}
            columns={columns}
            options={options}
        />
    );
}

const mapStateToProps = state => ({
    orders: state?.order?.orders,
    meta: state?.order?.metaOrder,
    metaCount: state?.order?.metaCount
})

const mapActionToProps = {
    fetchPagination: actions.Pagination,
    create: actions.create,
    update: actions.update,
    delete: actions.Delete,
    getCount: actions.getCount,

}


export default connect(mapStateToProps, mapActionToProps)(ClassesTable);