import React from "react";

import PaymentsTable from "./table/PaymentsTable"



const Payments = ({ subscriptions, ...props }) => {
    return (
        <React.Fragment>
            <div className='container ptop px-3'>
                <div className="row mb-4">
                    <PaymentsTable />
                </div>

            </div>

        </React.Fragment>
    );
}


export default Payments;