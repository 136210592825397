import React, { useState, useEffect, useCallback, useContext } from 'react';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import _ from 'lodash'
import './style.css'
import { Link } from 'react-router-dom';
import { Avatar } from '@mui/material';
import moment from 'moment/min/moment-with-locales';
import DateCountdown from 'react-date-countdown-timer';
//import moment from 'moment-timezone'
//utcOffset(new Date().getTimezoneOffset())
const ClassCard = (props) => {

   
    let settings = {
        infinite: props?.data?.length>4?true: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: true,
        pauseOnHover: true,
        responsive: [{

            breakpoint: 1024,
            settings: {
                slidesToShow: 4,
            }
        },
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 3,
            }
        },
        {
            breakpoint: 600,
            settings: {
                slidesToShow: 2,
            }
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
            }
        },
        ]
    };





    return (
        <>
            <Slider {...settings}>
                {props?.data?.map((item, index) => (

                    <div key={index} className="card slick-card">
                        <Link to={{
                                    pathname: '/class-details/' + item._id,
                                    hash: "#" + item.title
                                }}
                                    state={{ data: item }}
                                    >
                        <img className="card-img-top slick-image" src={process.env.REACT_APP_IMG_URL + item.image} alt="Card image" />
                        <span className="badge badge-dark text-bg-dark text-warning fw-light" style={{position:'absolute', top: 10, left:10, borderRadius:10}}> {item.type === 'single' ?moment.duration(moment(item.datetime).diff(moment())).humanize():moment.duration(moment(item.lessons[0].datetime).diff(moment())).humanize()}</span>
                 
                        <div className="card-body ">
                            <div className='row'>
                                <div className='col-lg-12 col-12 '>

                                    <Avatar className='float-start me-2 mt-2' sx={{ width: 56, height: 56 }} alt={item.commercial.firstname + ' ' + item.commercial.lastname} src={process.env.REACT_APP_IMG_URL + item.commercial.image} />

                                    
                                    <strong className='small'>{item.title}</strong>
                                    <p className="small">{item.commercial.firstname + ' ' + item.commercial.lastname}</p>
                                    {item.type === 'single' ? <p className="small">{moment(item.datetime).local().locale('es').format("lll")}</p> : <>{moment(item.lessons[0].datetime).local().locale('es').format('lll')}</>}
                                </div>
                            </div>

                            <div className='text-center mt-2'>
                                <a className='small'>Ver más</a>
                            </div>

                        </div>
                        </Link>
                    </div>
                ))}
            </Slider>
        </>

    )
}


export default ClassCard
