import API from "../utils/api";

export const ACTION_TYPES = {
    ORDER_CREATE: 'ORDER_CREATE',
    ORDER_UPDATE: 'ORDER_UPDATE',
    ORDER_DELETE: 'ORDER_DELETE',
    ORDER_FETCH: 'ORDER_FETCH',
    ORDER_FETCH_ALL: 'ORDER_FETCH_ALL',
    ORDER_PAGINATION: 'ORDER_PAGINATION',
    ORDER_GETMYORDERS: 'ORDER_GETMYORDERS',
    ORDER_COUNT: 'ORDER_COUNT',
    ORDER_EARNINGS: 'ORDER_EARNINGS'
}

export const fetchAll = () => dispatch => {
    API.order().fetchAll()
        .then(res => {
      
            dispatch({
                type: ACTION_TYPES.ORDER_FETCH_ALL,
                payload: res.data
            })
        })
        .catch(err => console.log(err))
}

export const Pagination = (page = 1, limit = 5, filterbyID = "", isUpcoming ="",) => dispatch => {
    API.order().fetchPagination(page, Math.abs(limit), filterbyID, isUpcoming)
        .then(res =>{
         
            dispatch({
                type: ACTION_TYPES.ORDER_PAGINATION,
                payload: res.data
            })
        })
        .catch(err => console.log(err))
}
export const getEarnings = (page = 1, limit = 5, commercialid = "") => dispatch => {
    API.order().getEarnings(page, Math.abs(limit), commercialid)
        .then(res =>{
         console.log('2323',res.data)
            dispatch({
                type: ACTION_TYPES.ORDER_EARNINGS,
                payload: res.data
            })
        })
        .catch(err => console.log(err))
}

export const fetchById = (id, onSuccess) => dispatch => {
    API.order().fetchById(id)
        .then(res =>{
            dispatch({
                type: ACTION_TYPES.ORDER_FETCH,
                payload: res.data
            })
            onSuccess(res.data)
        })
        .catch(err => console.log(err))
}

export const create = (input, onSuccess) => dispatch => {
    API.order().create(input)
        .then(res =>{
            dispatch({
                type: ACTION_TYPES.ORDER_CREATE,
                payload: res.data
            })
            onSuccess(res.data)
        })
        .catch(err => console.log(err))
}

export const update = (id, input, onSuccess) => dispatch => {

    API.order().update(id, input)
        .then(res =>{
            dispatch({
                type: ACTION_TYPES.ORDER_UPDATE,
                payload: res.data
            })
            onSuccess()
        })
        .catch(err => console.log(err))
}

export const Delete = (id, onSuccess) => dispatch => {
    API.order().delete(id)
        .then(res =>{
         
            dispatch({
                type: ACTION_TYPES.ORDER_DELETE,
                payload: res.data.id
            })
            onSuccess()
        })
        .catch(err => console.log(err))
}


export const getMyOrders = (id) => dispatch => {
    API.order().getMyOrders(id)
        .then(res =>{
            dispatch({
                type: ACTION_TYPES.ORDER_GETMYORDERS,
                payload: res.data
            })
          
        })
        .catch(err => console.error(err))
}

export const getCount = (input, onSuccess) => dispatch => {
    API.order().getCount(input)
        .then(res =>{
          
            dispatch({
                type: ACTION_TYPES.ORDER_COUNT,
                payload: res.data
            })
            onSuccess(res.data)
        })
        .catch(err => console.log(err))
}