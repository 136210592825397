import React, { useEffect, useState, useContext } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { IconButton } from '@mui/material';
import DeleteIcon from "@mui/icons-material/Delete";
import { toast } from 'react-toastify';
import Grow from '@mui/material/Grow';
import CopyIcon from "@mui/icons-material/CopyAll";
import { AuthContext } from "../../../../context/AuthContext";
import { initialFormStateTemplate } from "../../../../utils/enums";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Grow ref={ref} {...props} />;
});

const FormDialogCloneTemplates = (props) => {
  const { getAuthUser } = useContext(AuthContext);
  const authUser = getAuthUser();
  const [open, setOpen] = useState(false);
  const [existingData, setTemplates] = useState(initialFormStateTemplate);
  const [errors, setErrors] = useState({});
  const [serverActionIsInProgress, setServerActionIsInProgress] = useState(false);

  const onError = (err) => {
    setServerActionIsInProgress(false);
    if (err?.show_message2user) {
      props.refresh();
      setOpen(false);
      toast.error(err.message);
    }
  };

  const handleOpen = () => {
    setOpen(true);
  }

  const handleClose = () => {
    setOpen(false);
  }
  const handleCloneTemplate = (e) => {
    setErrors({});

    existingData.id = "";
    existingData.title = "CLONE-" + props.dataTemplates[1];
    existingData.description = props.dataTemplates[2];
    existingData.template_schema = props.dataTemplates[3];
    existingData.template_schema_pdf_url = "";
    existingData.image = props.dataTemplates[5];
    existingData.status = props.dataTemplates[6];
    existingData.type = props.dataTemplates[7];
    existingData.owner = props.dataTemplates[8];
    existingData.createdBy = authUser.id


    const onSuccess = () => {
      setServerActionIsInProgress(false);
      setTimeout(
        () => props.refresh(),
        1000
      );
      setOpen(false);
      toast.success('Template Cloned');

    };
    setServerActionIsInProgress(true);
    if (existingData.id) {
      props.update(existingData.id, existingData, onSuccess, onError);
    } else {
      props.create(existingData, onSuccess, onError);
    }

  }
  
  return (
    <div>
      <IconButton onClick={handleOpen}>
        <CopyIcon />
      </IconButton>
      <Dialog
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
        aria-labelledby="form-dialog-title"
        fullWidth
        disablePortal
      >
        <DialogTitle id="form-dialog-title" className="text-lg-center">Clone Template</DialogTitle>

        <DialogContent style={{ padding: "30px 30px 10px 30px" }}>
          <DialogContentText>
            Are you sure want to clone this template?
            <br/>
            <b>"{props?.dataTemplates[1]}"</b>
          </DialogContentText>
        </DialogContent>

        <DialogActions style={{ padding: 30 }}>
          <Button variant="contained" onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button variant="contained" onClick={handleCloneTemplate} color="secondary">
            Clone
          </Button>
        </DialogActions>

      </Dialog>
    </div>
  );
}

export default FormDialogCloneTemplates;