import React, { useEffect, useState } from "react";

import "./style.css"
import { connect } from "react-redux";
import * as actions from "../../actions/class";
import ClassCard from "../../components/ClassCard";
import moment from 'moment/min/moment-with-locales';
import { Avatar } from "@mui/material";
import { Link } from 'react-router-dom';

const OnlineClasses = (props) => {

  useEffect(() => {
    props.fetchRandomClasses({type:'single'})
  }, [])
  useEffect(() => {
    console.log(props.randomClasses)
  }, [props.randomClasses])

  return (
    <>
      <div className='container bg-yellow py-4 mb-4'>

      </div>

      <div className='container'>
        <div className='text-center mb-4'>
          <h3 className='fw-bold'>Clases</h3>
        </div>
        <div className='row mb-5'>
          {props?.randomClasses?.map((item, index) => (
          <div className="col-lg-3 mb-4">
            <div className="card">
              <img className="card-img-top slick-image" src={process.env.REACT_APP_IMG_URL + item.image} alt="Card image" />
              <div className="card-body ">
                <div className='row'>
                  <div className='col-lg-12 col-12 '>

                    <Avatar className='float-start me-2 mt-2' sx={{ width: 56, height: 56 }} alt={item.commercial.firstname + ' ' + item.commercial.lastname} src={process.env.REACT_APP_IMG_URL + item.commercial.image} />


                    <strong>{item.title}</strong>
                    <p className="small">{item.commercial.firstname + ' ' + item.commercial.lastname}</p>
                    {item.type === 'single' ? <p className="small">{moment(item.datetime).local().locale('es').format('lll')}</p> : <>{moment(item.lessons[0].datetime).local().locale('es').format('lll')}</>}
                  </div>
                </div>

                <div className='text-center mt-2'>
                  <Link to={{
                    pathname: '/class-details/' + item._id,
                    hash: "#" + item.title
                  }}
                    state={{ data: item }}
                    className='small'>Ver más</Link>
                </div>

              </div>
            </div>
            </div>
          ))}

        </div>
      </div>

    </>


  )
}


const mapStateToProps = state => ({
  randomClasses: state?.classes?.randomClasses,
})

const mapActionToProps = {
  fetchRandomClasses: actions.fetchRandomClasses,
}

export default connect(mapStateToProps, mapActionToProps)(OnlineClasses);