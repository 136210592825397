import API from "../utils/api";

export const ACTION_TYPES = {
    TEMPLATES_CREATE: 'TEMPLATES_CREATE',
    TEMPLATES_UPDATE: 'TEMPLATES_UPDATE',
    TEMPLATES_DELETE: 'TEMPLATES_DELETE',
    TEMPLATES_FETCH: 'TEMPLATES_FETCH',
    TEMPLATES_FETCH_ALL: 'TEMPLATES_FETCH_ALL',
    TEMPLATES_PAGINATION: 'TEMPLATES_PAGINATION',
    TEMPLATES_FETCH_RANDOM_TEMPLATES: 'TEMPLATES_FETCH_RANDOM_TEMPLATES'
}

export const fetchAll = (_userId, onError) => dispatch => {
    API.templates().fetchAll(_userId)
        .then(res => {

            dispatch({
                type: ACTION_TYPES.TEMPLATES_FETCH_ALL,
                payload: res.data
            })
        })
        .catch(err => {
            onError({ ...err.response.data, status: err.response.status })
        })
}

export const Pagination = (page = 1, limit = 10, title = "", _userId, onError) => dispatch => {
    API.templates().fetchPagination(page, Math.abs(limit), title, _userId)
        .then(res => {
            console.log('API.templates().', res.data)
            dispatch({
                type: ACTION_TYPES.TEMPLATES_PAGINATION,
                payload: res.data
            })
        })
        .catch(err => {
            onError({ ...err.response.data, status: err.response.status })
        })
}

export const fetchById = (id, onSuccess, onError) => dispatch => {
    API.templates().fetchById(id)
        .then(res => {
            dispatch({
                type: ACTION_TYPES.TEMPLATES_FETCH,
                payload: res.data
            })
            onSuccess(res.data)
        })
        .catch(err => {
            onError({ ...err.response.data, status: err.response.status })
        })
}

export const create = (input, onSuccess, onError) => dispatch => {
    API.templates().create(input)
        .then(res => {
            dispatch({
                type: ACTION_TYPES.TEMPLATES_CREATE,
                payload: res.data
            })
            onSuccess()
        })
        .catch(err => {
            onError({ ...err.response.data, status: err.response.status })
        })
}

export const update = (id, input, onSuccess, onError) => dispatch => {

    API.templates().update(id, input)
        .then(res => {
            dispatch({
                type: ACTION_TYPES.TEMPLATES_UPDATE,
                payload: res.data
            })
            onSuccess()
        })
        .catch(err => {
            onError({ ...err.response.data, status: err.response.status })
        })
}

export const Delete = (id, onSuccess, onError) => dispatch => {
    API.templates().delete(id)
        .then(res => {

            dispatch({
                type: ACTION_TYPES.TEMPLATES_DELETE,
                payload: res.data.id
            })
            onSuccess()
        })
        .catch(err => {
            onError({ ...err.response.data, status: err.response.status })
        })
}

export const fetchRandomTemplates = (param, onError) => dispatch => {
    API.templates().fetchRandomTemplates(param)
        .then(res => {

            dispatch({
                type: ACTION_TYPES.TEMPLATES_FETCH_RANDOM_TEMPLATES,
                payload: res.data
            })
        })
        .catch(err => {
            onError({ ...err.response.data, status: err.response.status })
        })
}