import { ACTION_TYPES } from "../actions/templates";

const initialState = {
    templates: [],
    metaCourse: {}
}

export const templates = (state = initialState, action) => {
    switch (action.type) {
        case ACTION_TYPES.TEMPLATES_FETCH_ALL:
            return {
                ...state,
                templates: [...action.payload]
            }
        case ACTION_TYPES.TEMPLATES_CREATE:
            return {
                ...state,
                templates: [...state.templates, action.payload]
            }
        case ACTION_TYPES.TEMPLATES_UPDATE:
            return {
                ...state,
                templates: state.templates.map(x => x.id === action.payload.id ? action.payload : x)
            }
        case ACTION_TYPES.TEMPLATES_DELETE:
            return {
                ...state,
                templates:state.templates.filter(x => x.id !== action.payload)
            }
        case ACTION_TYPES.TEMPLATES_PAGINATION:
         
            return {
                ...state,
                templates: [...action.payload.templates],
                metaTemplate: action.payload.meta
            }
            case ACTION_TYPES.TEMPLATES_FETCH_RANDOM_TEMPLATES:
      
                    return {
                        ...state,
                        randomTemplates: [...action.payload]
                    }
        default:
            return state;
    }
}