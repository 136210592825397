import React, { useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from '@mui/material/Dialog';
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { ColorPicker, useColor } from "react-color-palette";
import "react-color-palette/css";
import { IconButton, Select, MenuItem, InputLabel, FormControl, Checkbox, FormControlLabel } from '@mui/material';
import { toast } from 'react-toastify';
import Grow from '@mui/material/Grow';
import API from "../../../../utils/api";
import { EnumTemplatesFieldType, EnumTemplatesFieldTypeWithLabel, initialFormStateTemplate, EnumTemplateFieldConditionalAction } from "../../../../utils/enums";
import moment from "moment";
import EditIcon from '@mui/icons-material/Edit';
import PolylineIcon from '@mui/icons-material/Polyline';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import _ from "lodash";
import { handleFormUploadSchemaEvent } from "../../../../utils/templates_util";
import { Form } from '@flipbyte/formik-json';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Grow ref={ref} {...props} />;
});

const FormDialogEditTemplates = (props) => {
  const [open, setOpen] = useState(false);
  const [existingData, setTemplates] = useState(initialFormStateTemplate);
  const [errors, setErrors] = useState({});
  const [avatar, setAvatar] = useState(existingData?.image);
  const [addLogic4Control, setAddLogic4Control] = useState({});
  const [serverActionIsInProgress, setServerActionIsInProgress] = useState(false);
  const [color, setColor] = useColor("#561ecb");
  const [colorPalletVisibleName, setColorPalletVisibleName] = useState("");

  const _do_not_show_percentage_for_question = true;

  const setColorForOptionsOnChange = (event, propertyName) => {
    setColor(event);
    _.set(existingData, propertyName, event);
    setTemplates({ ...existingData });
  }
  const setResetColorPalletVisibleName = (propertyName) => {
    if (colorPalletVisibleName == propertyName) {
      setColorPalletVisibleName("");
    } else {
      setColorPalletVisibleName(propertyName);
    }
  }

  const onError = (err) => {
    setServerActionIsInProgress(false);
    if (err?.show_message2user) {
      props.refresh();
      setOpen(false);
      toast.error(err.message);
    }
  };

  const handleClickOpen = () => {
    setAddLogic4Control({});
    setErrors({});
    setTemplates({
      id: props.dataTemplates[0],
      title: props.dataTemplates[1],
      description: props.dataTemplates[2],
      template_schema: props.dataTemplates[3],
      template_schema_pdf_url: props.dataTemplates[4],
      image: props.dataTemplates[5],
      status: props.dataTemplates[6],
      type: props.dataTemplates[7],
      owner: props.dataTemplates[8],
      createdBy: (props.dataTemplates[9]?._id || (props.dataTemplates[9]?.id || props.dataTemplates[9]))
    })
    setOpen(true);
  }

  const handleClose = () => {
    setOpen(false);
  }

  const handleInputChange = event => {
    const { name, value } = event.target;
    // const nestedAttr = name.split(".");
    // const lengthOfNestedAttr = nestedAttr.length;
    //     let existingObj;
    //     let updatedObj={};
    //     nestedAttr.map((attr, index) => {
    //       if (index + 1 === lengthOfNestedAttr) {
    //         existingObj[attr]=value;
    //         console.log('93 :: ',index, attr, value, existingObj);
    //       }else{
    //         if(existingObj){
    //           existingObj =  existingObj[attr]; 
    //         }else{
    //           existingObj = existingData[attr]; 
    //         }       
    //       }
    //       let newArray = [...existingObj];

    //       let _obj={};
    //       _obj[attr]=existingObj;
    // newArray[attr] = {...newArray[attr], _obj};
    //       updatedObj[attr]=existingObj;
    //     });
    // setTemplates({ ...existingData, [name]: value })
    _.set(existingData, name, value);
    setTemplates({ ...existingData });

  }

  const handleInputChangeForFileType = event => {
    // if (event.target.files[0].size / 1024 > 200) {
    //   toast.warning('maximum size allowed 200 kbs');
    //   return
    // }
    var formdata = new FormData();
    formdata.append("file", event.target.files[0]);
    formdata.append("documentId", Date.now());
    API.utility().upload(formdata)
      .then(res => {
        setTemplates({ ...existingData, image: res.data.data.path });
      })

  }

  const validate = () => {
    let tempErrors = {};
    let formIsValid = true;

    if (!existingData.title) {
      formIsValid = false;
      tempErrors["title"] = "Cannot be empty";
    }

    if (!existingData.description) {
      formIsValid = false;
      tempErrors["description"] = "Cannot be empty";
    }


    setErrors(tempErrors);
    return formIsValid;
  }

  const handleSubmit = (e) => {

    const onSuccess = () => {
      setServerActionIsInProgress(false);
      setOpen(false);
      toast.success('Template Saved');
    }
    e.preventDefault();

    if (validate() && serverActionIsInProgress == false) {
      setServerActionIsInProgress(true);
      props.update(existingData.id, existingData, onSuccess, onError)
    }
  }

  const addSectionPageFor_template_schema = (e) => {
    const count_existing_pages = _.keys(existingData.template_schema.elements || []).length;
    let new_page = {};
    const new_page_name = "template_page_" + _.now();//+ (count_existing_pages + 1);
    new_page[new_page_name] = {
      title: "New Page",
      type: "container",
      renderer: "fieldset",
      htmlClass: "container",
      elements: {
      }
    };
    existingData.template_schema.elements[new_page_name] = new_page[new_page_name];

    const btn_page_name = "buttonsGroup";
    new_page[btn_page_name] = existingData.template_schema.elements[btn_page_name];
    delete existingData.template_schema.elements[btn_page_name];
    existingData.template_schema.elements[btn_page_name] = new_page[btn_page_name];

    setTemplates({ ...existingData });
  }
  const removeSectionPageFor_template_schema = (_nestedPathFromRoot, _question_id_2_remove) => {
    delete existingData.template_schema.elements[_question_id_2_remove];
    setTemplates({ ...existingData });
  }
  const addQuestion_for_Page_template_schema = (_nestedPathFromRoot, _fieldType) => {
    const count_existing_pages = _.keys(_.get(existingData, _nestedPathFromRoot) || []).length;
    const new_question_name = "page_question_" + _.now();//+ (count_existing_pages + 1);

    _.set(existingData, _nestedPathFromRoot + '.' + new_question_name, _return_template_for_new_question(new_question_name, _fieldType));
    setTemplates({ ...existingData });
  }
  const addQuestion_for_Page_template_schema_after_supplied_question = (_nestedPathFromRoot, _name_of_SuperQuestion, _fieldType) => {

    const count_existing_pages = _.keys(_.get(existingData, _nestedPathFromRoot) || []).length;
    const new_question_name = "page_question_" + _.now();//+ (count_existing_pages + 1);

    let _all_questions_of_current_page = _.get(existingData, _nestedPathFromRoot);
    let _temp_new_sequence_of_all_questions_of_current_page = {};
    let _new_question_obj = _return_template_for_new_question(new_question_name, _fieldType);

    //#endregion update show when validtion for new child field obj
    for (let key in _all_questions_of_current_page) {
      _temp_new_sequence_of_all_questions_of_current_page[key] = _all_questions_of_current_page[key];
      if (key === _name_of_SuperQuestion) {
        _temp_new_sequence_of_all_questions_of_current_page[new_question_name] = _new_question_obj;
      }
    }

    _.set(existingData, _nestedPathFromRoot, _temp_new_sequence_of_all_questions_of_current_page);
    setTemplates({ ...existingData });
  }
  const addQuestion_for_Page_template_schema_as_child_for_supplied_parent = (_nestedPathFromRoot, _name_of_SuperQuestion, _add_logic_trigger_key, _fieldType) => {

    const count_existing_pages = _.keys(_.get(existingData, _nestedPathFromRoot) || []).length;
    const new_question_name = "page_question_" + _.now();//+ (count_existing_pages + 1);

    let _all_questions_of_current_page = _.get(existingData, _nestedPathFromRoot);
    let _temp_new_sequence_of_all_questions_of_current_page = {};
    let _new_question_obj = _return_template_for_new_question(new_question_name, _fieldType);



    //#region update show when validtion for new child field obj
    //pending here
    let _current_depth_level_of_child_question = 0;
    if (_all_questions_of_current_page[_name_of_SuperQuestion]) {
      if ((_all_questions_of_current_page[_name_of_SuperQuestion]?.super_question_data)) {
        if ((_all_questions_of_current_page[_name_of_SuperQuestion]?.super_question_data?.current_depth_level_of_child_question || 0) >= 0) {
          _current_depth_level_of_child_question = (_all_questions_of_current_page[_name_of_SuperQuestion]?.super_question_data?.current_depth_level_of_child_question || 0) + 1;
        }
      }
    }
    let conditional_obj = _.cloneDeep(addLogic4Control[_add_logic_trigger_key]);
    _new_question_obj.super_question_data = conditional_obj;
    _new_question_obj.super_question_data.name_of_super_question = _name_of_SuperQuestion;
    _new_question_obj.super_question_data.current_depth_level_of_child_question = _current_depth_level_of_child_question;
    _new_question_obj.super_question_data.fieldType = _all_questions_of_current_page[_name_of_SuperQuestion].fieldType;
    _new_question_obj.showWhen = _return_showWhen_template_for_question(_name_of_SuperQuestion, conditional_obj.add_logic_condition, conditional_obj.add_logic_value, _new_question_obj.super_question_data.fieldType);
    //#endregion update show when validtion for new child field obj

    let _add_new_question_after_this_question = _name_of_SuperQuestion;

    for (let key in _all_questions_of_current_page) {
      let _current_question_data_obj = _all_questions_of_current_page[key];
      if (_current_question_data_obj?.super_question_data?.name_of_super_question === _name_of_SuperQuestion) {
        _add_new_question_after_this_question = key;
      }
    }

    for (let key in _all_questions_of_current_page) {
      _temp_new_sequence_of_all_questions_of_current_page[key] = _all_questions_of_current_page[key];
      if (key === _add_new_question_after_this_question) {
        _temp_new_sequence_of_all_questions_of_current_page[new_question_name] = _new_question_obj;
      }
    }

    _.set(existingData, _nestedPathFromRoot, _temp_new_sequence_of_all_questions_of_current_page);
    setTemplates({ ...existingData });
    //set required to false
    _update_value_for_question_is_set_validation_required(_nestedPathFromRoot + '.' + new_question_name + '.validation', false)
  }
  const removeQuestion_from_Page_template_schema = (_nestedPathFromRoot, new_question_name) => {

    const _all_questions_data = _.get(existingData, _nestedPathFromRoot);
    delete _all_questions_data[new_question_name];
    _.set(existingData, _nestedPathFromRoot, _all_questions_data);
    setTemplates({ ...existingData });

  }
  const _update_value_for_question_is_set_validation_required = (_nestedPathFromRoot, isCheckedT) => {
    let _existing_validation = _.get(existingData, _nestedPathFromRoot) || [];

    if (isCheckedT) {
      //#region add validation only if missing
      if (!_returnT_for_question_is_set_validation_required(_nestedPathFromRoot)) {
        if (!_existing_validation) {
          _existing_validation = [];
        }
        _existing_validation.push(["required", "*Required"]);
      }
      //#endregion add validation only if missing
    } else {
      //#region remove validation only if present
      if (_returnT_for_question_is_set_validation_required(_nestedPathFromRoot)) {
        if (!_existing_validation) {
          _existing_validation = [];
        }
        _existing_validation.map((obj, index) => {
          if (obj) {
            if (obj.indexOf("required") > -1) {
              _existing_validation.splice(index, 1);
            }
          }
        });

      }
      //#endregion remove validation only if present
    }
    if (_.keys(_existing_validation || []).length === 0) {
      _existing_validation = undefined;
    }
    _.set(existingData, _nestedPathFromRoot, _existing_validation);
    setTemplates({ ...existingData });
  }

  const _returnT_for_question_is_set_validation_required = (_nestedPathFromRoot) => {
    let _is_requiredT = false;
    let _existing_validation = _.get(existingData, _nestedPathFromRoot) || [];
    _existing_validation.map((obj, index) => {
      if (obj) {
        if (obj.indexOf("required") > -1) {
          _is_requiredT = true;
        }
      }
    });
    return _is_requiredT;
  }

  const _returnT_for_question_points_enabled = (_nestedPathFromRoot) => {
    let _is_requiredT = false;
    let _existing_validation = _.get(existingData, _nestedPathFromRoot) || 0;
    if (_existing_validation) {
      _is_requiredT = true;
    }
    return _is_requiredT;
  }

  const _update_value_for_question_points_enabled = (_nestedPathFromRoot, isCheckedT) => {
    let _existing_points = _.get(existingData, _nestedPathFromRoot) || 0;

    if (isCheckedT) {
      //#region add validation only if missing
      if (!_returnT_for_question_points_enabled(_nestedPathFromRoot)) {

        _existing_points = 1;
      }
      //#endregion add validation only if missing
    } else {
      //#region remove validation only if present
      if (_returnT_for_question_points_enabled(_nestedPathFromRoot)) {
        _existing_points = 0;
      }
      //#endregion remove validation only if present
    }
    if (_existing_points === 0) {
      _existing_points = undefined;
    }
    _.set(existingData, _nestedPathFromRoot, _existing_points);
    setTemplates({ ...existingData });
  }

  const _return_single_point_value_out_of_100_for_question_points_enabled = (_nestedPathFromRoot) => {
    let _existing_points = _.get(existingData, _nestedPathFromRoot) || 0;
    let _total_questions_with_points_found = 0;
    if (_existing_points) {
      if (_existing_points === 1) {
        //#region here value is 1 so calculate from rest all points
        let _list_of_all_pages = _.values(_.get(existingData, "template_schema.elements"));
        for (let _single_page of _list_of_all_pages) {
          for (let _single_question of _.values(_single_page.elements)) {
            if (_single_question?.points) {
              _total_questions_with_points_found = _total_questions_with_points_found + 1;
            }
          }
        }
        return (100 / _total_questions_with_points_found).toFixed(2);
        //#endregion here value is 1 so calculate from rest all points
      } else {
        //#region here value is greater than 1 so return same
        return _existing_points;
        //#endregion here value is greater than 1 so return same
      }
    } else {
      return 0;
    }
  }

  const _updateQuestion_of_Page_template_schema = (_nestedPathFromRoot, _fieldType) => {

    const _existing_data = _.get(existingData, _nestedPathFromRoot);
    const old_question_name = _existing_data.name;
    const _old_label_value = _existing_data.label || _existing_data.title;
    const _updated_question_template_data = _return_template_for_new_question(old_question_name, _fieldType, _old_label_value);
    _.set(existingData, _nestedPathFromRoot, _updated_question_template_data);
    setTemplates({ ...existingData });
  }

  const _return_template_for_new_question = (new_question, _fieldType, _old_label_value) => {
    _fieldType = _fieldType || EnumTemplatesFieldType.text;
    switch (_fieldType) {
      case EnumTemplatesFieldType.telephone:
        return {
          name: "telephone",
          label: _old_label_value || "Telephone",
          type: "field",
          renderer: "text",
          fieldType: "telephone",
          validation: [
            ['string']
          ]
        };
        break;
      case EnumTemplatesFieldType["file-uploader"]:
        return {
          name: new_question,
          label: _old_label_value || "File Uploader",
          type: "field",
          renderer: "file-uploader",
          fieldType: "file-uploader",
          options: {
            accept: ['image/*'],
            multiple: true,
            onDrop: (formik, config, acceptedFiles) => {
              console.log(formik, config, acceptedFiles);

            }
            // onDrop: handleFormUploadSchemaEvent
          },
          validation: [
            ['string']
          ],
          attributes: {
            do_not_show_required: true,
          },
        };
        break;
      case EnumTemplatesFieldType.email:
        return {
          name: new_question,
          label: _old_label_value || "Email",
          type: "field",
          renderer: "text",
          fieldType: "email",
          validation: [
            ['string'],
            // ['required'],
            ['email']
          ]
        };
        break;
      case EnumTemplatesFieldType.switch:
        return {
          name: new_question,
          label: _old_label_value || "Switch",
          type: "field",
          renderer: "switch",
          fieldType: "switch",
          validation: [
            ['bool'],
            // // ['test', new_question+'.0', 'You have to select this value', value => value === true],
            // ['required', 'You have to select this value']
          ]
        };
        break;
      case EnumTemplatesFieldType.radio:
        return {
          name: new_question,
          label: _old_label_value || "Radio",
          type: "field",
          renderer: "radio",
          fieldType: "radio",
          labelClass: "form-label",
          fieldClass: "d-inline",
          formGroupClass: "form-check form-check-inline",
          htmlClass: "input-group",
          options: [{
            value: "response_1",
            title: "Response 1"
          }],
          validation: [
            // ['bool'],
            ['string'],
            // // ['test', new_question+'.0', 'You have to select this value', value => value === true],
            // ['required', 'You have to select this value']
          ]
        };
        break;
      case EnumTemplatesFieldType.checkbox:
        return {
          name: new_question,
          label: _old_label_value || "Choose option",
          type: "field",
          renderer: "checkbox",
          fieldType: "checkbox",
          labelClass: "mr-2",
          fieldClass: "d-inline",
          formGroupClass: "form-check form-check-inline",
          htmlClass: "form-group",
          options: [{
            value: 'response_1',
            label: 'Response 1'
          }],
          validation: [
            // ['bool'],
            ['string'],
            // // ['test', new_question+'.0', 'You have to select this value', value => value === true],
            // ['required', 'You have to select this value']
          ]
        };
        break;
      case EnumTemplatesFieldType.date:
        return {
          name: new_question,
          label: _old_label_value || "Date",
          type: "field",
          renderer: "text",
          fieldType: "date",
          validation: [
            ['date'],
            // ['required']
          ]
        };
        break;
      case EnumTemplatesFieldType.number:
        return {
          name: new_question,
          label: _old_label_value || "Number",
          type: "field",
          renderer: "text",
          fieldType: "number",
          validation: [
            ['number'],
            // ['required']
          ]
        };
      case EnumTemplatesFieldType.range:
        return {
          name: new_question,
          label: _old_label_value || "Range",
          type: "field",
          renderer: "text",
          fieldType: "range",
          validation: [
            ['number'],
            // ['required']
          ],
          attributes: {
            min: 0,
            max: 100,
            increment: 2
          }
        };
        break;
      case EnumTemplatesFieldType.textarea:
        return {
          name: new_question,
          label: _old_label_value || "Description",
          attributes: {
            placeholder: "Please Enter"
          },
          type: "field",
          renderer: "textarea",
          fieldType: "textarea",
          validation: [
            ["string"],
            // ["required", "Please enter your question"]
          ]
        };
        break;
      case EnumTemplatesFieldType.text:
        return {
          name: new_question,
          label: _old_label_value || "Type Question",
          attributes: {
            placeholder: "Please Enter"
          },
          type: "field",
          renderer: "text",
          fieldType: "text",
          validation: [
            ["string"],
            // ["required", "Please enter your question"]
          ]
        };
        break;
      case EnumTemplatesFieldType["inner-text"]:
        return {
          name: new_question,
          label: "Instructions",
          type: "field",
          renderer: "inner-text",
          fieldType: "inner-text",

          // as: "i",
          // htmlClass: 'text-muted d-block mb-3 mt-1',
          // defaultValue: 'Instructions'

        };
        break;
    }
  }
  const _return_showWhen_template_for_question = (_field_name_to_check, enum_value2forcondition, check_for_conditions_array, _super_question_data_fieldType) => {

    let _show_when_condition = ['or', ['is', _field_name_to_check, ''], ['isOfType', _field_name_to_check, 'undefined']]; //['not', ['or', ['is', _field_name_to_check, ''], ['isOfType', _field_name_to_check, 'undefined']]];
    let _show_when_condition_2nd_level = [];
    switch (_super_question_data_fieldType) {
      case EnumTemplatesFieldType.checkbox:
        switch (enum_value2forcondition) {
          case EnumTemplateFieldConditionalAction.is:
            if (_.isArray(check_for_conditions_array)) {
              _show_when_condition = ['or'];//['is'];
              _show_when_condition_2nd_level = ['or'];
              for (let index in check_for_conditions_array) {
                // _show_when_condition_2nd_level.push(['is', _field_name_to_check + '[' + (parseInt(check_for_conditions_array[index].split('_')[1])-1) + '][0]', "on"]);
                _show_when_condition.push(['is', _field_name_to_check + '[' + (parseInt(check_for_conditions_array[index].split('_')[1]) - 1) + '][0]', "on"]);
              }
              // _show_when_condition.push(_show_when_condition_2nd_level);
            } else {
              _show_when_condition = ['is', _field_name_to_check + '[' + (parseInt(check_for_conditions_array.split('_')[1]) - 1) + '][0]', "on"];
            }
            break;
          case EnumTemplateFieldConditionalAction.is_not:
            if (_.isArray(check_for_conditions_array)) {
              _show_when_condition = ['not'];
              _show_when_condition_2nd_level = ['or'];
              for (let index in check_for_conditions_array) {
                _show_when_condition_2nd_level.push(['is', _field_name_to_check + '[' + (parseInt(check_for_conditions_array[index].split('_')[1]) - 1) + '][0]', "on"]);
              }
              _show_when_condition.push(_show_when_condition_2nd_level);
            } else {
              _show_when_condition = ['not', ['is', _field_name_to_check + '[' + (parseInt(check_for_conditions_array.split('_')[1]) - 1) + '][0]', "on"]];
            }
            break;
          case EnumTemplateFieldConditionalAction.is_selected:
            if (_.isArray(check_for_conditions_array)) {
              _show_when_condition = ['not', ['or', ['is', _field_name_to_check + '[0][0]', ''], ['isOfType', _field_name_to_check + '[0][0]', 'undefined']]];
            } else {
              _show_when_condition = ['not', ['or', ['is', _field_name_to_check + '[0][0]', ''], ['isOfType', _field_name_to_check + '[0][0]', 'undefined']]];
            }
            break;
          case EnumTemplateFieldConditionalAction.is_not_selected:
            if (_.isArray(check_for_conditions_array)) {
              _show_when_condition = ['is', ['or', ['is', _field_name_to_check + '[0][0]', ''], ['isOfType', _field_name_to_check + '[0][0]', 'undefined']]];;
            } else {
              _show_when_condition = ['is', ['or', ['is', _field_name_to_check + '[0][0]', ''], ['isOfType', _field_name_to_check + '[0][0]', 'undefined']]];;
            }
            break;
          case EnumTemplateFieldConditionalAction.is_one_of:
            if (_.isArray(check_for_conditions_array)) {
              _show_when_condition = ['or']//['is'];
              //_show_when_condition_2nd_level = ['or'];
              for (let index in check_for_conditions_array) {
                //_show_when_condition_2nd_level.push(['is', _field_name_to_check + '[' + (parseInt(check_for_conditions_array[index].split('_')[1])-1) + '][0]', "on"]);
                _show_when_condition.push(['is', _field_name_to_check + '[' + (parseInt(check_for_conditions_array[index].split('_')[1]) - 1) + '][0]', "on"]);
              }
              // _show_when_condition.push(_show_when_condition_2nd_level);
            } else {
              _show_when_condition = ['is', _field_name_to_check + '[' + (parseInt(check_for_conditions_array.split('_')[1]) - 1) + '][0]', "on"];
            }
            break;
          case EnumTemplateFieldConditionalAction.is_not_one_of:
            if (_.isArray(check_for_conditions_array)) {
              _show_when_condition = ['not'];
              _show_when_condition_2nd_level = ['or'];
              for (let index in check_for_conditions_array) {
                _show_when_condition_2nd_level.push(['is', _field_name_to_check + '[' + (parseInt(check_for_conditions_array[index].split('_')[1]) - 1) + '][0]', "on"]);
              }
              _show_when_condition.push(_show_when_condition_2nd_level);
            } else {
              _show_when_condition = ['not', ['is', _field_name_to_check + '[' + (parseInt(check_for_conditions_array.split('_')[1]) - 1) + '][0]', "on"]];
            }
            break;
        }
        break;
      default:
        switch (enum_value2forcondition) {
          case EnumTemplateFieldConditionalAction.is:
            if (_.isArray(check_for_conditions_array)) {
              _show_when_condition = ['or'];//['is'];
              _show_when_condition_2nd_level = ['or'];
              for (let index in check_for_conditions_array) {
                // _show_when_condition_2nd_level.push(['is', _field_name_to_check, check_for_conditions_array[index]]);
                _show_when_condition.push(['is', _field_name_to_check, check_for_conditions_array[index]]);
              }
              // _show_when_condition.push(_show_when_condition_2nd_level);
            } else {
              if (check_for_conditions_array) {
                _show_when_condition = ['is', _field_name_to_check, check_for_conditions_array];
              }
            }
            break;
          case EnumTemplateFieldConditionalAction.is_not:
            if (_.isArray(check_for_conditions_array)) {
              _show_when_condition = ['not'];
              _show_when_condition_2nd_level = ['or'];
              for (let index in check_for_conditions_array) {
                _show_when_condition_2nd_level.push(['is', _field_name_to_check, check_for_conditions_array[index]]);
              }
              _show_when_condition.push(_show_when_condition_2nd_level);
            } else {
              if (check_for_conditions_array) {
                _show_when_condition = ['not', ['is', _field_name_to_check, check_for_conditions_array]];
              } else {
                _show_when_condition = ['not', _show_when_condition]
              }
            }
            break;
          case EnumTemplateFieldConditionalAction.is_selected:
            if (_.isArray(check_for_conditions_array)) {
              _show_when_condition = ['not', ['or', ['is', _field_name_to_check, ''], ['isOfType', _field_name_to_check, 'undefined']]];
            } else {
              _show_when_condition = ['not', ['or', ['is', _field_name_to_check, ''], ['isOfType', _field_name_to_check, 'undefined']]];
            }
            break;
          case EnumTemplateFieldConditionalAction.is_not_selected:
            if (_.isArray(check_for_conditions_array)) {
              _show_when_condition = ['is', ['or', ['is', _field_name_to_check, ''], ['isOfType', _field_name_to_check, 'undefined']]];;
            } else {
              _show_when_condition = ['is', ['or', ['is', _field_name_to_check, ''], ['isOfType', _field_name_to_check, 'undefined']]];;
            }
            break;
          case EnumTemplateFieldConditionalAction.is_one_of:
            if (_.isArray(check_for_conditions_array)) {
              _show_when_condition = ['or'];//['is'];
              _show_when_condition_2nd_level = ['or'];
              for (let index in check_for_conditions_array) {
                // _show_when_condition_2nd_level.push(['is', _field_name_to_check, check_for_conditions_array[index]]);
                _show_when_condition.push(['is', _field_name_to_check, check_for_conditions_array[index]]);
              }
              // _show_when_condition.push(_show_when_condition_2nd_level);
            } else {
              _show_when_condition = ['is', _field_name_to_check, check_for_conditions_array];
            }
            break;
          case EnumTemplateFieldConditionalAction.is_not_one_of:
            if (_.isArray(check_for_conditions_array)) {
              _show_when_condition = ['not'];
              _show_when_condition_2nd_level = ['or'];
              for (let index in check_for_conditions_array) {
                _show_when_condition_2nd_level.push(['is', _field_name_to_check, check_for_conditions_array[index]]);
              }
              _show_when_condition.push(_show_when_condition_2nd_level);
            } else {
              _show_when_condition = ['not', ['is', _field_name_to_check, check_for_conditions_array]];
            }
            break;
        }
        break;
    }
    return _show_when_condition;
  }
  const addOptions_to_question_in_page_template_schema = (_nestedPathFromRoot, _fieldType) => {
    const _existing_data = _.get(existingData, _nestedPathFromRoot) || [];
    const _existing_data_length = _existing_data.length + 1;
    let _options_obj;
    const _maxLimitOfOptions = 10;
    // if (_existing_data_length > _maxLimitOfOptions) {
    //   toast.warning('No Action');
    //   return;
    // }
    switch (_fieldType) {
      case EnumTemplatesFieldType.radio:
        _options_obj = {
          value: 'response_' + _existing_data_length,
          title: 'Response ' + _existing_data_length,
          colorCode: '',
        };
        break;
      case EnumTemplatesFieldType.checkbox:
        _options_obj = {
          value: 'response_' + _existing_data_length,
          label: 'Response ' + _existing_data_length,
          colorCode: '',
        };
        break;
    }
    _existing_data.push(_options_obj);
    _.set(existingData, _nestedPathFromRoot, _existing_data);
    setTemplates({ ...existingData });
  }

  const onClick4AddLogic = (_nestedPathFromRoot) => {

    if (addLogic4Control[_nestedPathFromRoot] && addLogic4Control[_nestedPathFromRoot].isVisible === true) {
      addLogic4Control[_nestedPathFromRoot] = {};
      setAddLogic4Control({ ...addLogic4Control });
    } else {
      addLogic4Control[_nestedPathFromRoot] = { isVisible: true, path_name: _nestedPathFromRoot };
      setAddLogic4Control({ ...addLogic4Control });
    }
  };
  const onClick4AddLogicUpdateValues = (_nestedPathFromRoot, key2update, value2update) => {

    if (addLogic4Control[_nestedPathFromRoot] && addLogic4Control[_nestedPathFromRoot].isVisible === true) {
      addLogic4Control[_nestedPathFromRoot][key2update] = value2update;
      setAddLogic4Control({ ...addLogic4Control });
    } else {
      //should not hit here

    }
  };

  const onClick4AddLogicUpdateValuesSelectArrayType = (_nestedPathFromRoot, key2update, value2update) => {

    if (addLogic4Control[_nestedPathFromRoot] && addLogic4Control[_nestedPathFromRoot].isVisible === true) {
      if (!_.isArray(addLogic4Control[_nestedPathFromRoot][key2update])) {
        addLogic4Control[_nestedPathFromRoot][key2update] = [];
      }
      const _currentIndexOf = addLogic4Control[_nestedPathFromRoot][key2update].indexOf(value2update);
      if (_currentIndexOf > -1) {
        addLogic4Control[_nestedPathFromRoot][key2update].splice(_currentIndexOf, 1);
      } else {
        addLogic4Control[_nestedPathFromRoot][key2update].push(value2update);
      }
      setAddLogic4Control({ ...addLogic4Control });
    } else {
      //should not hit here

    }
  };

  const _return_all_child_questions_of_current_page_question = (currentPageIndex, indexInner, elementsObjAttrPage, elementsObjAttrPageInner, _add_logic_condition, _add_logic_trigger_action, _add_logic_value) => {

    //let _get_current_questions_data = _.get(existingData, 'template_schema.elements.' + elementsObjAttrPage + '.elements.' + elementsObjAttrPageInner);
    let _name_of_super_question = elementsObjAttrPageInner;


    let _all_child_questions_of_current_parent = _.filter(_.values(_.get(existingData, 'template_schema.elements.' + elementsObjAttrPage + '.elements')), (item) => {
      if (item && item.super_question_data && item.super_question_data.name_of_super_question === _name_of_super_question) {
        //////below code is used to make different groups of same logic but different field type
        // if (_add_logic_condition && _add_logic_trigger_action) {
        //   if (((_.isArray(item.super_question_data.add_logic_value) === true && _.intersection(_add_logic_value, item.super_question_data.add_logic_value).length > 0) || (_.isArray(item.super_question_data.add_logic_value) === false && _add_logic_value === item.super_question_data.add_logic_value)) && _add_logic_condition == item.super_question_data.add_logic_condition && _add_logic_trigger_action === item.super_question_data.add_logic_trigger_action) {
        //     return true;
        //   } else {
        //     return false;
        //   }
        // }
        //////below code is used to make same group of same logic of all field type
        if (_add_logic_condition) {
          if (((_.isArray(item.super_question_data.add_logic_value) === true && _.intersection(_add_logic_value, item.super_question_data.add_logic_value).length > 0) || (_.isArray(item.super_question_data.add_logic_value) === false && _add_logic_value === item.super_question_data.add_logic_value)) && _add_logic_condition == item.super_question_data.add_logic_condition) {
            return true;
          } else {
            return false;
          }
        }
        else {
          return true;
        }
      }
      return false;
    });

    return _.mapKeys(_all_child_questions_of_current_parent, 'name');
  };
  const _return_grouping_data_for__all_child_questions_of_current_page_question = (currentPageIndex, indexInner, elementsObjAttrPage, elementsObjAttrPageInner, _add_logic_condition, _add_logic_trigger_action, _add_logic_value) => {

    let _list_of_all_child_questions_from_current_parent_questions = _.cloneDeep(_return_all_child_questions_of_current_page_question(currentPageIndex, indexInner, elementsObjAttrPage, elementsObjAttrPageInner, _add_logic_condition, _add_logic_trigger_action, _add_logic_value));

    let _current_super_question_data_from_parent = _.get(existingData, 'template_schema.elements.' + elementsObjAttrPage + '.elements.' + elementsObjAttrPageInner);


    let _temp_list_of_all_child_questions_from_current_parent_questions = [];
    for (let _item of _.keys(_list_of_all_child_questions_from_current_parent_questions)) {
      let _child_question = _.cloneDeep(_list_of_all_child_questions_from_current_parent_questions[_item]);
      if (_child_question) {
        let _current_super_question_data = _child_question.super_question_data;
        let _add_logic_condition = _child_question.super_question_data.add_logic_condition;
        let _add_logic_trigger_action = _child_question.super_question_data.add_logic_trigger_action;
        let _add_logic_value = _child_question.super_question_data.add_logic_value;
        let _key2update = "";
        let _all_options_from_super_question = [];
        if (_current_super_question_data_from_parent) {
          switch (_current_super_question_data_from_parent?.fieldType) {
            case EnumTemplatesFieldType.checkbox:

              _all_options_from_super_question = _current_super_question_data_from_parent?.options;

              for (let item in _all_options_from_super_question) {
                if (_.isArray(_current_super_question_data?.add_logic_value)) {
                  if (_current_super_question_data?.add_logic_value.includes(_all_options_from_super_question[item]?.value)) {
                    _key2update = _key2update + " " + (_all_options_from_super_question[item]?.label || _all_options_from_super_question[item]?.title);
                  }
                } else {
                  if (_all_options_from_super_question[item]?.value == _current_super_question_data?.add_logic_value) {
                    _key2update = _key2update + " " + (_all_options_from_super_question[item]?.label || _all_options_from_super_question[item]?.title);
                  } else {
                    // debugger;
                  }
                }
              }
              break;
            case EnumTemplatesFieldType.radio:
              _all_options_from_super_question = _current_super_question_data_from_parent?.options;

              for (let item in _all_options_from_super_question) {
                if (_.isArray(_current_super_question_data?.add_logic_value)) {
                  if (_current_super_question_data?.add_logic_value.includes(_all_options_from_super_question[item]?.value)) {
                    _key2update = _key2update + " " + (_all_options_from_super_question[item]?.label || _all_options_from_super_question[item]?.title);
                  }
                } else {
                  if (_all_options_from_super_question[item]?.value == _current_super_question_data?.add_logic_value) {
                    _key2update = _key2update + " " + (_all_options_from_super_question[item]?.label || _all_options_from_super_question[item]?.title);
                  } else {
                    // debugger;
                  }
                }
              }
              break;
            default:
              if (_.isArray(_add_logic_value)) {
                //_key2update = (_add_logic_condition || "") + "_" + (_add_logic_trigger_action || "") + "_" + (_add_logic_value.join("_")||"");
                _key2update = (_add_logic_condition || "") + "_" + (_add_logic_value.join("_") || "");
              } else {
                //_key2update = (_add_logic_condition || "") + "_" + (_add_logic_trigger_action || "") + "_" + (_add_logic_value||"");
                _key2update = (_add_logic_condition || "") + "_" + (_add_logic_value || "");
              }
              break;
          }
        }
        if (!_key2update) {
          debugger;
        }
        _key2update = _key2update.replaceAll('_', ' ');
        let _existing_logic = _.filter(_temp_list_of_all_child_questions_from_current_parent_questions, function (e) {
          //////below code is used to make different groups of same logic but different field type
          // if (e._add_logic_condition === _add_logic_condition && e._add_logic_trigger_action === _add_logic_trigger_action && ((_.isArray(_add_logic_value) && _.intersection(e._add_logic_value, _add_logic_value).length > 0) || (!_.isArray(_add_logic_value) && _add_logic_value === e._add_logic_value))) {
          //   return true;
          // }
          //////below code is used to make same group of same logic of all field type
          if (e._add_logic_condition === _add_logic_condition && ((_.isArray(_add_logic_value) && _.intersection(e._add_logic_value, _add_logic_value).length > 0) || (!_.isArray(_add_logic_value) && _add_logic_value === e._add_logic_value))) {
            return true;
          }
          return false;
        });
        if (_existing_logic.length <= 0) {
          _temp_list_of_all_child_questions_from_current_parent_questions.push(_.cloneDeep({ _add_logic_condition: _add_logic_condition, _add_logic_trigger_action: _add_logic_trigger_action, _add_logic_value: _add_logic_value, display_title_for_tab: _key2update }));
        }
      }
    }
    return _.cloneDeep(_temp_list_of_all_child_questions_from_current_parent_questions || []);
  };
  const TemplatesQuestionSchemaHtml = (props) => {
    let currentPageIndex = props.indexOuter;
    let elementsObjAttrPageInner = props.elementsObjAttrPageInner;
    let indexInner = props.indexInner;
    ///onhover////class removed 
    return (
      <div className={`pt-3 pb-2 px-2 mb-3  ${_.keys(_.get(existingData, 'template_schema.elements.' + props.elementsObjAttrPage + '.elements.' + elementsObjAttrPageInner + '.super_question_data') || []).length === 0 ? 'bg-xlight' : 'ms-5 bg-light'}`} key={elementsObjAttrPageInner + '_' + indexInner}>
        <div className="row">
          <div className="col-lg-8 col-8 ">

            <div className="">
              {/* <label className="form-label fw-bold">{_.get(existingData, 'template_schema.elements.' + elementsObjAttrPage + '.elements.'+elementsObjAttrPageInner+'.label')} </label> */}
              <input onChange={handleInputChange} value={_.get(existingData, 'template_schema.elements.' + props.elementsObjAttrPage + '.elements.' + elementsObjAttrPageInner + '.label')} name={'template_schema.elements.' + props.elementsObjAttrPage + '.elements.' + elementsObjAttrPageInner + '.label'} type="text" className="form-control form-control-sm mb-1" required />
            </div>
          </div>

          {_.keys(_.get(existingData, 'template_schema.elements.' + props.elementsObjAttrPage + '.elements.' + elementsObjAttrPageInner + '.super_question_data') || []).length === 0 && <div className="col-lg-4 col-4">
            <select className="form-select form-select-sm" value={_.get(existingData, 'template_schema.elements.' + props.elementsObjAttrPage + '.elements.' + elementsObjAttrPageInner + '.fieldType') || ''} onChange={(event) => { _updateQuestion_of_Page_template_schema('template_schema.elements.' + props.elementsObjAttrPage + '.elements.' + elementsObjAttrPageInner + '', event.target.value) }} name={'template_schema.elements.' + props.elementsObjAttrPage + '.elements.' + elementsObjAttrPageInner + '.fieldType'}>

              {Object.keys(EnumTemplatesFieldTypeWithLabel || []).map(key => (
                <option key={key} value={key}>
                  {EnumTemplatesFieldTypeWithLabel[key].label}
                </option>
              ))}

            </select>
          </div>}
          {_.keys(_.get(existingData, 'template_schema.elements.' + props.elementsObjAttrPage + '.elements.' + elementsObjAttrPageInner + '.super_question_data') || []).length > 0 && <div className="col-lg-4 col-4">
            <span style={{ fontSize: 10 }}>
              Trigger when:
              <span>
                &nbsp;{_.get(existingData, 'template_schema.elements.' + props.elementsObjAttrPage + '.elements.' + _.get(existingData, 'template_schema.elements.' + props.elementsObjAttrPage + '.elements.' + elementsObjAttrPageInner + '.super_question_data.name_of_super_question') + '.label')}
              </span>
              <span>
                &nbsp;{(_.get(existingData, 'template_schema.elements.' + props.elementsObjAttrPage + '.elements.' + elementsObjAttrPageInner + '.super_question_data.add_logic_condition') || '').replaceAll('_', ' ')}
              </span>
              {!_.get(existingData, 'template_schema.elements.' + props.elementsObjAttrPage + '.elements.' + elementsObjAttrPageInner + '.super_question_data.add_logic_value') && <>
                <span>&nbsp;Empty</span>
              </>}
              {_.get(existingData, 'template_schema.elements.' + props.elementsObjAttrPage + '.elements.' + elementsObjAttrPageInner + '.super_question_data.add_logic_value') && <>
                {[EnumTemplatesFieldType.radio, EnumTemplatesFieldType.checkbox].indexOf(_return_action_trigger_super_question_data_from_parent_for_current_child('template_schema.elements.' + props.elementsObjAttrPage + '.elements', elementsObjAttrPageInner)) > -1 && <>
                  <span>
                    &nbsp;{_return_action_trigger_selected_option_lable_for_current_child('template_schema.elements.' + props.elementsObjAttrPage + '.elements', elementsObjAttrPageInner)}
                  </span>
                </>}
                {[EnumTemplatesFieldType.radio, EnumTemplatesFieldType.checkbox].indexOf(_return_action_trigger_super_question_data_from_parent_for_current_child('template_schema.elements.' + props.elementsObjAttrPage + '.elements', elementsObjAttrPageInner)) === -1 && <>
                  <span>
                    &nbsp;{_return_action_trigger_selected_option_lable_for_current_child('template_schema.elements.' + props.elementsObjAttrPage + '.elements', elementsObjAttrPageInner)}
                  </span>
                </>}
              </>}
            </span>
          </div>}
        </div>
        <div className="row expanddiv">

          <div className="col-lg-8 col-6">

            {(_.keys(_.get(existingData, 'template_schema.elements.' + props.elementsObjAttrPage + '.elements.' + elementsObjAttrPageInner + '.super_question_data') || []).length === 0 && EnumTemplatesFieldTypeWithLabel[_.get(existingData, 'template_schema.elements.' + props.elementsObjAttrPage + '.elements.' + elementsObjAttrPageInner + '.fieldType')]?.do_not_show_required !== true && _.keys(_.get(existingData, 'template_schema.elements.' + props.elementsObjAttrPage + '.elements.' + elementsObjAttrPageInner + '.showWhen') || []).length === 0 && _.get(existingData, 'template_schema.elements.' + props.elementsObjAttrPage + '.elements.' + elementsObjAttrPageInner + '.attributes.do_not_show_required') !== true) && <span>
              <input type="checkbox" className="form-check-input" id={'chk_validation_' + props.elementsObjAttrPage + '_' + elementsObjAttrPageInner + ''} checked={_returnT_for_question_is_set_validation_required('template_schema.elements.' + props.elementsObjAttrPage + '.elements.' + elementsObjAttrPageInner + '.validation')} onChange={(evt) => { _update_value_for_question_is_set_validation_required('template_schema.elements.' + props.elementsObjAttrPage + '.elements.' + elementsObjAttrPageInner + '.validation', evt.target.checked) }} />
              <small className="form-check-label text-black-50 ms-2" htmlFor={'chk_validation_' + props.elementsObjAttrPage + '_' + elementsObjAttrPageInner + ''}>Required</small>
            </span>}

            {(EnumTemplatesFieldTypeWithLabel[_.get(existingData, 'template_schema.elements.' + props.elementsObjAttrPage + '.elements.' + elementsObjAttrPageInner + '.fieldType')]?.add_logic && (_.get(existingData, 'template_schema.elements.' + props.elementsObjAttrPage + '.elements.' + elementsObjAttrPageInner + '.super_question_data.current_depth_level_of_child_question') || 0) < 1) &&
              <a className="small px-3" onClick={() => { onClick4AddLogic('template_schema__elements__' + props.elementsObjAttrPage + '__elements__' + elementsObjAttrPageInner) }}>
                <i className="fa fa-code-branch"></i> Add Logic
              </a>
            }

            {_.keys(_.get(existingData, 'template_schema.elements.' + props.elementsObjAttrPage + '.elements.' + elementsObjAttrPageInner + '.super_question_data') || []).length === 0 && <a className="small mx-2" onClick={() => { addQuestion_for_Page_template_schema_after_supplied_question('template_schema.elements.' + props.elementsObjAttrPage + '.elements', elementsObjAttrPageInner, '') }}>
              <i className="fa fa-plus"></i> Add Question
            </a>}
            {_.get(existingData, 'template_schema.elements.' + props.elementsObjAttrPage + '.elements.' + elementsObjAttrPageInner + '.attributes.do_not_show_delete_btn') !== true && <a name="btn_remove_question_from_page" className="small ml-3 ms-2 text-danger" onClick={() => { removeQuestion_from_Page_template_schema('template_schema.elements.' + props.elementsObjAttrPage + '.elements', elementsObjAttrPageInner) }}>
              <i className="fa fa-trash"></i>
            </a>}

            {_do_not_show_percentage_for_question !== true && <input type="checkbox" className="form-check-input ml-3 ms-2" id={'chk_points_' + props.elementsObjAttrPage + '_' + elementsObjAttrPageInner + ''} checked={_returnT_for_question_points_enabled('template_schema.elements.' + props.elementsObjAttrPage + '.elements.' + elementsObjAttrPageInner + '.points')} onChange={(evt) => { _update_value_for_question_points_enabled('template_schema.elements.' + props.elementsObjAttrPage + '.elements.' + elementsObjAttrPageInner + '.points', evt.target.checked) }} />}
            {/* <small className="form-check-label text-black-50 ms-2" htmlFor={'chk_points_' + props.elementsObjAttrPage + '_' + elementsObjAttrPageInner + ''}>Points enabled</small> */}
            {_returnT_for_question_points_enabled('template_schema.elements.' + props.elementsObjAttrPage + '.elements.' + elementsObjAttrPageInner + '.points') && <label className="bg-success m-1 rounded small">{_return_single_point_value_out_of_100_for_question_points_enabled('template_schema.elements.' + props.elementsObjAttrPage + '.elements.' + elementsObjAttrPageInner + '.points')}%</label>}
          </div>

          {(() => {
            if (_.keys(_.get(existingData, 'template_schema.elements.' + props.elementsObjAttrPage + '.elements.' + elementsObjAttrPageInner + '.super_question_data') || []).length === 0) {
              switch (_.get(existingData, 'template_schema.elements.' + props.elementsObjAttrPage + '.elements.' + elementsObjAttrPageInner + '.fieldType')) {
                case EnumTemplatesFieldType.switch:
                  break;
                case EnumTemplatesFieldType.radio:
                case EnumTemplatesFieldType.checkbox:
                  return (
                    <div className="col-lg-12 col-12">
                      <div className="row">

                        {Object.values(_.get(existingData, 'template_schema.elements.' + props.elementsObjAttrPage + '.elements.' + elementsObjAttrPageInner + '.options') || []).map((elementsObjAttrPageInnerOptions, indexInnerOptions) => (
                          <div className="col-lg-2 col-2">
                            <div className="row">
                              <div className="col-lg-12 col-1 mt-2">
                                {/* <label className="form-label fw-light">Label</label> */}


                                {_.get(existingData, 'template_schema.elements.' + props.elementsObjAttrPage + '.elements.' + elementsObjAttrPageInner + '.fieldType') === EnumTemplatesFieldType.radio &&
                                  <>
                                    <input onChange={handleInputChange} value={_.get(existingData, 'template_schema.elements.' + props.elementsObjAttrPage + '.elements.' + elementsObjAttrPageInner + '.options.' + indexInnerOptions + '.title')} name={'template_schema.elements.' + props.elementsObjAttrPage + '.elements.' + elementsObjAttrPageInner + '.options.' + indexInnerOptions + '.title'} type="text" className="form-control form-control-sm border-green mb-1" required />

                                    <a className="btn btn-link btn-sm" onClick={() => setResetColorPalletVisibleName('template_schema.elements.' + props.elementsObjAttrPage + '.elements.' + elementsObjAttrPageInner + '.options.' + indexInnerOptions + '.title')}>
                                      <i className="fa fa-palette" style={{ color: _.get(existingData, 'template_schema.elements.' + props.elementsObjAttrPage + '.elements.' + elementsObjAttrPageInner + '.options.' + indexInnerOptions + '.colorCode.hex') }}></i> Color
                                    </a>
                                    {colorPalletVisibleName === 'template_schema.elements.' + props.elementsObjAttrPage + '.elements.' + elementsObjAttrPageInner + '.options.' + indexInnerOptions + '.title' && <ColorPicker height="50" hideAlpha="true" hideInput={["rgb", "hsv"]} color={_.get(existingData, 'template_schema.elements.' + props.elementsObjAttrPage + '.elements.' + elementsObjAttrPageInner + '.options.' + indexInnerOptions + '.colorCode') || color} onChange={(e) => { setColorForOptionsOnChange(e, 'template_schema.elements.' + props.elementsObjAttrPage + '.elements.' + elementsObjAttrPageInner + '.options.' + indexInnerOptions + '.colorCode') }} />}
                                  </>
                                }
                                {_.get(existingData, 'template_schema.elements.' + props.elementsObjAttrPage + '.elements.' + elementsObjAttrPageInner + '.fieldType') === EnumTemplatesFieldType.checkbox &&
                                  <>
                                    <input onChange={handleInputChange} value={_.get(existingData, 'template_schema.elements.' + props.elementsObjAttrPage + '.elements.' + elementsObjAttrPageInner + '.options.' + indexInnerOptions + '.label')} name={'template_schema.elements.' + props.elementsObjAttrPage + '.elements.' + elementsObjAttrPageInner + '.options.' + indexInnerOptions + '.label'} type="text" className="form-control form-control-sm" required />
                                    <a className="btn btn-link btn-sm" onClick={() => setResetColorPalletVisibleName('template_schema.elements.' + props.elementsObjAttrPage + '.elements.' + elementsObjAttrPageInner + '.options.' + indexInnerOptions + '.title')}>
                                      <i className="fa fa-palette" style={{ color: _.get(existingData, 'template_schema.elements.' + props.elementsObjAttrPage + '.elements.' + elementsObjAttrPageInner + '.options.' + indexInnerOptions + '.colorCode.hex') }}></i> Color
                                    </a>
                                    {colorPalletVisibleName === 'template_schema.elements.' + props.elementsObjAttrPage + '.elements.' + elementsObjAttrPageInner + '.options.' + indexInnerOptions + '.title' && <ColorPicker height="50" hideAlpha="true" hideInput={["rgb", "hsv"]} color={_.get(existingData, 'template_schema.elements.' + props.elementsObjAttrPage + '.elements.' + elementsObjAttrPageInner + '.options.' + indexInnerOptions + '.colorCode') || color} onChange={(e) => { setColorForOptionsOnChange(e, 'template_schema.elements.' + props.elementsObjAttrPage + '.elements.' + elementsObjAttrPageInner + '.options.' + indexInnerOptions + '.colorCode') }} />}
                                  </>
                                }



                              </div>
                              {/* <div className="col-lg-6 col-6">
                              <label className="form-label fw-light">Value</label>
                              <input onChange={handleInputChange} value={_.get(existingData, 'template_schema.elements.' + props.elementsObjAttrPage + '.elements.' + elementsObjAttrPageInner + '.options.' + indexInnerOptions + '.value')} name={'template_schema.elements.' + props.elementsObjAttrPage + '.elements.' + elementsObjAttrPageInner + '.options.' + indexInnerOptions + '.value'} type="text" className="form-control border-green rounded-0 mb-3" required />
                            </div> */}
                            </div>
                          </div>
                        ))}
                        <div className="col-lg-2 col-4 mt-1">
                          <a className="btn btn-default" onClick={() => addOptions_to_question_in_page_template_schema('template_schema.elements.' + props.elementsObjAttrPage + '.elements.' + elementsObjAttrPageInner + '.options', _.get(existingData, 'template_schema.elements.' + props.elementsObjAttrPage + '.elements.' + elementsObjAttrPageInner + '.fieldType'))}>
                            <i className="fa fa-plus-circle "></i>
                          </a>
                        </div>
                      </div>

                    </div>
                  );

                  break;
                case EnumTemplatesFieldType.date:

                  break;
                case EnumTemplatesFieldType.number:

                  break;
                case EnumTemplatesFieldType.range:
                  return (
                    <div className="col-lg-4 col-12">
                      <label className="small fw-light me-1">Min</label>
                      <input onChange={handleInputChange} value={_.get(existingData, 'template_schema.elements.' + props.elementsObjAttrPage + '.elements.' + elementsObjAttrPageInner + '.attributes.min')} name={'template_schema.elements.' + props.elementsObjAttrPage + '.elements.' + elementsObjAttrPageInner + '.attributes.min'} type="number" className="border-0 p-0 small text-sm-center" max={_.toNumber(_.get(existingData, 'template_schema.elements.' + props.elementsObjAttrPage + '.elements.' + elementsObjAttrPageInner + '.attributes.max')) - 1} required style={{ width: 35 }} />

                      <label className="small fw-light mx-1">Max</label>
                      <input onChange={handleInputChange} value={_.get(existingData, 'template_schema.elements.' + props.elementsObjAttrPage + '.elements.' + elementsObjAttrPageInner + '.attributes.max')} name={'template_schema.elements.' + props.elementsObjAttrPage + '.elements.' + elementsObjAttrPageInner + '.attributes.max'} type="number" className="border-0 p-0 small text-sm-center" min={_.toNumber(_.get(existingData, 'template_schema.elements.' + props.elementsObjAttrPage + '.elements.' + elementsObjAttrPageInner + '.attributes.min')) + 1} required style={{ width: 35 }} />

                      <label className="small fw-light mx-1">Increment by</label>
                      <input onChange={handleInputChange} value={_.get(existingData, 'template_schema.elements.' + props.elementsObjAttrPage + '.elements.' + elementsObjAttrPageInner + '.attributes.increment')} name={'template_schema.elements.' + props.elementsObjAttrPage + '.elements.' + elementsObjAttrPageInner + '.attributes.increment'} type="number" className="border-0 p-0 small text-sm-center" min={_.toNumber(_.get(existingData, 'template_schema.elements.' + props.elementsObjAttrPage + '.elements.' + elementsObjAttrPageInner + '.attributes.min')) + 1} max={_.toNumber(_.get(existingData, 'template_schema.elements.' + props.elementsObjAttrPage + '.elements.' + elementsObjAttrPageInner + '.attributes.max')) - 1} required style={{ width: 35 }} />

                    </div>
                  );
                  break;
                case EnumTemplatesFieldType.textarea:
                case EnumTemplatesFieldType.text:
                  return (
                    <div className="col-lg-4 col-4">
                      {/* <label className="form-label fw-light">Format</label> */}
                      <select className="border-0 small text-black-50" value={_.get(existingData, 'template_schema.elements.' + props.elementsObjAttrPage + '.elements.' + elementsObjAttrPageInner + '.fieldType') || ''} onChange={handleInputChange} name={'template_schema.elements.' + props.elementsObjAttrPage + '.elements.' + elementsObjAttrPageInner + '.fieldType'}>
                        <option value="text">Short Answer</option>
                        <option value="textarea">Paragraph</option>
                      </select>
                    </div>
                  );

                  break;
              }
            } else {
              //handle trigger options else case
              switch (_.get(existingData, 'template_schema.elements.' + props.elementsObjAttrPage + '.elements.' + elementsObjAttrPageInner + '.fieldType')) {
                case EnumTemplatesFieldType.switch:
                  break;
                case EnumTemplatesFieldType.radio:
                case EnumTemplatesFieldType.checkbox:
                  return (
                    <div className="col-lg-12 col-12">
                      <div className="row">

                        {Object.values(_.get(existingData, 'template_schema.elements.' + props.elementsObjAttrPage + '.elements.' + elementsObjAttrPageInner + '.options') || []).map((elementsObjAttrPageInnerOptions, indexInnerOptions) => (
                          <div className="col-lg-2 col-2 mt-1">

                            {_.get(existingData, 'template_schema.elements.' + props.elementsObjAttrPage + '.elements.' + elementsObjAttrPageInner + '.fieldType') === EnumTemplatesFieldType.radio &&
                              <>
                                <input onChange={handleInputChange} value={_.get(existingData, 'template_schema.elements.' + props.elementsObjAttrPage + '.elements.' + elementsObjAttrPageInner + '.options.' + indexInnerOptions + '.title')} name={'template_schema.elements.' + props.elementsObjAttrPage + '.elements.' + elementsObjAttrPageInner + '.options.' + indexInnerOptions + '.title'} type="text" className="form-control form-control-sm" required />

                                <a className="btn btn-link btn-sm" onClick={() => setResetColorPalletVisibleName('template_schema.elements.' + props.elementsObjAttrPage + '.elements.' + elementsObjAttrPageInner + '.options.' + indexInnerOptions + '.title')}>
                                  <i className="fa fa-palette" style={{ color: _.get(existingData, 'template_schema.elements.' + props.elementsObjAttrPage + '.elements.' + elementsObjAttrPageInner + '.options.' + indexInnerOptions + '.colorCode.hex') }}></i> Color
                                </a>
                                {colorPalletVisibleName === 'template_schema.elements.' + props.elementsObjAttrPage + '.elements.' + elementsObjAttrPageInner + '.options.' + indexInnerOptions + '.title' && <ColorPicker height="50" hideAlpha="true" hideInput={["rgb", "hsv"]} color={_.get(existingData, 'template_schema.elements.' + props.elementsObjAttrPage + '.elements.' + elementsObjAttrPageInner + '.options.' + indexInnerOptions + '.colorCode') || color} onChange={(e) => { setColorForOptionsOnChange(e, 'template_schema.elements.' + props.elementsObjAttrPage + '.elements.' + elementsObjAttrPageInner + '.options.' + indexInnerOptions + '.colorCode') }} />}
                              </>
                            }
                            {_.get(existingData, 'template_schema.elements.' + props.elementsObjAttrPage + '.elements.' + elementsObjAttrPageInner + '.fieldType') === EnumTemplatesFieldType.checkbox &&
                              <>
                                <input onChange={handleInputChange} value={_.get(existingData, 'template_schema.elements.' + props.elementsObjAttrPage + '.elements.' + elementsObjAttrPageInner + '.options.' + indexInnerOptions + '.label')} name={'template_schema.elements.' + props.elementsObjAttrPage + '.elements.' + elementsObjAttrPageInner + '.options.' + indexInnerOptions + '.label'} type="text" className="form-control form-control-sm" required />
                                <a className="btn btn-link btn-sm" onClick={() => setResetColorPalletVisibleName('template_schema.elements.' + props.elementsObjAttrPage + '.elements.' + elementsObjAttrPageInner + '.options.' + indexInnerOptions + '.title')}>
                                  <i className="fa fa-palette" style={{ color: _.get(existingData, 'template_schema.elements.' + props.elementsObjAttrPage + '.elements.' + elementsObjAttrPageInner + '.options.' + indexInnerOptions + '.colorCode.hex') }}></i> Color
                                </a>
                                {colorPalletVisibleName === 'template_schema.elements.' + props.elementsObjAttrPage + '.elements.' + elementsObjAttrPageInner + '.options.' + indexInnerOptions + '.title' && <ColorPicker height="50" hideAlpha="true" hideInput={["rgb", "hsv"]} color={_.get(existingData, 'template_schema.elements.' + props.elementsObjAttrPage + '.elements.' + elementsObjAttrPageInner + '.options.' + indexInnerOptions + '.colorCode') || color} onChange={(e) => { setColorForOptionsOnChange(e, 'template_schema.elements.' + props.elementsObjAttrPage + '.elements.' + elementsObjAttrPageInner + '.options.' + indexInnerOptions + '.colorCode') }} />}
                              </>
                            }

                            {/* <div className="col-lg-6 col-6">
<label className="form-label fw-light">Value</label>
<input onChange={handleInputChange} value={_.get(existingData, 'template_schema.elements.' + props.elementsObjAttrPage + '.elements.' + elementsObjAttrPageInner + '.options.' + indexInnerOptions + '.value')} name={'template_schema.elements.' + props.elementsObjAttrPage + '.elements.' + elementsObjAttrPageInner + '.options.' + indexInnerOptions + '.value'} type="text" className="form-control border-green rounded-0 mb-3" required />
</div> */}

                          </div>
                        ))}
                        <div className="col-lg-2 col-4 mt-1">
                          <small className="ms-2 fw-light" onClick={() => addOptions_to_question_in_page_template_schema('template_schema.elements.' + props.elementsObjAttrPage + '.elements.' + elementsObjAttrPageInner + '.options', _.get(existingData, 'template_schema.elements.' + props.elementsObjAttrPage + '.elements.' + elementsObjAttrPageInner + '.fieldType'))}>
                            <i className="fa fa-plus-circle "></i> Add Response
                          </small>
                        </div>
                      </div>

                    </div>
                  );

                  break;
                case EnumTemplatesFieldType.date:

                  break;
                case EnumTemplatesFieldType.number:

                  break;
                case EnumTemplatesFieldType.range:
                  return (
                    <div className="col-lg-4 col-12">
                      <label className="small fw-light me-1">Min</label>
                      <input onChange={handleInputChange} value={_.get(existingData, 'template_schema.elements.' + props.elementsObjAttrPage + '.elements.' + elementsObjAttrPageInner + '.attributes.min')} name={'template_schema.elements.' + props.elementsObjAttrPage + '.elements.' + elementsObjAttrPageInner + '.attributes.min'} type="number" className="border-0 p-0 small text-sm-center" max={_.toNumber(_.get(existingData, 'template_schema.elements.' + props.elementsObjAttrPage + '.elements.' + elementsObjAttrPageInner + '.attributes.max')) - 1} required style={{ width: 35 }} />

                      <label className="small fw-light mx-1">Max</label>
                      <input onChange={handleInputChange} value={_.get(existingData, 'template_schema.elements.' + props.elementsObjAttrPage + '.elements.' + elementsObjAttrPageInner + '.attributes.max')} name={'template_schema.elements.' + props.elementsObjAttrPage + '.elements.' + elementsObjAttrPageInner + '.attributes.max'} type="number" className="border-0 p-0 small text-sm-center" min={_.toNumber(_.get(existingData, 'template_schema.elements.' + props.elementsObjAttrPage + '.elements.' + elementsObjAttrPageInner + '.attributes.min')) + 1} required style={{ width: 35 }} />

                      <label className="small fw-light mx-1">Increment by</label>
                      <input onChange={handleInputChange} value={_.get(existingData, 'template_schema.elements.' + props.elementsObjAttrPage + '.elements.' + elementsObjAttrPageInner + '.attributes.increment')} name={'template_schema.elements.' + props.elementsObjAttrPage + '.elements.' + elementsObjAttrPageInner + '.attributes.increment'} type="number" className="border-0 p-0 small text-sm-center" min={_.toNumber(_.get(existingData, 'template_schema.elements.' + props.elementsObjAttrPage + '.elements.' + elementsObjAttrPageInner + '.attributes.min')) + 1} max={_.toNumber(_.get(existingData, 'template_schema.elements.' + props.elementsObjAttrPage + '.elements.' + elementsObjAttrPageInner + '.attributes.max')) - 1} required style={{ width: 35 }} />

                    </div>
                  );
                  break;
                case EnumTemplatesFieldType.textarea:
                case EnumTemplatesFieldType.text:


                  break;
              }

            }
          })()}

          {(() => {

            const _current_fieldType = _.get(existingData, 'template_schema.elements.' + props.elementsObjAttrPage + '.elements.' + elementsObjAttrPageInner + '.fieldType');
            const _current_field_options = _.get(existingData, 'template_schema.elements.' + props.elementsObjAttrPage + '.elements.' + elementsObjAttrPageInner + '.options');
            let _current_add_logic_condition_enum = EnumTemplatesFieldTypeWithLabel[_current_fieldType].add_logic_condition || [];
            const _current_add_logic_key = 'template_schema__elements__' + props.elementsObjAttrPage + '__elements__' + elementsObjAttrPageInner;


            const _all_child_questions_if_present_for_current = _.values(_return_all_child_questions_of_current_page_question(props.currentPageIndex, props.indexInner, props.elementsObjAttrPage, props.elementsObjAttrPageInner));

            if (_all_child_questions_if_present_for_current.length > 0) {
              let _current_questions_super_question_data = _.cloneDeep(_all_child_questions_if_present_for_current[0]?.super_question_data);
              if (_current_questions_super_question_data?.add_logic_condition) {
                //_current_add_logic_condition_enum = _current_questions_super_question_data.add_logic_condition;
                //_current_add_logic_key=
                //_current_add_logic_condition_enum=
                //_current_fieldType=
                //_current_field_options
                //_.get(addLogic4Control, _current_add_logic_key + '.add_logic_condition')
                if (!_.get(addLogic4Control, _current_add_logic_key)) {
                  _.set(addLogic4Control, _current_add_logic_key, _current_questions_super_question_data);
                }
              }
            } else {

              if (_current_add_logic_condition_enum.length > 0 && !_.get(addLogic4Control, _current_add_logic_key + '.add_logic_condition')) {
                _.set(addLogic4Control, _current_add_logic_key + '.add_logic_condition', _current_add_logic_condition_enum[0]);
              }
              if (!_.get(addLogic4Control, _current_add_logic_key + '.add_logic_trigger_action')) {
                _.set(addLogic4Control, _current_add_logic_key + '.add_logic_trigger_action', EnumTemplatesFieldType.text);
              }
            }
            if (addLogic4Control[_current_add_logic_key]?.isVisible === true) {
              switch (_current_fieldType) {
                case EnumTemplatesFieldType.switch:
                case EnumTemplatesFieldType.radio:
                case EnumTemplatesFieldType.checkbox:
                case EnumTemplatesFieldType.number:
                case EnumTemplatesFieldType.range:
                case EnumTemplatesFieldType.textarea:
                case EnumTemplatesFieldType.text:
                  return (
                    <>
                      <div className="col-lg-12 col-12">
                        <div className=" ml-3 ms-2">
                          <label className="form-label fw-light">If answer </label>

                          <TemplatesAddLogicKeySelect current_add_logic_key={_current_add_logic_key} current_add_logic_condition_enum={_current_add_logic_condition_enum} />


                          <TemplatesAddLogicConditionValue current_add_logic_key={_current_add_logic_key} current_fieldType={_current_fieldType} current_field_options={_current_field_options} current_add_logic_conditionValue={_.get(addLogic4Control, _current_add_logic_key + '.add_logic_condition')} />


                          <label className="form-label fw-light"> then </label>
                          <select className="border-0 small text-black-50" value={_.get(addLogic4Control, _current_add_logic_key + '.add_logic_trigger_action')} onChange={(event) => { onClick4AddLogicUpdateValues(_current_add_logic_key, 'add_logic_trigger_action', event.target.value) }} name={_current_add_logic_key + '.add_logic_trigger_action'}>
                            <option value={EnumTemplatesFieldType.text}>Ask Question</option>
                            <option value={EnumTemplatesFieldType['file-uploader']}>Require Evidence</option>

                            <option value={EnumTemplatesFieldType.switch}>{EnumTemplatesFieldTypeWithLabel.switch.label}</option>
                            <option value={EnumTemplatesFieldType.radio}>{EnumTemplatesFieldTypeWithLabel.radio.label}</option>
                            <option value={EnumTemplatesFieldType.checkbox}>{EnumTemplatesFieldTypeWithLabel.checkbox.label}</option>
                            <option value={EnumTemplatesFieldType.date}>{EnumTemplatesFieldTypeWithLabel.date.label}</option>
                            <option value={EnumTemplatesFieldType.number}>{EnumTemplatesFieldTypeWithLabel.number.label}</option>
                            <option value={EnumTemplatesFieldType.range}>{EnumTemplatesFieldTypeWithLabel.range.label}</option>
                            <option value={EnumTemplatesFieldType['inner-text']}>{EnumTemplatesFieldTypeWithLabel['inner-text'].label}</option>

                          </select>

                          {/* <OverlayTrigger trigger="click" placement="right" overlay={popoverTemplatesAddLogicActionValue({ current_add_logic_key: _current_add_logic_key })}>
                          <Button>Trigger</Button>
                        </OverlayTrigger> */}
                          <a className="btn btn-link btn-sm" onClick={() => { addQuestion_for_Page_template_schema_as_child_for_supplied_parent('template_schema.elements.' + props.elementsObjAttrPage + '.elements', elementsObjAttrPageInner, _current_add_logic_key, _.get(addLogic4Control, _current_add_logic_key + '.add_logic_trigger_action')); }}>
                            <i className="fa fa-plus-circle "></i>
                          </a>

                        </div>
                      </div>
                      <div className="col-lg-12 col-12">
                        <Tabs
                          defaultActiveKey="0"
                          id="uncontrolled-tab-example"
                          className="mb-3"
                        >
                          {_return_grouping_data_for__all_child_questions_of_current_page_question(props.currentPageIndex, props.indexInner, props.elementsObjAttrPage, props.elementsObjAttrPageInner).map((logic_obj, logicIndex) => {

                            return (
                              <Tab eventKey={logicIndex} title={logic_obj.display_title_for_tab}>
                                <div className="col-lg-12 col-12">
                                  {Object.keys(_return_all_child_questions_of_current_page_question(props.currentPageIndex, props.indexInner, props.elementsObjAttrPage, props.elementsObjAttrPageInner, logic_obj._add_logic_condition, logic_obj._add_logic_trigger_action, logic_obj._add_logic_value) || []).length > 0 && <div className="mb-3">
                                    {Object.keys(_return_all_child_questions_of_current_page_question(props.currentPageIndex, props.indexInner, props.elementsObjAttrPage, props.elementsObjAttrPageInner, logic_obj._add_logic_condition, logic_obj._add_logic_trigger_action, logic_obj._add_logic_value) || []).map((elementsObjAttrPageInner__2, indexInner__2) => {

                                      return (
                                        TemplatesQuestionSchemaHtml({ elementsObjAttrPageInner: elementsObjAttrPageInner__2, indexInner: indexInner__2, currentPageIndex: props.currentPageIndex, PageHeaderLable: props.PageHeaderLable, PageHeaderEdit: props.PageHeaderEdit, elementsObjAttrPage: props.elementsObjAttrPage })
                                      );

                                    })}
                                  </div>}
                                </div>
                              </Tab>
                            );

                          })}



                          {/* <Tab eventKey="profile" title="Profile">
                            Tab content for Profile
                          </Tab>
                          <Tab eventKey="contact" title="Contact" disabled>
                            Tab content for Contact
                          </Tab> */}
                        </Tabs>
                      </div>

                    </>
                  );

                  break;
              }
            }
          })()}

        </div>

      </div>
    );
  }
  const TemplatesPageSchemaHtml = (props) => {
    return (
      <div className="card mb-4">
        <div className="card-body">

          {props.PageHeaderLable === true && <h5 className="fw-light mb-4"><i className="fa fa-angle-right" /> &nbsp;
            {_.get(existingData, 'template_schema.elements.' + props.elementsObjAttrPage + '.title')} </h5>}
          {props.PageHeaderEdit === true && <input onChange={handleInputChange} value={_.get(existingData, 'template_schema.elements.' + props.elementsObjAttrPage + '.title')} name={'template_schema.elements.' + props.elementsObjAttrPage + '.title'} type="text" className="form-control form-control-sm mb-1" required />}

          <div className="row">
            <div className="col-lg-12 col-12">
              {Object.keys(_.get(existingData, 'template_schema.elements.' + props.elementsObjAttrPage + '.elements') || []).length > 0 && <div className="mb-3">
                {Object.keys(_.get(existingData, 'template_schema.elements.' + props.elementsObjAttrPage + '.elements') || []).map((elementsObjAttrPageInner, indexInner) => {
                  if (_.keys(_.get(existingData, 'template_schema.elements.' + props.elementsObjAttrPage + '.elements.' + elementsObjAttrPageInner + '.super_question_data') || []).length === 0) {
                    return (
                      TemplatesQuestionSchemaHtml({ elementsObjAttrPageInner: elementsObjAttrPageInner, indexInner: indexInner, currentPageIndex: props.currentPageIndex, PageHeaderLable: props.PageHeaderLable, PageHeaderEdit: props.PageHeaderEdit, elementsObjAttrPage: props.elementsObjAttrPage })
                    );
                  }
                })}
              </div>}
              <div className="">
                <a className="btn btn-link btn-sm" onClick={() => addQuestion_for_Page_template_schema('template_schema.elements.' + props.elementsObjAttrPage + '.elements', '')}>
                  <i className="fa fa-plus-circle "></i> Add Question
                </a>
                {props.currentPageIndex > 0 && <a className="btn btn-link btn-sm float-end text-danger" onClick={() => removeSectionPageFor_template_schema('template_schema.elements', props.elementsObjAttrPage + '')}>
                  Remove Page
                </a>}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  const TemplatesAddLogicKeySelect = (props) => {
    return (
      <select className="border-0 small text-black-50" value={_.get(addLogic4Control, props.current_add_logic_key + '.add_logic_condition') || ''} onChange={(event) => { onClick4AddLogicUpdateValues(props.current_add_logic_key, 'add_logic_condition', event.target.value) }} name={props.current_add_logic_key + '.add_logic_condition'}>
        {Object.keys(EnumTemplateFieldConditionalAction || []).map(key => (

          (props.current_add_logic_condition_enum.indexOf(key) > -1) && <option key={key} value={key}>
            {EnumTemplateFieldConditionalAction[key].replaceAll('_', ' ')}
          </option>

        ))}
      </select>
    );
  }

  const TemplatesAddLogicConditionValue = (props) => {
    if (props) {
      switch (props.current_fieldType) {
        case EnumTemplatesFieldType.radio:
        case EnumTemplatesFieldType.checkbox:
          switch (props.current_add_logic_conditionValue) {
            case EnumTemplateFieldConditionalAction.is:
            case EnumTemplateFieldConditionalAction.is_not:
              if (props.current_field_options?.length > 0) {
                if (!_.get(addLogic4Control, props.current_add_logic_key + '.add_logic_value')) {
                  _.set(addLogic4Control, props.current_add_logic_key + '.add_logic_value', props.current_field_options[0].value)
                }
              }
              return (
                <select className="border-0 small text-black-50" value={_.get(addLogic4Control, props.current_add_logic_key + '.add_logic_value')} onChange={(event) => { onClick4AddLogicUpdateValues(props.current_add_logic_key, 'add_logic_value', event.target.value) }} name={props.current_add_logic_key + '.add_logic_value'}>
                  {props.current_field_options?.map((optionsKey, indexOptions) => (

                    <option key={optionsKey.value} value={optionsKey.value}>
                      {optionsKey?.label || optionsKey?.title}
                    </option>

                  ))}
                </select>
              );


              break;
            case EnumTemplateFieldConditionalAction.is_selected:
            case EnumTemplateFieldConditionalAction.is_not_selected:
              _.set(addLogic4Control, props.current_add_logic_key + '.add_logic_value', null);
              break;
            case EnumTemplateFieldConditionalAction.is_one_of:
            case EnumTemplateFieldConditionalAction.is_not_one_of:
              if (props.current_field_options?.length > 0) {
                if (!_.get(addLogic4Control, props.current_add_logic_key + '.add_logic_value')) {
                  _.set(addLogic4Control, props.current_add_logic_key + '.add_logic_value', props.current_field_options[0].value)
                }
              }
              return (
                <select removeselected="true" joinvalues="true" multiple className="create-component-select multi-select form-select form-select-sm" value={_.get(addLogic4Control, props.current_add_logic_key + '.add_logic_value')} onChange={(event) => { onClick4AddLogicUpdateValuesSelectArrayType(props.current_add_logic_key, 'add_logic_value', event.target.value) }} name={props.current_add_logic_key + '.add_logic_value'}>
                  {props.current_field_options?.map((optionsKey, indexOptions) => (

                    <option key={optionsKey.value} value={optionsKey.value}>
                      {optionsKey?.label || optionsKey?.title}
                    </option>

                  ))}
                </select>
              );
              break;
          }
          break;
        case EnumTemplatesFieldType.textarea:
        case EnumTemplatesFieldType.text:
          // _.set(addLogic4Control, props.current_add_logic_key + '.add_logic_value', '')
          return (
            <input value={_.get(addLogic4Control, props.current_add_logic_key + '.add_logic_value')} onChange={(event) => { onClick4AddLogicUpdateValues(props.current_add_logic_key, 'add_logic_value', event.target.value) }} name={props.current_add_logic_key + '.add_logic_value'} placehoder="blank" type="text" className="form-control-sm" />
          );
          break;
      }
    }
    return (
      <label></label>
    )

  }
  const popoverTemplatesAddLogicActionValue = (props) => (
    <Popover id="popover-basic">

      <Popover.Body>
        <select className="border-0 small text-black-50" value={_.get(addLogic4Control, props.current_add_logic_key + '.add_logic_trigger_action')} onChange={(event) => { onClick4AddLogicUpdateValues(props.current_add_logic_key, 'add_logic_trigger_action', event.target.value) }} name={props.current_add_logic_key + '.add_logic_trigger_action'}>
          <option value="ask_question">Ask Question</option>
          <option value="require_evidence">Require Evidence</option>
        </select>

      </Popover.Body>
    </Popover>
  );
  const _return_action_trigger_super_question_data_from_parent_for_current_child = (_nestedPathFromRoot, _current_super_question_id) => {
    let _current_super_question_data = _.get(existingData, _nestedPathFromRoot + '.' + _current_super_question_id + '')?.super_question_data;
    let _current_super_question_data_from_parent = _.get(existingData, _nestedPathFromRoot + '.' + _current_super_question_data.name_of_super_question + '');
    return _current_super_question_data_from_parent?.fieldType;
  }
  const _return_action_trigger_selected_option_lable_for_current_child = (_nestedPathFromRoot, _current_super_question_id) => {

    let _current_super_question_data = _.get(existingData, _nestedPathFromRoot + '.' + _current_super_question_id + '')?.super_question_data;
    let _current_super_question_data_from_parent = _.get(existingData, _nestedPathFromRoot + '.' + _current_super_question_data.name_of_super_question + '');
    const _empty_string = '[Empty]';
    if (_current_super_question_data_from_parent) {
      switch (_current_super_question_data_from_parent?.fieldType) {
        case EnumTemplatesFieldType.radio:
        case EnumTemplatesFieldType.checkbox:
          let _all_options_from_super_question = _current_super_question_data_from_parent?.options;
          let _response_2_send = "";
          for (let item in _all_options_from_super_question) {
            if (_.isArray(_current_super_question_data?.add_logic_value)) {
              if (_current_super_question_data?.add_logic_value.includes(_all_options_from_super_question[item]?.value)) {
                _response_2_send = _response_2_send + " " + (_all_options_from_super_question[item]?.label || _all_options_from_super_question[item]?.title);
              }
            } else {
              if (_all_options_from_super_question[item]?.value == _current_super_question_data?.add_logic_value) {
                _response_2_send = _response_2_send + " " + (_all_options_from_super_question[item]?.label || _all_options_from_super_question[item]?.title);
              }
            }
          }
          return _.trim(_response_2_send) || _empty_string;
          break;
      }
      if (_.isArray(_current_super_question_data?.add_logic_value)) {
        return (_.join(_current_super_question_data?.add_logic_value, ' / ')) || _empty_string;
      } else {
        return (_current_super_question_data?.add_logic_value) || _empty_string;
      }
    }
    return _empty_string;
  }
  return (
    <div>
      <IconButton onClick={handleClickOpen}>
        <EditIcon />
      </IconButton>
      <form onSubmit={handleSubmit}>
        <Dialog
          open={open}
          onClose={handleClose}
          TransitionComponent={Transition}
          aria-labelledby="form-dialog-title"
          fullWidth
          fullScreen={true}
          disablePortal

        >

          {/* <DialogTitle id="form-dialog-title" className="text-center">Edit Template</DialogTitle> */}

          <DialogContent>

            <div className="col-lg-12 col-12 mb-4">
              <div className="row">
                <div className="col-xl-8 col-12">
                  <div className="row">
                    <div className="col-lg-4 col-8 mb-4">
                      <img src={process.env.REACT_APP_IMG_URL + existingData.image} className="me-2 w-100"
                        onError={({ currentTarget }) => {
                          currentTarget.onerror = null; // prevents looping
                          currentTarget.src = process.env.REACT_APP_IMG_URL + "uploads/logo.png";
                        }}

                      />
                      <input type="file" id="file" className="d-none" onChange={handleInputChangeForFileType} />
                      <label htmlFor="file" className="btn bg-green rounded-0 mt-2" title="Update Logo">
                        <EditIcon />
                      </label>
                    </div>
                    <div className="col-lg-8 col-8 mb-4">

                      {/* <h4>
                        {existingData?.title}
                      </h4>
                      <p>{existingData?.description}</p> */}

                      <div className="mb-3">
                        <label className="form-label fw-bold">Title*</label>
                        <input onChange={handleInputChange} value={existingData?.title} name="title" type="text" className="form-control border-green rounded-0 mb-3" required />
                      </div>

                      <div className="mb-3">
                        <label className="form-label fw-bold">Description*</label>
                        <textarea rows={5} onChange={handleInputChange} value={existingData?.description} name="description" type="text" className="form-control border-green rounded-0 mb-3" required />
                      </div>

                    </div>
                    <div className="col-lg-12 col-12 mb-4">

                      {Object.keys(existingData?.template_schema?.elements || []).map((elementsObjAttrPage, indexOuter) => {
                        var props = {
                          currentPageIndex: indexOuter,
                          PageHeaderLable: true,
                          PageHeaderEdit: false,
                          elementsObjAttrPage: elementsObjAttrPage
                        };
                        let _type_of_element = _.get(existingData, 'template_schema.elements.' + props.elementsObjAttrPage + '.type');
                        let _renderer_of_element = _.get(existingData, 'template_schema.elements.' + props.elementsObjAttrPage + '.renderer');

                        if (['button-group'].indexOf(_renderer_of_element) === -1) {

                          switch (_type_of_element) {
                            case EnumTemplatesFieldType.container:
                              switch (indexOuter) {
                                case 0:
                                  props.PageHeaderLable = true;
                                  props.PageHeaderEdit = false;

                                  return (TemplatesPageSchemaHtml({ currentPageIndex: indexOuter, PageHeaderLable: props.PageHeaderLable, PageHeaderEdit: props.PageHeaderEdit, elementsObjAttrPage: elementsObjAttrPage }));

                                  break;
                                default:
                                  props.PageHeaderLable = false;
                                  props.PageHeaderEdit = true;

                                  return (TemplatesPageSchemaHtml({ currentPageIndex: indexOuter, PageHeaderLable: props.PageHeaderLable, PageHeaderEdit: props.PageHeaderEdit, elementsObjAttrPage: elementsObjAttrPage }));

                                  break;
                              }
                              break;
                          }
                        }
                      })}
                    </div>
                    <div className="col-lg-12 col-12 mb-4">
                      <div className="mb-1">

                        <a className="btn btn-link btn-sm" onClick={addSectionPageFor_template_schema}>
                          <i className="fa fa-file"></i> Add Page
                        </a>

                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-4 col-12">
                  <div className="smartphone">
                    <div className="content">
                      <Form
                        initialValues={existingData?.template_datas}
                        schema={existingData?.template_schema}
                      />

                    </div>
                  </div>
                </div>
              </div>

            </div>

          </DialogContent>

          <DialogActions>
            <button type="button" className="btn btn-primary float-start" onClick={handleClose} color="primary" style={{ 'z-index': 1000 }}>
              Cancel
            </button>
            <button type='submit' className="btn btn-primary float-start" style={{ 'z-index': 1000 }} >
              Update
            </button>
          </DialogActions>

        </Dialog>
      </form>
    </div>
  );
}

export default FormDialogEditTemplates;