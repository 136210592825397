import API from "../utils/api";

export const ACTION_TYPES = {
    CLASS_CREATE: 'CLASS_CREATE',
    CLASS_UPDATE: 'CLASS_UPDATE',
    CLASS_DELETE: 'CLASS_DELETE',
    CLASS_FETCH: 'CLASS_FETCH',
    CLASS_FETCH_ALL: 'CLASS_FETCH_ALL',
    CLASS_PAGINATION: 'CLASS_PAGINATION',
    COURSE_PAGINATION: 'COURSE_PAGINATION',
    CLASS_FETCH_RANDOM_CLASSES: 'CLASS_FETCH_RANDOM_CLASSES',
    CLASS_FETCH_SEARCHED_CLASSES: 'CLASS_FETCH_SEARCHED_CLASSES'
}

export const fetchAll = () => dispatch => {
    API.class().fetchAll()
        .then(res => {
      
            dispatch({
                type: ACTION_TYPES.CLASS_FETCH_ALL,
                payload: res.data
            })
        })
        .catch(err => console.log(err))
}

export const Pagination = (page = 1, limit = 10, title = "", type) => dispatch => {

    API.class().fetchPagination(page, Math.abs(limit), title, type)
        .then(res =>{
         
            dispatch({
                type: ACTION_TYPES.CLASS_PAGINATION,
                payload: res.data
            })
        })
        .catch(err => console.log(err))
}

export const fetchById = (id, onSuccess) => dispatch => {
    API.class().fetchById(id)
        .then(res =>{
            dispatch({
                type: ACTION_TYPES.CLASS_FETCH,
                payload: res.data
            })
            onSuccess(res.data)
        })
        .catch(err => console.log(err))
}

export const create = (input, onSuccess) => dispatch => {
    API.class().create(input)
        .then(res =>{
    
            dispatch({
                type: ACTION_TYPES.CLASS_CREATE,
                payload: res.data
            })
            onSuccess()
        })
        .catch(err => console.log(err))
}

export const update = (id, input, onSuccess) => dispatch => {
   
    API.class().update(id, input)
        .then(res =>{
            dispatch({
                type: ACTION_TYPES.CLASS_UPDATE,
                payload: res.data
            })
            onSuccess()
        })
        .catch(err => console.log(err))
}

export const Delete = (id, onSuccess) => dispatch => {
    API.class().delete(id)
        .then(res =>{
         
            dispatch({
                type: ACTION_TYPES.CLASS_DELETE,
                payload: res.data.id
            })
            onSuccess()
        })
        .catch(err => console.log(err))
}

export const fetchRandomClasses = (param) => dispatch => {
    API.class().fetchRandomClasses(param)
        .then(res => {
              
            dispatch({
                type: ACTION_TYPES.CLASS_FETCH_RANDOM_CLASSES,
                payload: res.data
            })
        })
        .catch(err => console.log(err))
}

export const fetchSearchedClasses = (param) => dispatch => {
    API.class().fetchSearchedClasses({
        "searchString": param,
      })
        .then(res => {
              
            dispatch({
                type: ACTION_TYPES.CLASS_FETCH_SEARCHED_CLASSES,
                payload: res.data
            })
        })
        .catch(err => console.log(err))
}