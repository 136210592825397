import React, { useEffect, useState } from "react";
import image1 from '../../images/carousel-1.jpg'
import im1 from '../../images/1.jpg'
import im2 from '../../images/2.jpg'
import im3 from '../../images/3.jpg'
import im4 from '../../images/4.JPG'
import image2 from '../../images/head/2.jpg'
import image3 from '../../images/head/3.jpg'
import image4 from '../../images/head/4.jpg'
import image5 from '../../images/head/5.jpg'
import about from '../../images/about.jpg'
import service from '../../images/service-1.jpg'
import "./style.css"
import { MdSearch, MdClose } from "react-icons/md";
import { connect } from "react-redux";
import * as actions from "../../actions/class";
import * as user from "../../actions/user";


import CourseCard from "../../components/CourseCard";
import { Link, useLocation } from 'react-router-dom';
import moment from 'moment/min/moment-with-locales';

const Home = (props) => {
    let location = useLocation();


    useEffect(() => {
        if (location?.state?.href)
            document.getElementById(location?.state?.href)?.scrollIntoView({
                behavior: 'smooth'
            });
    }, [location?.state?.href])


    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };

    return (
        <>

            <div class="container-fluid p-0 mb-5 wow fadeIn" >
                <div id="header-carousel" class="carousel" >
                    <div class="carousel-inner">
                        <div class="carousel-item active">
                            <img class="w-100" src={image1} alt="Image" />
                            <div class="carousel-caption">
                                <div class="container">
                                    <div class="row justify-content-start">
                                        <div class="col-lg-7">
                                           
                                            <h1 class="mt-5 carh4 animated slideInDown mt-4">The world’s most concise and comprehensive solar panel cleaning
                                                reports are here. Clean, inspect and grow like a pro. With only 10 accounts being released each
                                                month, join the waiting list now.
                                            </h1>
                                            {/* <p
                                                class="d-inline-block border border-white rounded text-primary fw-semi-bold py-1 px-3 animated slideInDown carp mt-5" onClick={() => props.invokesignin(true)}>
                                                Join the waiting list</p> */}
                                            <a onClick={() => props.invokesignin(true)} class="btn btn-primary py-3 px-5 animated slideInDown mt-4">Join the waiting list</a>
                                        </div>
                                        <div class="col-lg-4">
                                        <div id="carouselExampleControls" class="carousel slide" data-bs-ride="carousel">
  <div class="carousel-inner">
    <div class="carousel-item active">
      <img src={image2} class="d-block w-100" />
    </div>
    <div class="carousel-item">
      <img src={image3} class="d-block w-100" />
    </div>
    <div class="carousel-item">
      <img src={image4} class="d-block w-100" />
    </div>
    <div class="carousel-item">
      <img src={image5} class="d-block w-100" />
    </div>
  </div>
  <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Previous</span>
  </button>
  <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Next</span>
  </button>
</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    {/* <button class="carousel-control-prev" type="button" data-bs-target="#header-carousel" data-bs-slide="prev">
                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Previous</span>
            </button>
            <button class="carousel-control-next" type="button" data-bs-target="#header-carousel" data-bs-slide="next">
                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Next</span>
            </button> */}
                </div>
            </div>

            <div class=" py-5">
                <div class="container">
                    <div class="row g-4 align-items-end mb-4">
                        <div class="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
                            <img class="img-fluid rounded" src={about} />
                        </div>
                        <div class="col-lg-6 wow fadeInUp" data-wow-delay="0.3s">
                            <p class="d-inline-block border rounded text-primary fw-semi-bold py-1 px-3">About Us</p>
                            <h1 class="display-5 mb-4">How Solarspect Will Help You Grow Your Business</h1>
                            <p class="mb-4">Solarspect reporting will help you grow your business by providing you with the most advanced solar
                                panel cleaning reporting software. Give your clients a true picture of the health, condition and
                                performance of their solar array.
                            </p>
                            <div class="border rounded p-4">
                                <nav>
                                    <div class="nav nav-tabs mb-3" id="nav-tab" role="tablist">
                                        <button class="nav-link fw-semi-bold active" id="nav-story-tab" data-bs-toggle="tab"
                                            data-bs-target="#nav-story" type="button" role="tab" aria-controls="nav-story"
                                            aria-selected="true">Story</button>
                                        <button class="nav-link fw-semi-bold" id="nav-mission-tab" data-bs-toggle="tab"
                                            data-bs-target="#nav-mission" type="button" role="tab" aria-controls="nav-mission"
                                            aria-selected="false">Mission</button>
                                        <button class="nav-link fw-semi-bold" id="nav-vision-tab" data-bs-toggle="tab"
                                            data-bs-target="#nav-vision" type="button" role="tab" aria-controls="nav-vision"
                                            aria-selected="false">Vision</button>
                                    </div>
                                </nav>
                                <div class="tab-content" id="nav-tabContent">
                                    <div class="tab-pane fade show active" id="nav-story" role="tabpanel"
                                        aria-labelledby="nav-story-tab">
                                        <p>Solarspect reports have been compiled by solar panel cleaners, for solar panel cleaners. This is real,
                                            tried and tested solar panel cleaning reporting software, preferred by many solar O&amp;M providers
                                            and asset owners.</p>

                                    </div>
                                    <div class="tab-pane fade" id="nav-mission" role="tabpanel"
                                        aria-labelledby="nav-mission-tab">
                                        <p>Our mission is to strap rocket boosters to your existing solar panel cleaning reports and
                                            professionalise your solar cleaning company. Provide more detail than ever to your clients!</p>

                                    </div>
                                    <div class="tab-pane fade" id="nav-vision" role="tabpanel" aria-labelledby="nav-vision-tab">
                                        <p>Solarspect continue to grow our reporting system and increase the levels of detail required as the
                                            solar panel cleaning industry develops. Over-the-air updates will ensure you will remain totally
                                            updated with the very best and latest solar cleaning reporting developments.</p>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="border rounded p-4 wow fadeInUp" data-wow-delay="0.1s">
                        <div class="row g-4">
                            <div class="col-lg-4 wow fadeIn" data-wow-delay="0.1s">
                                <div class="h-100">
                                    <div class="d-flex">
                                        <div class="flex-shrink-0 btn-lg-square rounded-circle bg-primary">
                                            <i class="fa fa-times text-white"></i>
                                        </div>
                                        <div class="ps-3">
                                            <h4>No Hidden Cost</h4>
                                            <span>Offer comprehensive pricing that include all features.</span>
                                        </div>
                                        <div class="border-end d-none d-lg-block"></div>
                                    </div>
                                    <div class="border-bottom mt-4 d-block d-lg-none"></div>
                                </div>
                            </div>
                            <div class="col-lg-4 wow fadeIn" data-wow-delay="0.3s">
                                <div class="h-100">
                                    <div class="d-flex">
                                        <div class="flex-shrink-0 btn-lg-square rounded-circle bg-primary">
                                            <i class="fa fa-users text-white"></i>
                                        </div>
                                        <div class="ps-3">
                                            <h4>Class Leading Reports</h4>
                                            <span>Gain actionable insights from comprehensive reports.</span>
                                        </div>
                                        <div class="border-end d-none d-lg-block"></div>
                                    </div>
                                    <div class="border-bottom mt-4 d-block d-lg-none"></div>
                                </div>
                            </div>
                            <div class="col-lg-4 wow fadeIn" data-wow-delay="0.5s">
                                <div class="h-100">
                                    <div class="d-flex">
                                        <div class="flex-shrink-0 btn-lg-square rounded-circle bg-primary">
                                            <i class="fa fa-phone text-white"></i>
                                        </div>
                                        <div class="ps-3">
                                            <h4>Scalability</h4>
                                            <span>Scale your reporting capabilities as your business expands.</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div class="container-fluid facts my-5 py-5 d-none">
                <div class=" py-5">
                    <div class="row g-5">
                        <div class="col-6 col-lg-4 text-center wow fadeIn" data-wow-delay="0.1s">
                            <i class="fa fa-users fa-3x text-white mb-3"></i>
                            <h1 class="display-4 text-white" data-toggle="counter-up">98</h1>
                            <span class="fs-5 text-white">Happy Clients</span>
                            <hr class="bg-white w-25 mx-auto mb-0" />
                        </div>
                        <div class="col-6 col-lg-4 text-center wow fadeIn" data-wow-delay="0.3s">
                            <i class="fa fa-check fa-3x text-white mb-3"></i>
                            <h1 class="display-4 text-white" data-toggle="counter-up">1800</h1>
                            <span class="fs-5 text-white">Reports Generated</span>
                            <hr class="bg-white w-25 mx-auto mb-0" />
                        </div>
                        <div class="col-6 col-lg-4 text-center wow fadeIn" data-wow-delay="0.5s">
                            <i class="fa fa-users-cog fa-3x text-white mb-3"></i>
                            <h1 class="display-4 text-white" data-toggle="counter-up">12</h1>
                            <span class="fs-5 text-white">Dedicated Staff</span>
                            <hr class="bg-white w-25 mx-auto mb-0" />
                        </div>
                        {/* <div class="col-6 col-lg-3 text-center wow fadeIn" data-wow-delay="0.7s">
                            <i class="fa fa-award fa-3x text-white mb-3"></i>
                            <h1 class="display-4 text-white" data-toggle="counter-up">8</h1>
                            <span class="fs-5 text-white">Awards Achieved</span>
                            <hr class="bg-white w-25 mx-auto mb-0" />
                        </div> */}
                    </div>
                </div>
            </div>

            <div class=" feature py-5">
                <div class="container">
                    <div class="row g-5 align-items-center">
                        <div class="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
                            <p class="d-inline-block border rounded text-primary fw-semi-bold py-1 px-3" onClick={() => props.invokesignin(true)}>Why Choose Us!</p>
                            <h1 class="display-5 mb-4">Why Solar Cleaning Pros Choose Us!</h1>
                            <ul>
                                <li>Specific residential, commercial, utility-scale and chemical cleaning report templates</li>
                                <li>Safety assessments</li>
                                <li>Performance assessments</li>
                                <li>Fire Risk assessments</li>
                                <li>Bird hazard assessments</li>
                                <li>Site security assessments</li>
                                <li>Email your report to your client direct from the app</li>


                            </ul>
                            <Link class="btn btn-primary py-3 px-5" onClick={() => props.invokesignin(true)}>Join the waiting list</Link>
                        </div>
                        <div class="col-lg-6">
                            <div class="row g-4 align-items-center">
                                <div class="col-md-6">
                                    <div class="row g-4">
                                        <div class="col-12 wow fadeIn" data-wow-delay="0.3s">
                                            <div class="feature-box border rounded p-4">
                                                <i class="fa fa-check fa-3x text-primary mb-3"></i>
                                                <h4 class="mb-3">Time-Saving</h4>
                                                <p class="mb-3">Automate repetitive tasks and generate reports in minutes.
                                                </p>

                                            </div>
                                        </div>
                                        <div class="col-12 wow fadeIn" data-wow-delay="0.5s">
                                            <div class="feature-box border rounded p-4">
                                                <i class="fa fa-check fa-3x text-primary mb-3"></i>
                                                <h4 class="mb-3">Accuracy & Consistency</h4>
                                                <p class="mb-3">Eliminate manual errors with automated data integration and validation.
                                                </p>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6 wow fadeIn" data-wow-delay="0.7s">
                                    <div class="feature-box border rounded p-4">
                                        <i class="fa fa-check fa-3x text-primary mb-3"></i>
                                        <h4 class="mb-3">Cost-Efficient</h4>
                                        <p class="mb-3">Reduce overhead costs associated with manual report generation.</p>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class=" service py-5">
                <div class="container">
                    <div class="text-center mx-auto wow fadeInUp" data-wow-delay="0.1s" >
                        <p class="d-inline-block border rounded text-primary fw-semi-bold py-1 px-3">Our Services</p>
                        <h1 class="display-5 mb-5">Carry Out Multiple Assessments
                            Inside Your Reports</h1>
                    </div>
                    <div class="row g-4 wow fadeInUp" data-wow-delay="0.3s">
                        <div class="col-lg-4">
                            <div class="nav nav-pills d-flex justify-content-between w-100 h-100 me-4">
                                <button class="nav-link w-100 d-flex align-items-center text-start border p-4 mb-4 active"
                                    data-bs-toggle="pill" data-bs-target="#tab-pane-1" type="button">
                                    <h5 class="m-0"><i class="fa fa-bars text-primary me-3"></i>Executive Summary Report</h5>
                                </button>
                                <button class="nav-link w-100 d-flex align-items-center text-start border p-4 mb-4"
                                    data-bs-toggle="pill" data-bs-target="#tab-pane-2" type="button">
                                    <h5 class="m-0"><i class="fa fa-bars text-primary me-3"></i>Performance assessments</h5>
                                </button>
                                <button class="nav-link w-100 d-flex align-items-center text-start border p-4 mb-4"
                                    data-bs-toggle="pill" data-bs-target="#tab-pane-3" type="button">
                                    <h5 class="m-0"><i class="fa fa-bars text-primary me-3"></i>Fire Risk assessments</h5>
                                </button>
                                <button class="nav-link w-100 d-flex align-items-center text-start border p-4 mb-0"
                                    data-bs-toggle="pill" data-bs-target="#tab-pane-4" type="button">
                                    <h5 class="m-0"><i class="fa fa-bars text-primary me-3"></i>Bird hazard assessments</h5>
                                </button>
                            </div>
                        </div>
                        <div class="col-lg-8">
                            <div class="tab-content w-100">
                                <div class="tab-pane fade show active" id="tab-pane-1">
                                    <div class="row g-4">
                                        <div class="col-md-6" >
                                            <div class="position-relative h-100">
                                                <img class="position-absolute rounded w-100 h-100" src={im1}
                                                    alt="" />
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <h3 class="mb-4">Executive Summary Report</h3>
                                            <p class="mb-4">A concise overview of key business metrics, achievements, and future projections.
                                            </p>
                                            <p><i class="fa fa-check text-primary me-3"></i> Residential Solar Cleaning Report,</p>
                                            <p><i class="fa fa-check text-primary me-3"></i>Commercial Solar Cleaning Report</p>
                                            <p><i class="fa fa-check text-primary me-3"></i>Chemical Cleaning Report</p>
                                            <p><i class="fa fa-check text-primary me-3"></i>More to follow
                                            </p>


                                        </div>
                                    </div>
                                </div>
                                <div class="tab-pane fade" id="tab-pane-2">
                                    <div class="row g-4">
                                        <div class="col-md-6" >
                                            <div class="position-relative h-100">
                                                <img class="position-absolute rounded w-100 h-100" src={im2}
                                                    alt="" />
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <h3 class="mb-4">Executive Summary Report</h3>
                                            <p class="mb-4">A concise overview of key business metrics, achievements, and future projections.
                                            </p>
                                            <p><i class="fa fa-check text-primary me-3"></i>Learn more reasons why your client’s system may be underperforming</p>
                                            <p><i class="fa fa-check text-primary me-3"></i>Upload photographs highlighting the faults</p>
                                            <p><i class="fa fa-check text-primary me-3"></i>Report faults such as MC4 issues, broken panels, inverter error codes and others</p>
                                            <p><i class="fa fa-check text-primary me-3"></i>Be more accurate than the inverter reading ‘before and after’ guys!
                                            </p>  </div>
                                    </div>
                                </div>
                                <div class="tab-pane fade" id="tab-pane-3">
                                    <div class="row g-4">
                                        <div class="col-md-6" >
                                            <div class="position-relative h-100">
                                                <img class="position-absolute rounded w-100 h-100" src={im3}
                                                    alt="" />
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <h3 class="mb-4">Executive Summary Report</h3>
                                            <p class="mb-4">A concise overview of key business metrics, achievements, and future projections.
                                            </p>
                                            <p><i class="fa fa-check text-primary me-3"></i>Teach your clients about fire risks on their solar array</p>
                                            <p><i class="fa fa-check text-primary me-3"></i>Upload photographs highlighting fire risks</p>
                                            <p><i class="fa fa-check text-primary me-3"></i>Report fire risks and provide suggestions to make your client’s system safer.</p>
                                            <p><i class="fa fa-check text-primary me-3"></i>Earn more from installing basic fire safety measures on your client’s systems
                                            </p> </div>
                                    </div>
                                </div>
                                <div class="tab-pane fade" id="tab-pane-4">
                                    <div class="row g-4">
                                        <div class="col-md-6" >
                                            <div class="position-relative h-100">
                                                <img class="position-absolute rounded w-100 h-100" src={im4}
                                                    alt="" />
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <h3 class="mb-4">Executive Summary Report</h3>
                                            <p class="mb-4">A concise overview of key business metrics, achievements, and future projections.
                                            </p>
                                            <p><i class="fa fa-check text-primary me-3"></i>Teach your clients how bird droppings and bird nests increase risks on solar arrays</p>
                                            <p><i class="fa fa-check text-primary me-3"></i>Upload photographs of the issues the birds are causing on your client’s array</p>
                                            <p><i class="fa fa-check text-primary me-3"></i>Vastly increase your earnings per client by installing bird proofing and effective bird
                                                deterrent measures</p>
                                        </div>
                                    </div>
                                </div>


                            </div>

                            <a class="btn btn-outline-primary text-center mt-5 btn-lg" onClick={() => props.invokesignin(true)}>
                                Join the waiting list</a>
                        </div>
                    </div>
                </div>
            </div>

        </>

    )
}


const mapStateToProps = state => ({

})

const mapActionToProps = {
    invokesignin: user.invokesignin

}

export default connect(mapStateToProps, mapActionToProps)(Home);