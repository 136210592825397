import React from 'react'

const About = () => {

  return (
    <>
   

    <div className='container ptop'>
      <div className='text-center mb-4'>
        <h3 className='fw-bold'>About Us</h3>
      </div>
      <section id="about" class="about">
      <div class="container aos-init aos-animate" data-aos="fade-up">

        <div class="row gy-4">
        
          <div class="col-lg-12 content order-last  order-lg-first">
           
            <h5 className='mb-3'>Story</h5>
            <p className='mb-4'>
            Solarspect reports have been compiled by solar panel cleaners, for solar panel cleaners. This is real,
tried and tested solar panel cleaning reporting software, preferred by many solar O&amp;M providers
and asset owners.
            </p>
            <h5 className='mb-3'>Mission</h5>
            <p className='mb-4'>
            Our mission is to strap rocket boosters to your existing solar panel cleaning reports and
professionalise your solar cleaning company. Provide more detail than ever to your clients!
            </p>
            <h5 className='mb-3'>How Solarspect Will Help You Grow Your Business</h5>
            <p className='mb-4'>
            Solarspect reporting will help you grow your business by providing you with the most advance solar
panel cleaning reporting software. Give your clients a true picture of the health, condition and
performance of their solar array.
            </p>
            <h5 className='mb-3'>Vision</h5>
            <p className='mb-4'>
            Solarspect continue to grow our reporting system and increase the levels of detail required as the
solar panel cleaning industry develops. Over-the-air updates will ensure you will remain totally
updated with the very best and latest solar cleaning reporting developments.
            </p>
       
          </div>
        </div>

      </div>
    </section>
    </div>

  </>

  )
}

export default About
