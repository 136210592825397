import React, { useEffect, useState } from "react";

import MUIDataTable from "mui-datatables";
import { connect } from "react-redux";
import * as actions from "../../../../actions/user";
import FormDialogAddUser from "../formDialog/FormDialogAddUser";
import FormDialogEditUser from "../formDialog/FormDialogEditUser";
import FormDialogDeleteUser from "../formDialog/FormDialogDeleteUser";
import moment from 'moment/min/moment-with-locales';
import { Link, useNavigate, useLocation } from "react-router-dom";

const UserTable = ({ classes, ...props }) => {
    let location = useLocation();
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(15)
    const [role, setRole] = useState(location?.pathname === "/admin/commercials" ? 'commercial' : 'residential')
    console.log(location.pathname)
    // useEffect(() => {
    //     props.fetchPagination(1, rowsPerPage, role)
    // }, [])

    useEffect(() => {
        setRole(location?.pathname === "/admin/commercials" ? 'commercial' : 'residential')
        props.fetchPagination(1, rowsPerPage, location?.pathname === "/admin/commercials" ? 'commercial' : 'residential')

    }, [location?.pathname])

    const handleChangePage = async (newPage) => {
        await setPage(newPage);
        props.fetchPagination(newPage + 1, rowsPerPage, role)
    };

    const handleChangeRowsPerPage = async (rowsPerPage) => {
        await setRowsPerPage(rowsPerPage);
        await setPage(0);
        props.fetchPagination(1, rowsPerPage, role)
    };

    const handleSearch = async (searchText) => {
        await setPage(0);
        props.fetchPagination(1, rowsPerPage, searchText, searchText)
    };

    const handleFilterChange = async (firstName, email) => {
        await setPage(0);
        props.fetchPagination(1, rowsPerPage, firstName, email)
    };

    const refresh = async () => {
        await setPage(0);
        props.fetchPagination(1, rowsPerPage, role)
    }

    const columns = [
        {
            name: "id",
            label: "ID",
            options: {
                display: false,
                filter: false,
                sort: false,
            }
        },
        {
            // left side of first column is too close with the container, give more space on it
            name: "firstname",
            label: "Name",
            options: {
                filter: true,
                sort: false,
                customHeadRender: (columnMeta, handleToggleColumn) => {
                    return (
                        <th key={columnMeta.index}
                            style={{
                                paddingLeft: "31px",
                                fontWeight: 500,
                                borderBottom: "1px solid rgba(224, 224, 224, .5)"
                            }}
                        >
                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start" }}>
                                {columnMeta.label}
                            </div>
                        </th>
                    );
                },
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <span style={{ marginLeft: 15 }}>
                            {value}
                        </span>
                    );
                }
            },

        },
        {
            // left side of first column is too close with the container, give more space on it
            name: "lastname",
            label: "Last Name",
            options: {
                filter: true,
                sort: false,
                customHeadRender: (columnMeta, handleToggleColumn) => {
                    return (
                        <th key={columnMeta.index}
                            style={{
                                paddingLeft: "31px",
                                fontWeight: 500,
                                borderBottom: "1px solid rgba(224, 224, 224, .5)"
                            }}
                        >
                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start" }}>
                                {columnMeta.label}
                            </div>
                        </th>
                    );
                },
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <span style={{ marginLeft: 15 }}>
                            {value}
                        </span>
                    );
                }
            },

        },
        {
            name: "email",
            label: "Email",
            options: {
                filter: true,
                sort: false,
            }
        },
        {
            name: "birthday",
            label: "Birthday",
            options: {
                filter: true,
                sort: false,
                display: false
            }
        },
        {
            name: "image",
            label: "image",
            options: {
                filter: true,
                sort: false,
                display: false
            }
        },
        {
            name: "subjects",
            label: "subjects",
            options: {
                filter: true,
                sort: false,
                display: false
            }
        },
        {
            name: "experience",
            label: "experience",
            options: {
                filter: true,
                sort: false,
                display: false
            }
        },
        {
            name: "mobile",
            label: "Mobile",
            options: {
                filter: true,
                sort: false,
                display: false
            }
        },
        {
            name: "createdDate",
            label: "Registration Date",
            options: {
                filter: true,
                sort: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return moment(value).format('lll');
                }
            }
        },
        {
            name: "approved",
            label: "Verification Status",
            options: {
                filter: true,
                sort: false,
               // display: location?.pathname === "/admin/commercials" ? true : false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return value ? 'approved' : 'Pending';
                }
            }
        },
        {
            name: "role",
            label: "role",
            options: {
                filter: true,
                sort: false,
                display: false
            }
        },
        {
            name: "plantype",
            label: "Plan",
            options: {
                filter: true,
                sort: false,
                display: location?.pathname === "/admin/commercials" ? true : false,

            }
        },
        {
            name: "",
            options: {
                filter: false,
                sort: false,
                empty: true,
                customHeadRender: (columnMeta, handleToggleColumn) => {
                    return (
                        <th key={columnMeta.index} style={{ paddingRight: "16px" }}>
                            {/* <div style={{display:"flex", flexDirection:"row", justifyContent:"flex-end"}}>
                                <FormDialogAddUser component={Paper}  
                                    create={props.create}
                                    refresh={refresh}
                                />
                            </div> */}
                        </th>
                    );
                },
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end" }}>
                            <FormDialogEditUser
                                dataUser={tableMeta.rowData}
                                update={props.update}
                            />
                            {/* <FormDialogDeleteUser 
                                dataUser={tableMeta.rowData}
                                delete={props.delete}
                                refresh={refresh}
                            /> */}
                        </div>
                    );
                }
            }
        },

    ];

    const options = {
        filter: false,
        filterType: 'textField',
        responsive: 'stacked',
        selectableRows: false,
        rowsPerPageOptions: [5, 10, 25],
        serverSide: true,
        viewColumns: false,
        print: false,
        download: false,

        rowsPerPage: rowsPerPage,
        count: props.meta.totalDocs || 0,
        page: page,
        textLabels: {
            pagination: {
                next: "Next",
                previous: "previous",
                rowsPerPage: "Page per rows:",
                displayRows: "Page",
            },
        },

        onTableChange: (action, tableState) => {
            switch (action) {
                case 'changePage':
                    handleChangePage(tableState.page)
                    break;

                case 'changeRowsPerPage':
                    handleChangeRowsPerPage(tableState.rowsPerPage)
                    break;

                case 'search':
                    handleSearch(tableState.searchText)
                    break;

                case 'filterChange':
                    handleFilterChange(tableState.filterList[1], tableState.filterList[2])
                    break;

                case 'resetFilters':
                    handleSearch("")
                    break;

                default:
                    break;
            }
        },
    };

    return (
        <div style={{ zIndex: 111 }}>
            <MUIDataTable
                data={props.users}
                columns={columns}
                options={options}
            />
        </div>

    );
}

const mapStateToProps = state => ({
    users: state.user.users,
    meta: state.user.metaUser
})

const mapActionToProps = {
    fetchPagination: actions.Pagination,
    create: actions.create,
    update: actions.update,
    delete: actions.Delete
}
export default connect(mapStateToProps, mapActionToProps)(UserTable);
