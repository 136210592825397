//1,2 => text/textarea
//1,2,3,4,5,6 => checkbox/radio
//3,4 => swich
//7,8,9,10,11,12,13,14 => slider/number
///////rest all no add logic
const EnumTemplateFieldConditionalAction = {
    is: 'is',
    is_not: 'is_not',
    is_selected: 'is_selected',
    is_not_selected: 'is_not_selected',
    is_one_of: 'is_one_of',
    is_not_one_of: 'is_not_one_of',
    less_than: 'less_than',
    less_than_or_equal_to: 'less_than_or_equal_to',
    equal_to: 'equal_to',
    not_equal_to: 'not_equal_to',
    greater_than_or_equal_to: 'greater_than_or_equal_to',
    greater_than: 'greater_than',
    is_between: 'is_between',
    is_not_between: 'is_not_between'
};
const EnumTemplatesFieldType = {
    switch: 'switch',
    radio: 'radio',
    checkbox: 'checkbox',
    date: 'date',
    number: 'number',
    textarea: 'textarea',
    text: 'text',
    container: 'container',
    'file-uploader': 'file-uploader',
    email: 'email',
    telephone: 'telephone',
    range: 'range',
    'inner-text': 'inner-text'
};

const EnumTemplatesFieldTypeWithLabel = {
    switch: {
        label: 'Switch',
        add_logic: true,
        do_not_show_required: false,
        add_logic_condition: [
            EnumTemplateFieldConditionalAction.is_selected,
            EnumTemplateFieldConditionalAction.is_not_selected
        ],
    },
    radio: {
        label: 'Single Choice',
        add_logic: true,
        do_not_show_required: false,
        add_logic_condition: [
            EnumTemplateFieldConditionalAction.is,
            EnumTemplateFieldConditionalAction.is_not,
            EnumTemplateFieldConditionalAction.is_selected,
            EnumTemplateFieldConditionalAction.is_not_selected,
            EnumTemplateFieldConditionalAction.is_one_of,
            EnumTemplateFieldConditionalAction.is_not_one_of,
        ],
    },
    checkbox: {
        label: 'Multiple Choice',
        add_logic: true,
        do_not_show_required: false,
        add_logic_condition: [
            EnumTemplateFieldConditionalAction.is,
            EnumTemplateFieldConditionalAction.is_not,
            EnumTemplateFieldConditionalAction.is_selected,
            EnumTemplateFieldConditionalAction.is_not_selected,
            EnumTemplateFieldConditionalAction.is_one_of,
            EnumTemplateFieldConditionalAction.is_not_one_of,
        ],
    },
    date: {
        label: 'Date',
        add_logic: false,
        do_not_show_required: false,
        add_logic_condition: [],
    },
    number: {
        label: 'Number',
        add_logic: true,
        do_not_show_required: false,
        add_logic_condition: [
            EnumTemplateFieldConditionalAction.less_than,
            EnumTemplateFieldConditionalAction.less_than_or_equal_to,
            EnumTemplateFieldConditionalAction.equal_to,
            EnumTemplateFieldConditionalAction.not_equal_to,
            EnumTemplateFieldConditionalAction.greater_than_or_equal_to,
            EnumTemplateFieldConditionalAction.greater_than,
            EnumTemplateFieldConditionalAction.is_between,
            EnumTemplateFieldConditionalAction.is_not_between
        ],
    },
    textarea: {
        label: 'Paragraph',
        add_logic: true,
        do_not_show_required: false,
        add_logic_condition: [
            EnumTemplateFieldConditionalAction.is,
            EnumTemplateFieldConditionalAction.is_not
        ],
    },
    text: {
        label: 'Text Answer',
        add_logic: true,
        do_not_show_required: false,
        add_logic_condition: [
            EnumTemplateFieldConditionalAction.is,
            EnumTemplateFieldConditionalAction.is_not
        ],
    },
    'file-uploader': {
        label: 'Media',
        add_logic: false,
        do_not_show_required: false,
        add_logic_condition: [],
    },
    range: {
        label: 'Slider',
        add_logic: true,
        do_not_show_required: false,
        add_logic_condition: [
            EnumTemplateFieldConditionalAction.less_than,
            EnumTemplateFieldConditionalAction.less_than_or_equal_to,
            EnumTemplateFieldConditionalAction.equal_to,
            EnumTemplateFieldConditionalAction.not_equal_to,
            EnumTemplateFieldConditionalAction.greater_than_or_equal_to,
            EnumTemplateFieldConditionalAction.greater_than,
            EnumTemplateFieldConditionalAction.is_between,
            EnumTemplateFieldConditionalAction.is_not_between
        ],
    },
    'inner-text': {
        label: 'Instructions',
        add_logic: false,
        do_not_show_required: true,
        add_logic_condition: [],
    }
};
/////working old use this if old required
// const initialFormStateTemplate_template_schema = {
//     id: "templates",
//     label: "Templates",
//     type: "container",
//     renderer: "form",
//     elements: {
//         template_page_title_page: {
//             title: "Title Page",
//             type: "container",
//             renderer: "fieldset",
//             htmlClass: "container",
//             elements: {
//                 page_question_report_title: {
//                     name: "page_question_report_title",
//                     label: "Report Title",
//                     attributes: {
//                         placeholder: "Report Title"
//                     },
//                     type: "field",
//                     renderer: "text",
//                     fieldType: "text",
//                     validation: [
//                         ["string"],
//                         ["required", "Please enter your report title"]
//                     ]
//                 }
//             }
//         },
//         buttonsGroup: {
//             type: "container",
//             renderer: "button-group",
//             buttonsContainerClass: "buttons-container mt-2",
//             elements: {
//                 save: {
//                     type: "field",
//                     renderer: "button",
//                     name: "save",
//                     content: "Save",
//                     fieldClass: "btn-primary px-5",
//                     buttonType: "submit",
//                 }
//             }
//         }

//     }
// };
const initialFormStateTemplate_template_schema = {
    id: "templates",
    label: "Templates",
    type: "container",
    renderer: "form",
    elements: {
        template_page_title_page: {
            title: "Site",
            type: "container",
            renderer: "fieldset",
            htmlClass: "container",
            elements: {
                page_question_report_title: {
                    name: "page_question_report_title",
                    label: "Report Title",
                    attributes: {
                        placeholder: "Report Title",
                        do_not_show_delete_btn: true
                    },
                    type: "field",
                    renderer: "text",
                    fieldType: "text",
                    validation: [
                        ["string"],
                        ["required", "*Required"]
                    ]
                },
                page_question_site_name: {
                    name: "page_question_site_name",
                    label: "Site Name",
                    attributes: {
                        placeholder: "Site Name",
                        do_not_show_delete_btn: true
                    },
                    type: "field",
                    renderer: "text",
                    fieldType: "text",
                    validation: [
                        ["string"],
                        ["required", "*Required"]
                    ]
                },
                page_question_site_address: {
                    name: "page_question_site_address",
                    label: "Site Address",
                    attributes: {
                        placeholder: "Site Address",
                        do_not_show_delete_btn: true
                    },
                    type: "field",
                    renderer: "textarea",
                    fieldType: "textarea",
                    validation: [
                        ["string"],
                        ["required", "*Required"]
                    ]
                },
                page_question_works_date: {
                    name: "page_question_works_date",
                    label: "Works Date",
                    attributes: {
                        placeholder: "Works Date",
                        do_not_show_delete_btn: true
                    },
                    type: "field",
                    renderer: "text",
                    fieldType: "date",
                    validation: [
                        ["date"],
                        ["required", "*Required"]
                    ]
                },
                page_question_report_created_by: {
                    name: "page_question_report_created_by",
                    label: "Report Created By",
                    attributes: {
                        placeholder: "Report Created By",
                        do_not_show_delete_btn: true
                    },
                    type: "field",
                    renderer: "text",
                    fieldType: "text",
                    validation: [
                        ["string"],
                        ["required", "Report Created By"]
                    ]
                },
                page_question_clean_completed_by: {
                    name: "page_question_clean_completed_by",
                    label: "Clean Completed By",
                    attributes: {
                        placeholder: "Clean Completed By",
                        do_not_show_delete_btn: true
                    },
                    type: "field",
                    renderer: "text",
                    fieldType: "text",
                    validation: [
                        ["string"],
                        ["required", "*Required"]
                    ]
                },
                page_question_instructions: {
                    name: "page_question_instructions",
                    label: "Instructions",
                    attributes: {
                        placeholder: "Instructions",
                        do_not_show_delete_btn: false
                    },
                    type: "field",
                    renderer: "inner-text",
                    fieldType: "inner-text",
                    // as: "i",
                    // htmlClass: 'text-muted d-block mb-3 mt-1',
                    // defaultValue: 'Instructions'
                },
            }
        },
        buttonsGroup: {
            type: "container",
            renderer: "button-group",
            buttonsContainerClass: "buttons-container mt-2",
            elements: {
                save: {
                    type: "field",
                    renderer: "button",
                    name: "save",
                    content: "Save",
                    fieldClass: "btn-primary px-5",
                    buttonType: "submit",
                }
            }
        }

    }
};
const initialFormStateTemplate = {
    id: null,
    title: "",
    description: "",
    template_schema: initialFormStateTemplate_template_schema,
    image: "",
    status: "pending",
    type: "residential",
    owner: "",
    createdBy: "",
};
const initialFormStateInspections = {
    id: null,
    report_title: "",
    title: "",
    description: "",
    template_data: {},
    template_schema: initialFormStateTemplate_template_schema,
    template_schema_pdf_url: "",
    image: "",
    company_image: "",
    status: "pending",
    type: "",
    owner: "",
    createdBy: "",
};
const defaultMessageIfNotAnswerReceivedFromUser = "~ Not Answered ~";
export { EnumTemplatesFieldType, EnumTemplatesFieldTypeWithLabel, initialFormStateTemplate, initialFormStateInspections, EnumTemplateFieldConditionalAction, defaultMessageIfNotAnswerReceivedFromUser }