import React, { useEffect, useState } from 'react'
import { connect } from "react-redux";
import { useParams, useLocation } from 'react-router-dom';
import _ from "lodash";
import * as actions from "../../actions/inspections";
import { initialFormStateInspections, EnumTemplatesFieldType } from "../../utils/enums";
import { handleFormUploadSchemaEvent } from "../../utils/templates_util";
import './style.css'

const AddInspections = ({ inspections, ...props }) => {
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const [existingData, setInspections] = useState(initialFormStateInspections);
  const [errors, setErrors] = useState({});
  const [avatar, setAvatar] = useState(existingData?.image);
  const [serverActionIsInProgress, setServerActionIsInProgress] = useState(false);
  
  useEffect(() => {
    debugger;
    let inspection_id = location.pathname.split("/").pop();
    const token = location.search.split('=')[1];
    if (token) {
      localStorage.setItem('token', token);
    }
    console.log('inspection_id', inspection_id);
    const onSuccess = (_temp_existingData) => {
      console.log('15 :: inspection :: ', _temp_existingData);

      _temp_existingData.template_schema = updateObjectFieldsForCustomDropFn(_temp_existingData.template_schema);
      setInspections(_temp_existingData);

    }

    props.fetchById(inspection_id, onSuccess);

  }, [location])

  const updateObjectFieldsForCustomDropFn = (_temp_existingData) => {
    for (var prop in _temp_existingData) {
      //console.log(prop);
      switch (prop) {
        case 'elements':
          _temp_existingData[prop] = updateObjectFieldsForCustomDropFn(_temp_existingData[prop]);
          break;
        case 'fieldType':
          switch (_temp_existingData[prop]) {
            case "file-uploader":
              if (!_temp_existingData.options) {
                _temp_existingData.options = {};
              }
              _temp_existingData.options.onDrop = handleFormUploadSchemaEvent;
              break;
          }
          break;
        default:
          if (_temp_existingData[prop] && _temp_existingData[prop]?.renderer) {
            _temp_existingData[prop] = updateObjectFieldsForCustomDropFn(_temp_existingData[prop]);
          }
          break;
      }
    }
    return _temp_existingData;
  }
  const _returnT_for_question_is_set_validation_required = (_nestedPathFromRoot) => {
    let _is_requiredT = false;
    let _existing_validation = _.get(existingData, _nestedPathFromRoot) || [];
    _existing_validation.map((obj, index) => {
      if (obj) {
        if (obj.indexOf("required") > -1) {
          _is_requiredT = true;
        }
      }
    });
    return _is_requiredT;
  }
  const TemplatePageValueOfSchemaHtml = (props) => {

    const _attrName = props.name;
    const _fieldType = props.object.fieldType || props.object.type;
    const _attrOptions = props.object.options || [];
    const _attrValue = existingData.template_data[_attrName] || existingData.template_data[props.object.name];
    switch (_fieldType) {
      case EnumTemplatesFieldType.radio:

        return (
          <div className="mb-1 col-lg-4 col-12">
            {
              _attrOptions?.map((optionsKey, indexOptions) => {

                const _current_options_result_is_matched = (optionsKey.value === _attrValue) ? true : false;
                return (
                  (_current_options_result_is_matched === true && <label className="badge bg-success fw-light m-1 px-2 pb-1">{optionsKey?.label || optionsKey?.title} </label>)
                  // || (_current_options_result_is_matched !== true && <label className="badge bg-danger fw-light m-1 px-2 pb-1">{optionsKey?.label || optionsKey?.title} </label>)
                )
              })
            }
          </div>
        )
        break;
      case EnumTemplatesFieldType.checkbox:
        return (
          <div className="mb-1 col-lg-4 col-12">
            {
              _attrValue?.map((optionsValue, indexOptions) => {

                const optionsKey = _attrOptions[indexOptions]
                const _current_options_result_is_matched = (['on', true, 1].indexOf(optionsValue) > -1 || _.intersection(['on', true, 1], optionsValue).length > 0) ? true : false;
                return (
                  (_current_options_result_is_matched === true && <label className="badge bg-success fw-light m-1 px-2 pb-1">{optionsKey?.label || optionsKey?.title} </label>)
                  // || (_current_options_result_is_matched !== true && <label className="badge bg-danger fw-light m-1 px-2 pb-1">{optionsKey?.label || optionsKey?.title} </label>)
                )

              })
            }
          </div>
        )
        break;
      case EnumTemplatesFieldType["file-uploader"]:
        return (
          <div className="mb-w col-lg-12 col-4">
            <div className="row">
              {_attrValue?.map((optionsValue, indexOptions) => {
                return (
                  <div className="col-lg-4 col-8 mb-2">
                    <img src={process.env.REACT_APP_IMG_URL + optionsValue} className="me-2" />
                  </div>
                )
              })}
            </div>
          </div>
        )

        break;
      default:
        return (
          <div className="mb-1 col-lg-4 col-12">
            <label className="form-label fw-bold">{_attrValue} </label>
          </div>
        );
        break;
    }

  }
  const TemplatesPageSchemaHtml = (props) => {

    return (
      <div className=" mb-4">
        <div className="bg-xlight py-2 px-2 mb-2">
          <div className="form-label fw-bold mb-0">{_.get(existingData, 'template_schema.elements.' + props.elementsObjAttrPage + '.title')} :</div>
        </div>
        <div className="p-2">

          <div className="row">
            <div className="col-lg-12 col-12">
              {Object.keys(_.get(existingData, 'template_schema.elements.' + props.elementsObjAttrPage + '.elements')).length > 0 && <div className="mb-2">
                {Object.keys(_.get(existingData, 'template_schema.elements.' + props.elementsObjAttrPage + '.elements')).map((elementsObjAttrPageInner, indexInner) => {
                  return (
                    <div className="pt-2 pb-1 border-bottom" key={elementsObjAttrPageInner + '_' + indexInner}>
                      <div className="row ">
                        <div className="col-lg-8 col-12 ">

                          <p className="mb-2 text-black-50">
                            {_.get(existingData, 'template_schema.elements.' + props.elementsObjAttrPage + '.elements.' + elementsObjAttrPageInner + '.label')}
                            {_returnT_for_question_is_set_validation_required('template_schema.elements.' + props.elementsObjAttrPage + '.elements.' + elementsObjAttrPageInner + '.validation') === true && <label className="form-check-label"> *</label>}
                          </p>

                        </div>

                        <TemplatePageValueOfSchemaHtml name={elementsObjAttrPageInner} object={_.get(existingData, 'template_schema.elements.' + props.elementsObjAttrPage + '.elements.' + elementsObjAttrPageInner + '')} />

                      </div>

                    </div>
                  );
                })}
              </div>}

            </div>
          </div>
        </div>
      </div>
    );
  }


  return (


    <div className="px-2 mt-3">
      <div className="row">
        <div className="col-lg-4 col-8 mb-4">
          <img src={process.env.REACT_APP_IMG_URL + existingData.image} className="avatarprofle me-2" onError={({ currentTarget }) => {
                      currentTarget.onerror = null; // prevents looping
                      currentTarget.src = process.env.REACT_APP_IMG_URL +"uploads/logo.png";
                }}/>
        </div>
        <div className="col-lg-8 col-8 mb-4">
          <div className="mb-3">
            <h4>
              {existingData?.title}
            </h4>
            <p>
              {existingData?.description}
            </p>
          </div>
        </div>
        <div className="col-lg-12 col-12">

          {
            Object.keys(existingData?.template_schema?.elements).map((elementsObjAttrPage, indexOuter) => {

              let _type_of_element = _.get(existingData, 'template_schema.elements.' + elementsObjAttrPage + '.type');
              let _renderer_of_element = _.get(existingData, 'template_schema.elements.' + elementsObjAttrPage + '.renderer');

              if (['button-group'].indexOf(_renderer_of_element) === -1) {

                switch (_type_of_element) {
                  case EnumTemplatesFieldType.container:
                    switch (indexOuter) {
                      default:
                        return (
                          <TemplatesPageSchemaHtml key={elementsObjAttrPage + '_' + indexOuter} currentPageIndex={indexOuter} PageHeaderLable={false} PageHeaderEdit={false} elementsObjAttrPage={elementsObjAttrPage} />
                        );
                        break;
                    }
                    break;
                }
              }
            })}


        </div>


      </div>

    </div>







  );

}

const mapStateToProps = state => ({
  inspections: state?.inspections?.inspections
})
const mapActionToProps = {
  fetchById: actions.fetchById,
  create: actions.create,
  update: actions.update,
}

export default connect(mapStateToProps, mapActionToProps)(AddInspections);
