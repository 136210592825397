import React, { useState, useContext, useEffect } from "react";
import { AuthContext } from "../../context/AuthContext";
import { Link, useNavigate, useLocation } from "react-router-dom";
import './style.css'
import * as user from "../../actions/user";
import { connect } from "react-redux";


function HeaderBottom(props) {

    const { getAuthUser, isAuthenticated } = useContext(AuthContext)
    const authUser = getAuthUser()
    const navigate = useNavigate()
    let location = useLocation();

    return (
        <>

            <div className='container bg-yellow py-4 mb-4'>
                {authUser?.role === 'commercial' && <div className="row">
                    <div className="col text-center ">
                    <Link className={location?.pathname === "/commercial/templates" ? "border-bottom-green" : ""} to="/commercial/templates" >Templates</Link>
                    </div>
                    <div className="col text-center ">
                    <Link className={location?.pathname === "/commercial/inspections" ? "border-bottom-green" : ""} to="/commercial/inspections" >Inspections</Link>
                    </div>
                    <div className="col text-center ">
                        <Link className={location?.pathname === "/commercial/profile" ? "border-bottom-green" : ""} to="/commercial/profile"></Link>
                    </div>

                    <div className="col text-center ">
                        <Link className={location?.pathname === "/commercial/classes" ? "border-bottom-green" : ""} to="/commercial/classes" >Clases</Link>
                    </div>

                    <div className="col text-center ">
                        <Link className={location?.pathname === "/commercial/courses" ? "border-bottom-green" : ""} to="/commercial/courses" >Cursos</Link>
                    </div>


                    <div className="col text-center">
                        <Link className={location?.pathname === "/commercial/how-does-it-works" ? "border-bottom-green" : ""} to="/commercial/how-does-it-works">Cómo funciona</Link>
                    </div>
                    <div className="col text-center">
                        <Link className={location?.pathname === "/commercial/payments" ? "border-bottom-green" : ""} to="/commercial/payments">Pagos</Link>
                    </div>
                </div>}

                {authUser?.role === 'residential' && <div className="row">
                    <div className="col text-center ">
                        <Link className={location?.pathname === "/residential/profile" ? "border-bottom-green" : ""} to="/residential/profile">Perfil</Link>
                    </div>

                    <div className="col text-center ">
                        <Link className={location?.pathname === "/residential/subscriptions" ? "border-bottom-green" : ""} to="/residential/subscriptions">Clases registradas</Link>
                    </div>

                    <div className="col text-center ">
                        <Link className={location?.pathname === "/residential/ended-subscriptions" ? "border-bottom-green" : ""} to="/residential/ended-subscriptions">Clases finalizadas</Link>
                    </div>

                </div>}

                {authUser?.role === 'admin' && <div className="row">
                    <div className="col text-center ">
                        <Link className={location?.pathname === "/admin/commercials" ? "border-bottom-green" : ""} to="/admin/commercials">Profesores</Link>
                    </div>

                    <div className="col text-center ">
                        <Link className={location?.pathname === "/admin/residentials" ? "border-bottom-green" : ""} to="/admin/residentials">Alumnos</Link>
                    </div>

                    <div className="col text-center ">
                        <Link className={location?.pathname === "/admin/classes" ? "border-bottom-green" : ""} to="/admin/classes">Clases</Link>
                    </div>

                    <div className="col text-center ">
                        <Link className={location?.pathname === "/admin/orders" ? "border-bottom-green" : ""} to="/admin/orders">Datos financieros</Link>
                    </div>

                </div>}

            </div>

        </>
    );
}


const mapStateToProps = state => ({

})

const mapActionToProps = {

    updateUser: user.update,

}

export default connect(mapStateToProps, mapActionToProps)(HeaderBottom);