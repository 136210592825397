import React, { useEffect, useState, useContext } from "react";

import MUIDataTable from "mui-datatables";
import { connect } from "react-redux";
import * as actions from "../../../../actions/templates";
import FormDialogAddTemplates from "../formDialog/FormDialogAddTemplates";
import FormDialogEditTemplates from "../formDialog/FormDialogEditTemplates";
import FormDialogDeleteTemplates from "../formDialog/FormDialogDeleteTemplates";
import FormDialogViewTemplates from "../formDialog/FormDialogViewTemplates";
import FormDialogCloneTemplates from "../formDialog/FormDialogCloneTemplates";
//import moment from "moment";
import moment from 'moment/min/moment-with-locales';
import { toast } from 'react-toastify';
import { Link, useNavigate, useLocation } from "react-router-dom";
import PlayCircle from '@mui/icons-material/PlayCircle';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import sha1 from 'crypto-js/sha1'
import { AuthContext } from "../../../../context/AuthContext";
import _ from 'lodash'

const TemplatesTable = ({ templates, ...props }) => {
    let location = useLocation();
    const { getAuthUser } = useContext(AuthContext)
    const authUser = getAuthUser()
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(10)

    const [type, setType] = useState("")

    const onError = (err) => {
        if (err?.show_message2user) {
            //   props.refresh();
            //   setOpen(false);
            toast.error(err.message);
        }
    };


    useEffect(() => {
        if (location?.pathname?.indexOf("/templates") > -1) {
            props.fetchPagination(1, rowsPerPage, null, authUser?.id, onError);
            //setType('single');
            // props.fetchAll();
        }
        // if (location?.pathname === "/commercial/courses") {
        //     props.fetchPagination(1, rowsPerPage, null, 'multiple')
        //     setType('multiple')
        // }

    }, [location?.pathname])

    function return_query_string_from_json_object(obj, without_qm) {
        let s = "";
        if (_.isObject(obj)) {
            for (var key in obj) {
                if (s !== "") {
                    s += "&";
                }
                s += (key + "=" + encodeURIComponent(obj[key]));
            }
        }
        return (without_qm ? "" : "?") + s + "";
    }

    const handleChangePage = async (newPage) => {
        await setPage(newPage);
        props.fetchPagination(newPage + 1, rowsPerPage, null, authUser?.id, onError);
    };

    const handleChangeRowsPerPage = async (rowsPerPage) => {
        await setRowsPerPage(rowsPerPage);
        await setPage(0);
        props.fetchPagination(1, rowsPerPage, null, authUser?.id, onError);

    };

    const handleSearch = _.debounce(async (searchText) => {
        await setPage(0);
        props.fetchPagination(1, rowsPerPage, searchText, authUser?.id, onError);
    }, 1000, { leading: false, trailing: true });

    const handleFilterChange = async (title) => {
        await setPage(0);
        props.fetchPagination(1, rowsPerPage, title, authUser?.id, onError);
    };

    const refresh = async () => {
        await setPage(0);
        props.fetchPagination(1, rowsPerPage, null, authUser?.id, onError);
    }

    const popover = (templates) => (
        <Popover id="popover-basic">

            <Popover.Body>
                <table className="table table-bordered fw-normal small mb-0">
                    <tbody>
                        {templates?.map((item, index) =>

                            <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{item.title}</td>
                                <td>{moment(item.datetime).local().locale('es').format('lll')}</td>

                            </tr>

                        )}
                    </tbody>
                </table>

            </Popover.Body>
        </Popover>
    );

    const columns = [
        {
            name: "id",
            label: "ID",
            options: {
                display: false,
                filter: false,
                sort: false,
            }
        },
        {
            // left side of first column is too close with the container, give more space on it
            name: "title",
            label: "Template",
            options: {
                filter: true,
                sort: false

            },

        },
        {
            name: "description",
            label: "Description",
            options: {
                filter: true,
                sort: false,
                display: false
            }
        },
        {
            name: "template_schema",
            label: "Template Schema",
            options: {
                filter: false,
                sort: false,
                display: false,
            },
        }, {
            name: "template_schema_pdf_url",
            label: "PDF Url",
            options: {
                filter: false,
                sort: false,
                display: false,
            },
        },
        {
            name: "image",
            label: "Image",
            options: {
                filter: false,
                sort: false,
                display: false,
            },
        },
        {
            name: "status",
            label: "status",
            options: {
                filter: false,
                sort: false,
                display: false,
            },

        },
        {
            name: "type",
            label: "Type",
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return value
                },
            },

        },
        {
            name: "owner",
            label: "owner",
            options: {
                filter: false,
                sort: false,
                display: false,
            },

        },
        {
            name: "createdBy",
            label: "Created By",
            options: {
                filter: false,
                sort: false,
                display: false,
            },

        },
        {
            name: "createdDate",
            label: "Created On",
            options: {
                filter: true,
                sort: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return moment(value).format('lll');
                },
                display: true
            }
        },
        {
            name: "createdOn",
            label: "Created on",
            options: {
                filter: true,
                sort: false,
                display: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return moment(value).format('lll');
                }
            }
        },
        {
            name: "",
            options: {
                filter: false,
                sort: false,
                empty: true,
                customHeadRender: (columnMeta, handleToggleColumn) => {
                    return (
                        <th key={columnMeta.index} style={{ paddingRight: "16px" }}>
                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end" }}>
                                {authUser?.role === 'admin' && <FormDialogAddTemplates
                                    create={props.create}
                                    refresh={refresh}
                                    type={type}
                                />}
                            </div>
                        </th>
                    );
                },
                customBodyRender: (value, tableMeta, updateValue) => {
                    console.log(moment(tableMeta.rowData[15]).add(4, 'hours') < moment())
                    return (
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end" }}>

                            {/* {type === 'multiple' && <FormDialogEditTemplates
                                dataTemplates={tableMeta.rowData}
                                update={props.update}
                                type={type}
                            />} */}
                            <FormDialogCloneTemplates
                                dataTemplates={tableMeta.rowData}
                                create={props.create}
                                refresh={refresh}
                            />                            
                            <FormDialogViewTemplates
                                dataTemplates={tableMeta.rowData}
                                refresh={refresh}
                            />
                            {authUser?.role === 'admin' && <FormDialogEditTemplates
                                dataTemplates={tableMeta.rowData}
                                update={props.update}
                                refresh={refresh}
                            />}
                            {authUser?.role === 'admin' && <FormDialogDeleteTemplates
                                dataTemplates={tableMeta.rowData}
                                delete={props.delete}
                                refresh={refresh}
                            />}
                        </div>
                    );
                }
            }
        }
    ];

    const options = {

        elevation: 1,
        search: true,
        filter: false,
        filterType: 'textField',
        responsive: 'standard',
        selectableRows: 'none',
        rowsPerPageOptions: [5, 10, 25],
        serverSide: true,
        viewColumns: false,
        print: false,
        download: false,
        rowsPerPage: rowsPerPage,
        count: props?.meta?.totalDocs || 0,
        page: page,
        textLabels: {
            pagination: {
                next: "Next",
                previous: "previous",
                rowsPerPage: "Page per rows:",
                displayRows: "Page",
            },
        },

        onTableChange: (action, tableState) => {
            debugger;
            switch (action) {
                case 'changePage':
                    handleChangePage(tableState.page)
                    break;

                case 'changeRowsPerPage':
                    handleChangeRowsPerPage(tableState.rowsPerPage)
                    break;

                case 'search':
                    handleSearch(tableState.searchText)
                    break;

                case 'filterChange':
                    handleFilterChange(tableState.filterList[1], tableState.filterList[2])
                    break;

                case 'resetFilters':
                    handleSearch("")
                    break;

                default:
                    break;
            }
        },
    };

    return (

        <MUIDataTable
            // title="Clases"

            data={type === 'single' ? props.alltemplates : props.alltemplates}
            columns={columns}
            options={options}
            style={{ zIndex: 2990 }}
        />

    );
}

const mapStateToProps = state => ({
    alltemplates: state?.templates?.templates,
    meta: state?.templates?.metaClass
})

const mapActionToProps = {
    fetchPagination: actions.Pagination,
    create: actions.create,
    update: actions.update,
    delete: actions.Delete
}

export default connect(mapStateToProps, mapActionToProps)(TemplatesTable);