import React from 'react'
import './style.css'

const Legal = () => {

  return (

    <>
      <div className='container bg-yellow py-4 mb-4 ptop'>

      </div>

      <div className='container'>
        <div className='text-center mb-4'>
          <h3 className='fw-bold'>Legal</h3>
        </div>
        <div className='row mb-5'>
          <div className='col-lg-10 offset-lg-1 col-12 mb-4'>

            <div className='mb-4'>
              <p className='mb-3'>Coming soon....</p>




            </div>



          </div>

        </div>
      </div>

    </>

  )
}

export default Legal
