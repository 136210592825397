import React from "react";
import ReactDOM from 'react-dom/client';
import CookieConsent from "react-cookie-consent";
import { Link, useNavigate, useLocation } from "react-router-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { LayoutProvider } from "./context/LayoutContext";
import { AuthProvider } from "./context/AuthContext";
const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <LayoutProvider>
      <AuthProvider>
        <App />
        {/* <CookieConsent
        containerClasses="px-5"
        contentClasses="pl-5"
          location="bottom"
          buttonText="Accept all cookies"
          cookieName="gdprcookie"
          style={{ background: "#2B373B" }}
          buttonStyle={{ color: "#fff", fontSize: "14px", backgroundColor:'#066466' }}
          expires={150}
          
        >
          <span style={{ fontSize: "14px" }}>Solarscope only uses cookies that are necessary for the proper performance of the website. For more information see our <a style={{textDecoration:'underline',color:'#fff'}} href="/cookies-information">Cookies Policy</a>.</span>
          
        </CookieConsent> */}
      </AuthProvider>
    </LayoutProvider>
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
