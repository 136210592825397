import React, { useEffect, useState } from 'react'
import { connect } from "react-redux";
import { useParams, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import _ from "lodash";
import * as actions from "../../actions/inspections";
import { initialFormStateInspections } from "../../utils/enums";
import { handleFormUploadSchemaEvent } from "../../utils/templates_util";
import { Form } from '@flipbyte/formik-json';
import './style.css'

const EditInspections = ({ inspections, ...props }) => {
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const [existingData, setInspections] = useState(initialFormStateInspections);
  const [errors, setErrors] = useState({});
  const [avatar, setAvatar] = useState(existingData?.image);
  const [serverActionIsInProgress, setServerActionIsInProgress] = useState(false);
  
  const onError = (err) => {
    if (err?.show_message2user) {
      props.refresh();
      // setOpen(false);
      // toast.error(err.message);
    }
  };
  useEffect(() => {
    let inspection_id = location.pathname.split("/").pop();
    const token = location.search.split('=')[1];
    if (token) {
      localStorage.setItem('token', token);
    }
    console.log('inspection_id', inspection_id);
    const onSuccess = (_temp_existingData) => {
      console.log('15 :: inspection :: ', _temp_existingData);

      _temp_existingData.template_schema = updateObjectFieldsForCustomDropFn(_temp_existingData.template_schema);
      setInspections(_temp_existingData);

    }

    props.fetchById(inspection_id, onSuccess, onError);


  }, [location]);
  useEffect(() => {
    debugger;
    console.log('38 :: template data');

  }, [existingData.template_data])

  const updateObjectFieldsForCustomDropFn = (_temp_existingData) => {
    for (var prop in _temp_existingData) {
      //console.log(prop);
      switch (prop) {
        case 'elements':
          _temp_existingData[prop] = updateObjectFieldsForCustomDropFn(_temp_existingData[prop]);
          break;
        case 'fieldType':
          switch (_temp_existingData[prop]) {
            case "file-uploader":
              if (!_temp_existingData.options) {
                _temp_existingData.options = {};
              }
              _temp_existingData.options.onDrop = handleFormUploadSchemaEvent;
              break;
          }
          break;
        default:
          if (_temp_existingData[prop] && _temp_existingData[prop]?.renderer) {
            _temp_existingData[prop] = updateObjectFieldsForCustomDropFn(_temp_existingData[prop]);
          }
          break;
      }
    }
    return _temp_existingData;
  }

  const handleSubmit = (formValues, event) => {

    existingData.template_data = formValues;
    existingData.report_title = (formValues?.page_question_report_title)||(existingData.report_title||"");

    const onSuccess = () => {

      toast.success('Inspections data updated');

    };

    if (existingData.id) {
      props.update(existingData.id, existingData, onSuccess);
    } else {
      props.create(existingData, onSuccess);
    }

  }

  const handleFormBtnEvent = (type) => {
    switch (type) {
      case 'submitted':
        return handleSubmit.bind(this);
        break;
      default:
        return console.log.bind(console, type);
        break;
    }
  }


  return (


    <div className="px-2 mt-3">
      <div className="row">
        <div className="col-lg-4 col-8 mb-4">
          <img src={existingData.image? process.env.REACT_APP_IMG_URL + existingData.image:''} className="avatarprofle me-2" onError={({ currentTarget }) => {
                      currentTarget.onerror = null; // prevents looping
                      currentTarget.src = process.env.REACT_APP_IMG_URL +"uploads/logo.png";
                }} />
        </div>
        <div className="col-lg-8 col-8 mb-4">
          <div className="mb-3">
            <h4>
              {existingData?.title}
            </h4>
            <p>
              {existingData?.description}
            </p>
          </div>
        </div>
        <div className="col-lg-12 col-12 mb-4">
          <Form
            initialValues={existingData?.template_data}
            schema={existingData?.template_schema}
            onChange={handleFormBtnEvent('changed')}
            onSubmit={handleFormBtnEvent('submitted')}
            onError={handleFormBtnEvent('errors')}
          />



        </div>


      </div>
    </div>






  );

}

const mapStateToProps = state => ({
  inspections: state?.inspections?.inspections
})
const mapActionToProps = {
  fetchById: actions.fetchById,
  create: actions.create,
  update: actions.update,
}

export default connect(mapStateToProps, mapActionToProps)(EditInspections);
