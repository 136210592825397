import React, { useContext } from "react";
import { HashRouter, Route, Routes, Navigate, useLocation, BrowserRouter } from "react-router-dom";

// components
import HomeLayout from "./components/Layout/HomeLayout";
import CommonLayout from "./components/Layout/CommonLayout";

// pages
import Login from "./pages/login";
import Register from "./pages/register";
import Home from "./pages/home";
import Howitworks from "./pages/howitworks";
import About from "./pages/about";
import Contact from "./pages/contact";
import Help from "./pages/help";
import Conditions from "./pages/conditions";
import Legal from "./pages/legal";
import Cookies from "./pages/cookies";
import Privacy from "./pages/privacy";
import ClassDetails from "./pages/classdetails";
import OnlineClasses from "./pages/onlineclasses";
import OnlineCourses from "./pages/onlinecourses"
import Verify from "./pages/verify";
import Resetpwd from "./pages/resetpassword";
import Pricing from "./pages/pricing";

// pages common
import Profile from "./pages/common/profile";
import Classes from "./pages/common/classes";
import Inspections from "./pages/inspections/inspections-table";
import Templates from "./pages/templates/templates-table";

import Dashboard from "./pages/common/dashboard";

import Courses from "./pages/common/courses"

import CreateCourse from "./pages/common/create-course";
import Payments from "./pages/commercial/payments";

// pages admin
import Orders from "./pages/admin/subscriptions";
import ClassesAdmin from "./pages/admin/classes"

// pages commercial
import Howworks from "./pages/commercial/howworks";

// pages residential
import Subscriptions from "./pages/residential/subscriptions";
import User from "./pages/admin/user";
import ViewInspections from "./pages/mobile/ViewInspections";
import Landing4EditInspections from "./pages/mobile/Landing4EditInspections";
import EditInspections from "./pages/mobile/EditInspections";
import AddInspections from "./pages/mobile/AddInspections";

// context
import { AuthContext } from "./context/AuthContext";
// redux
import { Provider } from "react-redux";
import { store } from "./store/store";
// toast
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { GoogleOAuthProvider } from '@react-oauth/google';

export default function App() {
  // global
  let { getAuthUser, isAuthenticated } = useContext(AuthContext)
  let authUser = getAuthUser()
  let isAuthentic = isAuthenticated()
  return (
    <Provider store={store}>
      <GoogleOAuthProvider clientId="126701771732-fmve92dmj5m2heh8u90iukn23bekcl6a">

        <ToastContainer
        limit={1} closeButton={null} pauseOnHover={false} closeOnClick={false}
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
         
          rtl={false}
          pauseOnFocusLoss={true}
          draggable={false}
          
        />

        <BrowserRouter>
          <Routes>

            <Route path="/" >
              <Route index element={<Login />} />
              <Route path="/how-it-works" element={<Howitworks />} />
              <Route path="/about" element={<About />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/help" element={<Help />} />
              <Route path="/conditions" element={<Conditions />} />
              <Route path="/legal" element={<Legal />} />
              <Route path="/cookies-information" element={<Cookies />} />
              <Route path="/privacy-policies" element={<Privacy />} />
              <Route path="/class-details/:id" element={<ClassDetails />} />
              <Route path="/onlineclasses" element={<OnlineClasses />} />
              <Route path="/onlinecourses" element={<OnlineCourses />} />
              <Route path="/verify" element={<Verify />} />
              <Route path="/reset-password" element={<Resetpwd />} />
              <Route path="/Pricing" element={<Pricing />} />
              {/* <Route path='/mobile-inspection-add/:id' element={<AddInspections />} /> */}
              {/* <Route path='/mobile-inspection-view/:id' element={<ViewInspections />} /> */}
              {/* <Route path='/mobile-inspection-edit/:id' element={<Landing4EditInspections />} /> */}
              {/* <Route path='/mobile-inspection-edit-old/:id' element={<EditInspections />} /> */}
              {/* <Route path='/mobile-inspection-edit-new/:id' element={<Landing4EditInspections />} /> */}
              <Route path='/mobile-inspection-add/:id' element={<Landing4EditInspections />} />
              <Route path='/mobile-inspection-edit/:id' element={<Landing4EditInspections />} />
              <Route path='/mobile-inspection-view/:id' element={<Landing4EditInspections />} />

            </Route>

            <Route path="/residential" element={<ProtectedRoute role="residential"><CommonLayout /></ProtectedRoute>}>
              <Route index element={<Home />} />
              <Route index path="profile" element={<Profile />} />
              <Route path="dashboard" element={<Dashboard />} />
              <Route path="templates" element={<Templates />} />
              <Route path="inspections" element={<Inspections />} />
              <Route path="subscriptions" element={<Subscriptions />} />
              <Route path="ended-subscriptions" element={<Subscriptions />} />
              <Route path="Pricing" element={<Pricing />} />
            </Route>
            <Route path="/commercial" element={<ProtectedRoute role="commercial"><CommonLayout /></ProtectedRoute>}>
              <Route index element={<Home />} />
              <Route index path="profile" element={<Profile />} />
              <Route path="dashboard" element={<Dashboard />} />
              <Route path="templates" element={<Templates />} />
              <Route path="inspections" element={<Inspections />} />
              <Route path="subscriptions" element={<Subscriptions />} />
              <Route path="ended-subscriptions" element={<Subscriptions />} />
              <Route path="how-does-it-works" element={<Howworks />} />
              <Route path="payments" element={<Payments />} />
            </Route>
            <Route path="/admin" element={<ProtectedRoute role="admin"><CommonLayout /></ProtectedRoute>}>
              <Route index element={<Home />} />
              <Route index path="profile" element={<Profile />} />
              <Route path="dashboard" element={<Dashboard />} />
              <Route path="templates" element={<Templates />} />
              <Route path="inspections" element={<Inspections />} />
              <Route index path="orders" element={<Orders />} />
              <Route path="commercials" element={<User />} />
              <Route path="residentials" element={<User />} />
              <Route path="classes" element={<ClassesAdmin />} />
              <Route path="subscriptions" element={<Subscriptions />} />
              <Route path="ended-subscriptions" element={<Subscriptions />} />
              <Route path="how-does-it-works" element={<Howworks />} />
              <Route path="payments" element={<Payments />} />
            </Route>

            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />

          </Routes>
        </BrowserRouter>
      </GoogleOAuthProvider>
    </Provider>
  );


  function ProtectedRoute(data) {

    isAuthentic = isAuthenticated()
    authUser = getAuthUser()
    let location = useLocation();

    if (!isAuthentic) {
      return <Navigate to="/" state={{ from: location }} replace />;
    }

    if (authUser.role === data.role) {
      return data.children;
    } else {
      return <Navigate to="/" state={{ from: location }} replace />;
    }


  }


}
