import React from 'react'

const Howitworks = () => {

  return (
    <>
    <div className='container bg-yellow py-4 mb-4'>

    </div>

    <div className='container'>
        <div className='text-center mb-4'>
            <h3 className='fw-bold'>Así funciona</h3>
        </div>
        <div className='row mb-5'>
            <div className='col-lg-10 offset-lg-1 col-12 mb-4'>
            <section id="services" className="services py-1">

    <div className="row gy-4" data-aos="fade-up" data-aos-delay={100}>
      <div className="col-lg-4 col-md-6 ">
        <div className="service-item  position-relative">
          <div className="icon">
            <i className="bi bi-activity" />
          </div>
          <h3>Nesciunt Mete</h3>
          <p>
            Provident nihil minus qui consequatur non omnis maiores. Eos
            accusantium minus dolores iure perferendis tempore et consequatur.
          </p>
        
        </div>
      </div>
      {/* End Service Item */}
      <div className="col-lg-4 col-md-6">
        <div className="service-item position-relative">
          <div className="icon">
            <i className="bi bi-broadcast" />
          </div>
          <h3>Eosle Commodi</h3>
          <p>
            Ut autem aut autem non a. Sint sint sit facilis nam iusto sint.
            Libero corrupti neque eum hic non ut nesciunt dolorem.
          </p>
       
        </div>
      </div>
      {/* End Service Item */}
      <div className="col-lg-4 col-md-6">
        <div className="service-item position-relative">
          <div className="icon">
            <i className="bi bi-easel" />
          </div>
          <h3>Ledo Markt</h3>
          <p>
            Ut excepturi voluptatem nisi sed. Quidem fuga consequatur. Minus ea
            aut. Vel qui id voluptas adipisci eos earum corrupti.
          </p>
        
        </div>
      </div>
      {/* End Service Item */}
      <div className="col-lg-4 col-md-6">
        <div className="service-item position-relative">
          <div className="icon">
            <i className="bi bi-bounding-box-circles" />
          </div>
          <h3>Asperiores Commodit</h3>
          <p>
            Non et temporibus minus omnis sed dolor esse consequatur. Cupiditate
            sed error ea fuga sit provident adipisci neque.
          </p>
          
        </div>
      </div>
      {/* End Service Item */}
      <div className="col-lg-4 col-md-6">
        <div className="service-item position-relative">
          <div className="icon">
            <i className="bi bi-calendar4-week" />
          </div>
          <h3>Velit Doloremque</h3>
          <p>
            Cumque et suscipit saepe. Est maiores autem enim facilis ut aut
            ipsam corporis aut. Sed animi at autem alias eius labore.
          </p>
       
        </div>
      </div>
      {/* End Service Item */}
      <div className="col-lg-4 col-md-6">
        <div className="service-item position-relative">
          <div className="icon">
            <i className="bi bi-chat-square-text" />
          </div>
          <h3>Dolori Architecto</h3>
          <p>
            Hic molestias ea quibusdam eos. Fugiat enim doloremque aut neque non
            et debitis iure. Corrupti recusandae ducimus enim.
          </p>
          
        </div>
      </div>
      {/* End Service Item */}
    </div>

</section>

            </div>

        </div>
    </div>

</>

  )
}

export default Howitworks
