import React, { useState, useContext } from 'react'
import "./style.css"
import { AuthContext } from '../../../context/AuthContext'
import API from '../../../utils/api'
import Swal from 'sweetalert2'
import countries from '../../../components/Header/countries'
import { AccountCircle } from '@mui/icons-material'

const Profile = () => {
    const { getAuthUser } = useContext(AuthContext)
    const authUser = getAuthUser()

    const [firstname, setFirstname] = useState(authUser?.firstname);
    const [lastname, setLastname] = useState(authUser?.lastname);
    const [email, setEmail] = useState(authUser?.email);
    const [mobile, setMobile] = useState(authUser?.mobile);
    const [birthday, setBirthday] = useState(authUser?.birthday?authUser?.birthday:new Date().toISOString().split("T")[0]);
    const [subjects, setSubjects] = useState(authUser?.subjects);
    const [experience, setExperience] = useState(authUser?.experience);
    const [address, setAddress] = useState(authUser?.address);
    const [description, setDescription] = useState(authUser?.description);
    const [password, setPassword] = useState('');
    const [confirmpassword, setConfirmpassword] = useState('');
    const [avatar, setAvatar] = useState(authUser?.image);

    const handleInputChangeForFileType = event => {

        var formdata = new FormData();
        formdata.append("file", event.target.files[0]);
        formdata.append("documentId", Date.now());
        API.utility().upload(formdata)
            .then(res => {
                console.log(res.data)
                setAvatar(res.data.data.path)
            })

    }

    const updateProfile = () => {
        API.user().update(authUser.id, {
            firstname: firstname,
            lastname: lastname,
            birthday: birthday,
            mobile: mobile,
            image: avatar,
            address: address,
            experience: experience,
            update: true

        })
            .then(function (response) {
                localStorage.setItem('authUser', JSON.stringify(response.data))

                Swal.fire({
                    title: 'Yay!',
                    text: 'Profile updated',
                    icon: 'success',
                    showConfirmButton: false,
                    timer: 2000
                })
                setTimeout(() => { window.location.reload(false); }, 2000);

            })

    }

    return (
        <>
            <div className='container ptop'>
                <div className='text-center mb-4'>
                    <h3 className='fw-bold'>Profile</h3>
                </div>
                <div className='row mb-5'>
                    <div className='col-lg-8 offset-lg-2 col-12 border  border-green mb-4'>
                        <div className='border-bottom-green mb-4 py-4 px-lg-5'>
                            <h5>Personal Details</h5>
                            {/* <p className='mb-0'>Para validar tu perfil es necesario completar la siguiente información</p> */}
                        </div>

                        
                        <form className='p-lg-5' onSubmit={(e) => { e.preventDefault(); updateProfile() }}>
                        <div className="row">
                                <div className="col-lg-12 col-12 text-center">
                                    
                                    <img src={avatar?(process.env.REACT_APP_IMG_URL + avatar):''} className="avatarprofle1 me-2 " 				onError={({ currentTarget }) => {
                      currentTarget.onerror = null; // prevents looping
                      currentTarget.src = process.env.REACT_APP_IMG_URL +"uploads/user.jpg";
                }}
/>
                                    <br />
                                    <input type="file" id="file" className='d-none' onChange={handleInputChangeForFileType} />
                                   
                                    <label htmlFor="file" className='btn btn-light mt-2'>Upload  company logo</label>
                                    <br />
                                    <small>Logo will be visible on your reports.</small>
                                </div>

                            </div>
                            <div className="row mb-4">
                                <div className="col-lg-12 col-12">

                                    <div className="mb-3">
                                        <label className="form-label fw-bold">First Name</label>
                                        <input onChange={e => setFirstname(e.currentTarget.value)} value={firstname} type="text" className="form-control border-green rounded-0 mb-3" required />
                                    </div>

                                    <div className="mb-3">
                                        <label className="form-label fw-bold">Last Name</label>
                                        <input onChange={e => setLastname(e.currentTarget.value)} value={lastname} type="text" className="form-control border-green rounded-0 mb-3" required />
                                    </div>

                                    {/* <div className="mb-3">
                                        <label className="form-label fw-bold">Fecha de nacimiento </label>
                                        <input onChange={e => setBirthday(e.currentTarget.value)} value={birthday} type="date" className="form-control border-green rounded-0 mb-3" />
                                    </div> */}

                                    {/* <div className="mb-3">
                                        <label className="form-label fw-bold">Country</label>
                                       
                                        <select onChange={e => setAddress(e.currentTarget.value)} value={address} className="form-select border-green rounded-0 mb-3">
            <option disabled selected value>Elegir</option>
            {countries.map(e =>
            
              <option key={e.code} value={e.code}>{e.country}</option>
            )};
          </select>
                                    </div> */}

                                    {/* <div className="mb-3">
                                        <label className="form-label fw-bold">Teléfono móvil</label>
                                        <input onChange={e => setMobile(e.currentTarget.value)} value={mobile} type="tel" className="form-control border-green rounded-0 mb-3" />
                                    </div> */}

                                    <div className="mb-3">
                                        <label className="form-label fw-bold">Email</label>
                                        <input disabled value={email} type="text" className="form-control border-green rounded-0 mb-3" required />
                                    </div>

                                  {authUser.role==='commercial' &&  <div className="mb-3">
                                        <label className="form-label fw-bold">Professional description </label>
                                        <textarea rows={5} onChange={e => setExperience(e.currentTarget.value)} value={experience} type="text" className="form-control border-green rounded-0 mb-3" required />
                                    </div>}



                                </div>

                            </div>

                            <div className="row">
                                <div className="col-lg-12 col-12 text-center">

                                    <button type='submit' className='btn btn-primary'>Save</button>
                                </div>

                            </div>
                        </form>
                    </div>

                </div>
            </div>

        </>
    )
}

export default Profile
