import React from "react";

import TemplatesTable from "./table/TemplatesTable"



const Templates = ({ templates, ...props }) => {
    return (
        <React.Fragment>
            <div className='container ptop px-3'>
                <div className="row mb-4">
                <TemplatesTable />
                </div>
                
            </div>

        </React.Fragment>
    );
}


export default Templates;