import React, { useState, useEffect, useCallback, useContext } from 'react';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import _ from 'lodash'
import './style.css'
import { Link } from 'react-router-dom';
import { Avatar } from '@mui/material';
import moment from 'moment/min/moment-with-locales';

const CourseCard = (props) => {
    

    let settings = {
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        pauseOnHover: true,
        responsive: [{

            breakpoint: 1024,
            settings: {
                slidesToShow: 4,
            }
        },
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 3,
            }
        },
        {
            breakpoint: 600,
            settings: {
                slidesToShow: 2,
            }
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
            }
        },
        ]
    };


    useEffect(() => {

    }, []);



    return (
        <>
            <Slider {...settings}>
                {props?.data?.map((item, index) => (

                    <div className="card slick-card">
                        <img className="card-img-top slick-image" src={process.env.REACT_APP_IMG_URL + item.image} alt="Card image" />
                        <div className="card-body ">
                            <div className='row'>
                                <div className='col-lg-12 col-12 '>

                                    <Avatar className='float-start me-2 mt-2' sx={{ width: 56, height: 56 }} alt={item.commercial.firstname + ' ' + item.commercial.lastname} src={process.env.REACT_APP_IMG_URL + item.commercial.image} />


                                    <strong className='small'>{item.title}</strong>
                                    <p className="small">{item.commercial.firstname + ' ' + item.commercial.lastname}</p>
                                    {item.type === 'single' ? <p className="small">{moment(item.datetime).local().locale('es').format('lll')}</p> : <>{moment(item.lessons[0].datetime).local().locale('es').format('lll')}</>}
                                </div>
                            </div>

                            <div className='text-center mt-2'>
                                <Link to={{
                                    pathname: '/class-details/' + item._id,
                                    hash: "#"+item.title
                                        }}
                                    state={{ data: item }}
                                    className='small'>Ver más</Link>
                            </div>

                        </div>
                    </div>
                ))}
            </Slider>
        </>

    )
}


export default CourseCard
