import React, { useState, useEffect, useContext } from 'react'
import './style.css'
import { useLocation, useParams } from 'react-router-dom'
import moment from 'moment'
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import SavingsIcon from '@mui/icons-material/Savings';
import GroupIcon from '@mui/icons-material/Group';
import { Avatar } from '@mui/material';
import _ from 'lodash'
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import {
    PayPalScriptProvider,
    PayPalButtons,
    usePayPalScriptReducer
} from "@paypal/react-paypal-js";
import * as actions from "../../actions/order";
import * as user from "../../actions/user";
import { connect } from "react-redux";
import { AuthContext } from '../../context/AuthContext';
import Swal from "sweetalert2";
import calendar from './../../images/icons/calendar.png'
import users from './../../images/icons/users.png'
import pig from './../../images/icons/pig.png'
import clock from './../../images/icons/clock.png'
import parse from 'html-react-parser';

const ClassDetails = (props) => {

    let location = useLocation()
    const { getAuthUser, isAuthenticated } = useContext(AuthContext)
    const authUser = getAuthUser()
    let isAuthentic = isAuthenticated()
    const [data, setData] = useState(location?.state?.data ? location?.state?.data : []);
    const [currency, setCurrency] = useState(localStorage.getItem("currency"));
    const [exchangerate, setExchangerate] = useState(localStorage.getItem("exchangerate") ? localStorage.getItem("exchangerate") : 1);
    const [show, setShow] = useState(false);
    const [checkoutClass, setCheckoutClass] = useState({});
    const [amount, setAmount] = useState(0);
    const [refreshPaypal, setRefreshPaypal] = useState(false);
    const [loading, setLoading] = useState(true);
    const [showOverlay, setShowOverlay] = useState(false);
    useEffect(() => {
        const onSuccess = (response) => {
            setLoading(false)
        }
        if (data.type === 'single') {
            props.getCount(
                {
                    class: data?._id ? data?._id : data?.id,
                    residential: authUser?.id,
                }, onSuccess
            )
        } else {
            props.getCount(
                {
                    course: data?.id,
                    residential: authUser?.id,
                }, onSuccess
            )
        }

    }, [data])
    // useEffect(() => {

    //    console.log('props.metaCount',props.metaCount)

    // }, [props.metaCount])

    const handleClose = () => {
        setShow(false);
        setShowOverlay(false)
        setTimeout(
            () => setRefreshPaypal(false),
            300
        );
    }
    const handleShow = (amt, lesson) => {
        console.log(lesson)
        if (lesson) {
            setCheckoutClass(lesson)
        }
        setAmount(amt)
        setShowOverlay(true)
        setShow(true);
        setTimeout(
            () => setShowOverlay(false), 
            1000
          );
        setTimeout(
            () => setRefreshPaypal(true),
            100
        );
    }

    const ButtonWrapper = ({ currency, showSpinner }) => {
        // usePayPalScriptReducer can be use only inside children of PayPalScriptProviders
        // This is the main reason to wrap the PayPalButtons in a new component
        const [{ options, isPending }, dispatch] = usePayPalScriptReducer();

        useEffect(() => {
            dispatch({
                type: "resetOptions",
                value: {
                    ...options,
                    currency: currency,
                },
            });
        }, [currency, showSpinner]);


        return (<>
            {(showSpinner && isPending) && <div className="spinner" />}
            <PayPalButtons
                style={{ "layout": "vertical", label: 'pay' }}
                disabled={false}
                forceReRender={[amount, currency]}
                fundingSource={undefined}
                createOrder={(data, actions) => {
                    return actions.order
                        .create({
                            purchase_units: [
                                {
                                    amount: {
                                        currency_code: currency,
                                        value: amount,
                                    },
                                },
                            ],
                            application_context: {
                                shipping_preference: "NO_SHIPPING"
                            },
                            payer: {
                                birth_date: moment(authUser.birthday).format('yyyy-MM-DD'),
                                email_address: authUser.email,
                                phone: {
                                    phone_number: {
                                        national_number: authUser.mobile ? authUser.mobile : '4543433243',
                                    }
                                },
                                name: {
                                    given_name: authUser.firstname,
                                    surname: authUser.lastname,
                                },
                                address: {
                                    address_line_1: '123 ABC Street',
                                    address_line_2: 'Apt 2',
                                    admin_area_2: 'San Jose',
                                    admin_area_1: 'CA',
                                    postal_code: '95121',
                                    country_code: 'US',
                                },
                            },
                        })
                        .then((orderId) => {
                            // Your code here after create the order
                            console.log('paypal-orderId', orderId)
                            return orderId;
                        });
                }}
                onApprove={function (res, actions) {
                    return actions.order.capture().then(function () {
                        console.log('paypal-approve', res, actions)

                        let orderObj = {
                            orderID: res.orderID,
                            payerID: res.payerID,
                            paymentSource: res.paymentSource,
                            residential: authUser?.id,
                            commercial: data?.commercial?.id,
                            class: data.type === 'single' ? data._id : checkoutClass.id,
                            course: data.type === 'multiple' ? data.id : '',
                            amount: amount,
                            status: "success",
                            note: "",
                            classdatetime: data.type === 'single' ? data.datetime : checkoutClass.datetime
                        }
                        const onSuccess = (response) => {
                            Swal.fire("Gracias por registraste a la clase", data.type === 'single' ? data.title : checkoutClass.title, 'success',)
                            console.log('order-approve', response)
                            if (data.type === 'single') {
                                props.getCount(
                                    {
                                        class: data?._id,
                                        residential: authUser?.id,
                                    },
                                )
                            } else {
                                props.getCount(
                                    {
                                        course: data.id,
                                        residential: authUser?.id,
                                    },
                                )
                            }
                            handleClose()
                            setCheckoutClass({})

                        }
                        console.log('order-obj', orderObj)
                        props.create(orderObj, onSuccess)
                        // {
                        //     "accelerated": false,
                        //     "orderID": "969028185Y496471U",
                        //     "payerID": "YA73YD45KLZKQ",
                        //     "paymentID": null,
                        //     "billingToken": null,
                        //     "facilitatorAccessToken": "A21AAKqQ8-ot-jpxkzTmwamKXlfcKnPoNnyl6sspyju4EJPq8UFgWF2hFBPHAV-tLwu4GVdObmLlLH5Am9Jl4XXANN_aLiEqQ",
                        //     "paymentSource": "paypal"
                        // }
                        // Your code here after capture the order
                    });
                }}

            />
        </>
        );
    }

    return (

        <>
            <div className='container'>
                <div className='text-center mb-4'>
                    <h3 className='fw-bold'>{data.title}</h3>
                </div>
                <div className='row '>
                    <div className={`col-12 mb-4 ${data.type === 'single' ? "col-lg-9" : "col-lg-8 offset-lg-2"}`}>

                        <div className='mb-4'>
                            <img className="w-100 mb-4" style={{maxHeight: 400,backgroundColor: '#eee',objectFit:'contain' }} src={process.env.REACT_APP_IMG_URL + data.image} />

                        </div>

                        {data.type === 'multiple' && <div className='mb-5'>
                            <h5>Resumen del curso</h5>
                            {
                                data?.lessons?.map((item, index) => <div className='row'>
                                    <div className='col-lg-4'>
                                        <p className='my-1 fw-light'>Clase {index + 1}: {item.title}</p>
                                    </div>

                                    <div className='col-lg-6 bg-green'>
                                        <p className='my-1 fw-light'><CalendarTodayIcon fontSize='12' className='me-2' /> {moment(item.datetime).local().format('DD/MM/yyyy')} <AccessTimeIcon className='me-2 ms-2' /> {moment(item.datetime).local().format('HH:mm')} <SavingsIcon className='me-2 ms-2' />{currency === 'USD' ? '$' : '€'} {_.round((data.price * exchangerate), 2)}  <GroupIcon className='me-2 ms-2' /> {props.metaCount?.count?.multiple[item.id] ? props.metaCount?.count?.multiple[item.id] : 0}/{data.intake} {props.metaCount?.count?.multiple[item.id]===data.intake?'Full':''}</p>
                                    </div>

                                    <div className='col-lg-2'>
                                        {authUser && authUser?.role==='residential' && (props.metaCount?.count?.multiple[item.id] ? props.metaCount?.count?.multiple[item.id] : 0<data.intake)?(loading  ?
                                            <div className="dot-pulse m-auto mt-3"></div>
                                            : (props.metaCount?.subscriptons?.includes(item.id) ?
                                                <button type='button' className='btn bg-light rounded-5 btn-sm w-100' >inscrito</button>
                                                : <button type='button' className='btn bg-yellow text-white rounded-5 btn-sm w-100' onClick={() => handleShow(data.price, item)}>Inscribirme</button>))
                                            :
                                            <><button type='button' className='btn bg-yellow text-white rounded-5 btn-sm w-100' onClick={()=>props.invokesignin(true)}>Inscribirme</button></>
                                            }

                                    </div>

                                </div>)
                            }

                        </div>}

                        <div className='mb-5'>
                            <h5>¿Qué aprenderás en esta clase?</h5>
                            {/* <p className='mb-4'>{data.description}</p> */}
                            {data.description.length>0 && <div className='htmparser'>{parse(data?.description)}</div>}
                        </div>

                        <div className='mb-5'>
                            <h5>Acerca del profesor</h5>
                            <div className='d-block'>
                                <Avatar className='float-start me-4' sx={{ width: 100, height: 100 }} src={process.env.REACT_APP_IMG_URL + data.commercial.image} />

                                <p className='fw-bold'>{data.commercial.firstname + ' ' + data.commercial.lastname}</p>

                                <p className='mb-4'>{data.commercial.experience}</p>
                            </div>



                        </div>



                    </div>
                    {data.type === 'single' && <div className='col-lg-3 col-12 mb-4'>
                        <div className='w-100 bg-green p-3'>
                            <p className='mb-2 fs-6 fw-light'><img src={calendar} height="20"/>  {moment(data.datetime).local().format('DD/MM/yyyy')}</p>
                            <p className='mb-2 fs-6 fw-light'><img src={clock} height="20"/>  {moment(data.datetime).local().format('HH:mm')}</p>
                            <p className='mb-2 fs-6 fw-light'><img src={pig} height="22"/>  {currency === 'USD' ? '$' : '€'} {_.round((data.price * exchangerate), 2)}</p>
                            <p className='mb-2 fs-6 fw-light'><img src={users} height="18"/>  {props.metaCount?.count?.single ? props.metaCount?.count?.single : 0}/{data.intake} {props.metaCount?.count?.single===data.intake? 'Full':''}</p>
                        </div>
                    
                     {authUser && authUser?.role==='residential' && (props.metaCount?.count?.single ? props.metaCount?.count?.single : 0<data.intake)?  <div className='text-center'>
                            {loading?
                                <div className="dot-pulse m-auto mt-3"></div>
                                : (props.metaCount?.subscriptons?.includes(data._id) ?
                                    <button type='button' className='btn bg-light rounded-5 btn-sm mt-3' >inscrito</button>
                                    : <button type='button' className='btn bg-yellow text-white rounded-5 mt-3' onClick={() => handleShow(data.price)}>Inscribirme</button>)}
                        </div>:<div className='text-center'><button type='button' className='btn bg-yellow text-white rounded-5 btn-sm mt-3' onClick={()=>props.invokesignin(true)}>Inscribirme</button></div>}
                    </div>}

                </div>
            </div>

            <Modal show={show} size="xl" centered={true} onHide={handleClose}>

                <Modal.Body>
                   {showOverlay && <div className='paymnetoverlay text-center'>
                        <div className="spinner-border mb-4 mt-5" role="status">
                            <span className="sr-only"></span>
                        </div>
                        <h2 className='fw-light'>Obteniendo detalles de pago, por favor espere...</h2>
                    </div>}
                    <div className='row p-4'>

                        <div className='col-xl-12 mb-4'>

                            <h4>Datos de facturación</h4>
                        </div>
                        <div className='col-xl-6'>
                            <PayPalScriptProvider
                                options={{
                                    "client-id": "AUvlW4ubczyaiLY0re298l9zFROFM7FEqwQ8pGbMUNZM8e5AQzz-Zq5aZ9sxCa7onfWTog4KBhzJrkC7",
                                    components: "buttons",
                                    currency: "EUR",


                                }}
                            >
                                <ButtonWrapper
                                    currency={currency}
                                    showSpinner={refreshPaypal}
                                    amount={data.price}
                                />
                            </PayPalScriptProvider>
                            <Button variant="secondary btn-lg w-100 mb-3" onClick={handleClose}>
                                Cancel
                            </Button>
                        </div>
                        <div className='col-xl-6'>
                            <div className='border-green p-4'>
                                <h4>Tus clases</h4>
                                <ul>
                                    <li>{data.type === 'single' ? data.title : checkoutClass.title} <span className='float-end'>{currency === 'USD' ? '$' : '€'} {_.round((data.price * exchangerate), 2)}</span> </li>
                                </ul>
                                <hr />
                                <ul className="list-unstyled">
                                    <li className='h4'>Total <span className='float-end'>{currency === 'USD' ? '$' : '€'} {_.round((data.price * exchangerate), 2)}</span> </li>
                                </ul>

                            </div>





                        </div>
                    </div>

                </Modal.Body>


            </Modal>



        </>

    )
}

const mapStateToProps = state => ({
    orders: state?.order?.orders,
    meta: state?.order?.metaOrder,
    metaCount: state?.order?.metaCount,
    showLogin: state?.user?.showLogin,
})

const mapActionToProps = {
    fetchPagination: actions.Pagination,
    create: actions.create,
    update: actions.update,
    delete: actions.Delete,
    getCount: actions.getCount,
    invokesignin: user.invokesignin

}

export default connect(mapStateToProps, mapActionToProps)(ClassDetails);

