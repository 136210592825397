import React, { useState, useContext } from 'react'
import "./style.css"
import Form from 'react-bootstrap/Form';
import API from '../../utils/api';
import Swal from 'sweetalert2';

const Contact = () => {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");

    const sendform = () => {


      let param ={
        name: name,
        email: email,
        message: message
      }

        API.user().sendcontatform(param)
            .then(function (response) {
                if (response.data) {
                    Swal.fire("Yes!", "Your inquiry has been sent.", 'success')
                    setName("")
                    setEmail("")
                    setMessage("")

                } else {
                    Swal.fire("Oops!", "It could not send. Try again.", 'warning')

                }


            })
            .catch(function (error) {
                
                Swal.fire("Oops!", "It could not send. Try again.", 'warning')

            });


    }

    return (
        <>
     
     
            <div className='container ptop'>
                <div className='text-center mb-4'>
                <h3 className='fw-bold'>Contact Us</h3>
                </div>
                <div className='row mb-5'>
                    <div className='col-lg-8 offset-lg-2 col-12 border p-5 border-green mb-4'>
                        
                        <form onSubmit={(e) => { e.preventDefault(); sendform() }}>
                            <div className="row mb-4">
                              
                                <div className="col-lg-12 col-12">
                                    
                                    <input type="text" onChange={e => setName(e.currentTarget.value)} value={name}  className="form-control border-green rounded-0" placeholder="Name" required/>
                                </div>
                            </div>
                            <div className="row mb-4">
                             
                                <div className="col-lg-12 col-12">
                                    <input type="text" onChange={e => setEmail(e.currentTarget.value)} value={email}  className="form-control border-green rounded-0" placeholder="Email" required/>
                                </div>
                            </div>
                            <div className="row">
                               
                                <div className="col-lg-12 col-12">
                                    <textarea type="text" onChange={e => setMessage(e.currentTarget.value)} value={message}  className="form-control border-green rounded-0 mb-4" placeholder="Message" rows={3} required/>

                                    <Form.Check type="checkbox" className='mb-4'>
                                        <Form.Check.Input type="checkbox" isValid />
                                        <Form.Check.Label className='text-secondary'>Accept terms & services</Form.Check.Label>
                                      
                                    </Form.Check>
                                    <div className='text-center'>
                                    <button type='submit' className='btn btn-primary  px-5'>Send message</button>
                                    </div>
                                    
                                </div>
                            </div>
                        </form>
                    </div>
                 
                </div>
            </div>
       
        </>
    )
}

export default Contact
