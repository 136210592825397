import React, { useState, useContext, useEffect } from 'react'
import { AuthContext } from '../../context/AuthContext'
import { loadStripe } from '@stripe/stripe-js';
import { Elements, useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js';
import API from '../../utils/api';
import Swal from 'sweetalert2';
import Checkout from '../checkout';
import Switch, { SwitchProps } from '@mui/material/Switch';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import moment from 'moment';
import { connect } from "react-redux";
import * as user from "../../actions/user";
import { Link } from 'react-router-dom';

const Pricing = (props) => {

    const { getAuthUser } = useContext(AuthContext)
    let authUser = getAuthUser()
    const [stripeOptions, setStripeOptions] = useState({});
    const [orderID, setOrderID] = useState('');
    const [loading, setLoading] = useState(false);
    const [isCheckout, setIscheckout] = useState(false);
    const [isannual, setIsannual] = useState(false);

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };
    useEffect(() => {
        scrollToTop()
    }, []);

    const getReferenceToken = async () => {
        console.log(authUser)
        if(!authUser){
            props.invokesignin(true)
            return
        }
        setLoading(true);
        let amount = isannual ? 529 : 49;

        let param = {
            amount: Math.round(amount * 100),
            currency: 'USD',
            automatic_payment_methods: {
                enabled: true,
            },
            metadata: {
                'name': authUser.firstname + ' ' + authUser.lastname,
                'email': authUser.email,
                'mobile': authUser.mobile
            }
        }


        API.utility().createPaymentIntent(param)
            .then(async (res) => {

                if (res.data.statusCode === 400) {
                    Swal.fire('Oops!', res.data.raw.message, 'warning')
                    setLoading(false);
                    return;
                }

                // console.log(res.data, 'ooo', res?.data?.split('pi_').pop().split('_secret')[0])
                setOrderID(res?.data?.split('pi_').pop().split('_secret')[0])
                let options = {
                    clientSecret: res.data
                }
                setStripeOptions(options)
                setLoading(false);
                setIscheckout(true)
                //history.push("/checkout-one", options)
                //  window.scrollTo(0, 170);


                //  await initializePaymentSheet(res.data,res?.data?.split('pi_').pop().split('_secret')[0]);
            })
            .catch(err => console.log(err))


    };
    const paymentStatus = (isSuccess, result) => {
        console.log(isSuccess, result)
        // {
        //     "id": "pi_3OA8sZAuQuURJsCe0mzV4Mw5",
        //     "object": "payment_intent",
        //     "amount": 2900,
        //     "amount_details": {
        //         "tip": {}
        //     },
        //     "automatic_payment_methods": {
        //         "allow_redirects": "always",
        //         "enabled": true
        //     },
        //     "canceled_at": null,
        //     "cancellation_reason": null,
        //     "capture_method": "automatic",
        //     "client_secret": "pi_3OA8sZAuQuURJsCe0mzV4Mw5_secret_yTeFJP3mbW0Lv3IygAzFTuFVS",
        //     "confirmation_method": "automatic",
        //     "created": 1699438859,
        //     "currency": "gbp",
        //     "description": null,
        //     "last_payment_error": null,
        //     "livemode": false,
        //     "next_action": null,
        //     "payment_method": "pm_1OA8t4AuQuURJsCeBydWiQ4H",
        //     "payment_method_configuration_details": null,
        //     "payment_method_types": [
        //         "card"
        //     ],
        //     "processing": null,
        //     "receipt_email": null,
        //     "setup_future_usage": null,
        //     "shipping": null,
        //     "source": null,
        //     "status": "succeeded"
        // }
        if (isSuccess) {
            API.order().create({
                customer_name: authUser.firstname + ' ' + authUser.lastname,
                customer_email: authUser.email,
                customer_mobile: authUser.mobile,
                customer_id: authUser.id,
                transaction_no: orderID,
                transaction_details: result,
                plan: isannual ? 'yearly' : 'monthly',
                transaction_amount: (result.paymentIntent.amount / 100),
                transaction_status: "success",
                note: "",
                planexpiry: authUser?.planexpiry
            })
                .then(response => {
                    let neworder = response.data.neworder;
                    let customer = response.data.customer;
                    //console.log('22222>>', response.data)
                    setLoading(false);
                    setIscheckout(false)

                    localStorage.setItem(
                        'authUser',
                        JSON.stringify(customer)
                    );
                    authUser = customer;
                    Swal.fire('Payment successful.!', "You have completed the payment of USD " + result.paymentIntent.amount / 100, 'success');
                })
        }

    }


    return (

        <>
            <div className='container-fluid ptop'>
                {!isCheckout ? <div className="container px-0">
                    <div class="pricing-header p-3 pb-md-4 mx-auto text-center">
                        <h1 class="display-4 fw-normal">Pricing</h1>
                        <p class="fs-5 text-muted">Industry-leading solar panel cleaning reporting systems start here. For individuals and organizations
looking to digitise their reporting system.
                        </p>
                        {authUser && authUser?.plantype != 'free' && <p>Subscribed to &nbsp;<span className='badge bg-dark ml-1 text-capitalize'>{authUser?.plantype}</span> plan valid till &nbsp;
                            <span className='badge bg-dark ml-1'>{moment(authUser?.planexpiry).format("ll")}</span></p>}
                        <div className='my-3 offset-lg-4'>
                            <Stack direction="row" spacing={1} alignItems="center">
                                <p class="fs-5">Billed Monthly</p>
                                <Switch size='medium' checked={isannual}
                                    onChange={(event) => {
                                        setIsannual(event.target.checked)
                                    }} />
                                <p class="fs-5">Billed Annually (Save 10%){isannual}</p>
                            </Stack>
                        </div>

                    </div>
                    <main>

                        <div class="row row-cols-1 row-cols-md-3 offset-md-3 mb-3 text-center">
                            <div class="col">
                                <div class="card mb-4 rounded-3 shadow-sm">
                                    <div class="card-header py-3">
                                        <h4 class="my-0 fw-normal">Free</h4>
                                    </div>
                                    <div class="card-body">
                                        <h1 class="card-title pricing-card-title">$0<small class="text-muted fw-light">/mo</small></h1>
                                        <ul class="list-unstyled mt-3 mb-4">
                                            <li>Residential template only</li>
                                            <li>Limited to 4 inspections per month</li>
                                           
                                        </ul>
                                        <Link to={"/" + authUser?.role + "/inspections"} class="w-100 btn btn-lg btn-outline-primary" onClick={null}>Get started</Link>
                                    </div>
                                </div>
                            </div>

                            <div class="col">
                                <div class="card mb-4 rounded-3 shadow-sm border-primary">
                                    <div class="card-header py-3 text-white bg-primary border-primary">
                                        <h4 class="my-0 fw-normal text-white">Premium</h4>
                                    </div>
                                    <div class="card-body">
                                        <h1 class="card-title pricing-card-title">{isannual ? '$529/yr' : '$49/mo'}<small class="text-muted fw-light"></small></h1>
                                        <ul class="list-unstyled mt-3 mb-4">
                                            <li>Unlimited access to:</li>
                                            <li>✅ Residential template</li>
                                                <li>✅ Commercial template</li>
                                                <li>✅ Utility-scale template</li>
                                                <li>✅ Chemical clean template</li>
                                            <li>Unlimited Inspections</li>
                                          
                                        </ul>
                                        {!loading ? <button type="button" onClick={() => getReferenceToken()} class="w-100 btn btn-lg btn-primary">Get started</button>
                                            :
                                            <button class="btn btn-primary btn-lg w-100" type="button" disabled>
                                                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                &nbsp;Loading...
                                            </button>}

                                    </div>
                                </div>
                            </div>
                        </div>


                    </main>
                </div>
                    : <>
                        <Checkout stripeOptions={stripeOptions} paymentStatus={paymentStatus} isannual={isannual} />

                    </>}
            </div>

        </>

    )
}


const mapStateToProps = state => ({

    showLogin: state?.user?.showLogin,
})

const mapActionToProps = {

    invokesignin: user.invokesignin

}

export default connect(mapStateToProps, mapActionToProps)(Pricing);





