import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useLocation, useSearchParams } from "react-router-dom";
import API from '../../utils/api';
import Swal from "sweetalert2";
import { useJwt } from "react-jwt";

const Resetpwd = () => {
  const navigate = useNavigate()
  let location = useLocation();
  let [searchParams, setSearchParams] = useSearchParams();
  let trackernum = searchParams.get("trackernum")
  const [loading, setLoading] = useState(true);
  const [password, setPassword] = useState('');
  const [confirmpassword, setConfirmpassword] = useState('');
  const { decodedToken, isExpired, reEvaluateToken } = useJwt(trackernum);
  const exp = decodedToken?.exp
  const sub = decodedToken?.sub
  console.log('1-->',sub, isExpired)

  useEffect(() => {
  // reEvaluateToken(searchParams.get("trackernum"))
  // console.log('2-->',decodedToken, isExpired)
   // if (sub) {
      if(isExpired){
        setLoading(true)
        Swal.fire("Sorry!", "Link has been expired, please try again.", 'warning').then((result) => {
  
          if (result.isConfirmed) {
            navigate('/')
          }
        })
      }else{
        setLoading(false)
      }
      // API.user().fetchById(decodedToken?.sub)
      //   .then(res => {
      //     console.log(res.status === 200)
      //     if (res.status === 200) {
      //       setLoading(false)
      //     } else {
      //        navigate('/')
      //     }
      //   })
      //   .catch(err => console.log(err))
   // } else {
      // navigate('/')
   // }
  }, [sub])


  const resetpwd = () => {
    API.user().update(sub, { password: password, fcmtoken: "" })
      .then(res => {
        console.log(res.status === 200)
        if (res.status === 200) {
          Swal.fire("Congrats!", "Your password has been updated", 'success').then((result) => {

            if (result.isConfirmed) {
              navigate('/')
            }
          })

        } else {
          Swal.fire("Sorry!", "Something went wrong, please try again later.", 'warning').then((result) => {

            if (result.isConfirmed) {
              navigate('/')
            }
          })
        }
      })
      .catch(err => console.log(err))

  };

  return (
    <>
       <div className='container bg-yellow py-4 mb-4 ptop'>

</div>


      <div className='container'>

        <div className='row mb-5'>
          <div className='col-lg-6 offset-lg-3 col-12 mb-4'>

            {loading ?
              <div className='mb-4 text-center'>
                <div className="spinner-border text-warning my-4" role="status">
                  <span className="sr-only"></span>
                </div>
                <h4 className='text-green'></h4>
                <hr className="bg-success border-1 border-top border-success" />
                <p>Please wait, checking.</p>
              </div> :
              <div className='my-4 text-center'>
                <h4 className='text-green'>Reset your password</h4>
                <form onSubmit={(e) => { e.preventDefault(); resetpwd() }}>
                  <input onChange={e => setPassword(e.currentTarget.value)} type="password" minLength={6} className="form-control border-green rounded-0 mb-3" placeholder="New Password" required />
                  <input onChange={e => setConfirmpassword(e.currentTarget.value)} type="password" minLength={6} className="form-control border-green rounded-0 mb-3" placeholder="Verify passwords" pattern={"^" + password} required />
                  <button type="submit" className="btn btn-success btn-lg rounded-0 bg-yellow text-white fw-bold px-5 mb-2">Submit</button>
                </form>
                {/* <Link className='btn btn-lg rounded-0 bg-yellow text-white fw-bold px-5 mt-4' to="/">Go To Home</Link> */}
              </div>
            }
          </div>

        </div>
      </div>

    </>

  )
}

export default Resetpwd
