import React, { useState, useContext, useEffect } from "react";
import { AuthContext } from "../../context/AuthContext";
import logo from '../../images/logo.png'
import { Link, useNavigate, useLocation } from "react-router-dom";
import Modal from 'react-bootstrap/Modal';
import "./style.css";

import Form from 'react-bootstrap/Form';
import { IoCloseOutline, IoLogoFacebook, IoLogoGoogle } from "react-icons/io5";
import * as user from "../../actions/user";
import { connect } from "react-redux";
import API from '../../utils/api';
import Swal from "sweetalert2";
import Reaptcha from 'reaptcha';
import Dropdown from 'react-bootstrap/Dropdown';
import Image from 'react-bootstrap/Image'

import { useGoogleLogin } from '@react-oauth/google';
import { hasGrantedAllScopesGoogle, hasGrantedAnyScopeGoogle } from '@react-oauth/google';
import axios from "axios";

function Register(props) {

  const { logout, getAuthUser, login, sociallogin, isAuthenticated } = useContext(AuthContext)
  const authUser = getAuthUser()
  const navigate = useNavigate()
  let location = useLocation();

  const [role, setRole] = useState('residential');
  const [approved, setApproved] = useState(false);
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [birthday, setBirthday] = useState(new Date());
  const [subjects, setSubjects] = useState('');
  const [experience, setExperience] = useState('');
  const [password, setPassword] = useState('');
  const [confirmpassword, setConfirmpassword] = useState('');
  const [captcha, setCaptcha] = useState('');
  const [termscheckbox, setTermscheckbox] = useState(false);
  const [isresetpwd, setIsresetpwd] = useState(false);
  const [navcollapse, setNavcollapse] = useState(false);
  const [provider, setProvider] = useState('');
  const [whichIssueFoundInOldSystem, setWhichIssueFoundInOldSystem] = useState("");
  const [whichFeatureAppealsInOldSystem, setWhichFeatureAppealsInOldSystem] = useState("");

  var [emailValue, setEmailValue] = useState("");
  var [passwordValue, setPasswordValue] = useState("");
  const [isMobile, setIsMobile] = useState(window.innerWidth < 992);
  const [isScrolled, setIsScrolled] = useState(false);
  var [loading, setLoading] = useState(false);
  var [errorMessage, setErrorMessage] = useState(null);
  const [showLogin, setShowLogin] = useState(false);
  const [showRegistration, setShowRegistration] = useState(false);
  const loginHide = () => props.invokesignin(false);
  const loginShow = () => {
    setIsresetpwd(false); props.invokesignin(true); setErrorMessage(null);

  }
  const registrationHide = () => setShowRegistration(false);
  const registrationShow = () => {

    setShowRegistration(true);
  }


  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      const isMobileView = window.innerWidth < 992;

      if (isMobileView) {
        setIsScrolled(scrollTop > 45);
      } else {
      
      }
    };

    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', () => {
      setIsMobile(window.innerWidth < 992);
    });
    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', () => {
        setIsMobile(window.innerWidth < 992);
      });
    };

  }, [])


  const isEmailValid = () => {
    const regexp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regexp.test(email);
  }
  const register = () => {
    setLoading(true);
    /////NO UI for this below??? do we required this
    // if (getAge(birthday) < 15) {
    //   Swal.fire("Oops!", "You have to be over 14 years old.", 'warning')
    //   setLoading(false);
    //   return;
    // }
    if (password !== confirmpassword) {
      Swal.fire("Oops!", "Password does not match.", 'warning')
      setLoading(false);
      return;
    }

    if (!termscheckbox) {
      Swal.fire("Oops!", "I accept the general conditions and privacy policy", 'warning')
      setLoading(false);
      return;
    }

    // if (!captcha) {
    //   Swal.fire("Oops!", "completar la verificación de captcha", 'warning')
    // setLoading(false);
    //   return;
    // }


    API.user().register({
      firstname: firstname,
      lastname: lastname,
      email: email,
      birthday: birthday,
      password: confirmpassword,
      role: role,
      image: 'uploads/user.jpg',
      mobile: '',
      subjects: subjects,
      experience: experience,
      approved: role === 'residential' ? false : false,
      verified: true,
      provider: "generic",
      address: address,
      whichIssueFoundInOldSystem:whichIssueFoundInOldSystem,
      whichFeatureAppealsInOldSystem:whichFeatureAppealsInOldSystem
    })
      .then(function (response) {

        if (response.status) {
          setLoading(false)
          console.log(response.data)
          setShowRegistration(false)
         // Swal.fire("", "A verification email has been sent. Verify your email.", 'success')
          Swal.fire({
            title: "Yes!",
            text: "Thank you for joining the Solarspect waiting list. We limit our inspections to 10 new users per month. When you’ve been chosen as one of the lucky ones, you will receive an email to let you know that your free membership has begun! Fingers crossed, you’ll be onboard soon!!!",

            confirmButtonText: "Ok",
          }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
             // loginShow()
            } 
          });
          setRole('residential')
          setApproved(false)
          setFirstname('')
          setLastname("")
          setEmail("")
          setSubjects("")
          setExperience("")
          setPassword("")
          setConfirmpassword("")
          setCaptcha("")
          setTermscheckbox(false)

        }
      })
      .catch(function (error) {
        setLoading(false)
        console.log(error.response.data.message)
        Swal.fire("Oops!", error.response.data.message, 'warning')

      });

  }

  function onChangeCaptcha(value) {
    setCaptcha(value)
  }

  const handleLogin = () => {
    login(
      emailValue,
      passwordValue,
      navigate,
      setLoading,
      setErrorMessage
    )
    loginHide()
  }


  const sendResetPwdLink = () => {
    API.user().sendresetlink(emailValue)
      .then(function (res) {
        if (res.status === 200) {

          loginHide()
          Swal.fire("", "Password reset link has been sent to your registered email.", 'success')
        }

      }).catch(err => console.log(err))

  }

  const signout = () => {
    logout(navigate)
    // Swal.fire({
    //     icon: 'success',
    //     title: 'Logout Sucessfull',
    //     text: 'Thank You'
    // })
    // dispatch({ type: "user/logout" })
    // history.push("/");
  }

  useEffect(() => {
    if (errorMessage === 'success') {
      props.invokesignin(false)
      setErrorMessage(null)

    }
  }, [errorMessage]);

  const handleSocialLogin = (user) => {

    sociallogin(
      user._profile.email,
      navigate,
      setLoading,
      setErrorMessage
    )
  };
  const scrollToTop = () => {
    window.scrollTo({
        top: 0,
        behavior: "smooth",
    });
};

  const getAge = birthDate => Math.floor((new Date() - new Date(birthDate).getTime()) / 3.15576e+10)

  const handleSocialRegister = (user) => {
    console.log("scoial success---->", user._profile);
    // setFirstname(user._profile.firstName)
    // setLastname(user._profile.lastName)
    // setEmail(user._profile.email)
    // setProvider(user._provider)
    // setRole('residential')
    // setConfirmpassword(user._token.accessToken)
    // setPassword(user._token.accessToken)


    API.user().register({
      firstname: user._profile.firstName,
      lastname: user._profile.lastName,
      email: user._profile.email,
      birthday: birthday,
      password: user._token.accessToken,
      role: 'residential',
      image: 'uploads/user.jpg',
      mobile: '',
      subjects: subjects,
      experience: experience,
      verified: true,
      approved: false,
      provider: user._provider,
      address: address,
    })
      .then(function (response) {

        if (response.status) {

          setShowRegistration(false)
          sociallogin(
            response.data.email,
            navigate,
            setLoading,
            setErrorMessage
          )

          setRole('residential')
          setApproved(false)
          setFirstname('')
          setLastname("")
          setEmail("")
          setSubjects("")
          setExperience("")
          setPassword("")
          setConfirmpassword("")
          setCaptcha("")
          setTermscheckbox(false)
          setAddress('')
          setWhichIssueFoundInOldSystem("")
          setWhichFeatureAppealsInOldSystem("")
        }
      })
      .catch(function (error) {
        console.log(error.response.data.message)
        Swal.fire("Oops!", error.response.data.message, 'warning')

      });
    //facebook
    // {
    //   "_provider": "facebook",
    //   "_profile": {
    //       "id": "10225807334828315",
    //       "name": "Chetan N",
    //       "firstName": "Chetan",
    //       "lastName": "N",
    //       "email": "co5296@gmail.com",
    //       "profilePicURL": "https://platform-lookaside.fbsbx.com/platform/profilepic/?asid=10225807334828315&height=50&width=50&ext=1675528607&hash=AeRMeNsWG6xf_ac6ti4"
    //   },
    //   "_token": {
    //       "accessToken": "EAAMNvOfgJvQBALUpU29CMjj4Gn3DqXJf7KMZCWK2ExHhc73CHytxeZBMXxxMjclkw6Rjmp7MKmHoZClV9crO30JwQamqtVMgRloupDRECKthwmIa1BjRGwEZComZCBWA4ZAkG5qkG4OIeIdZAz9zt8jZAHp3IZCqi05ZBveAQiYTcgYSiy91moJMJGGZBeJ2dGsHtO23YaJe0iOGgZDZD",
    //       "expiresAt": 1672941601770
    //   }
    //}

    //google
    //   {
    //     "_provider": "google",
    //     "_profile": {
    //         "id": "112473292017237932816",
    //         "name": "chetan nikam",
    //         "firstName": "chetan",
    //         "lastName": "nikam",
    //         "email": "iamchetan03@gmail.com",
    //         "profilePicURL": "https://lh3.googleusercontent.com/a/AEdFTp5NxSo46GLvDhQOx2symJ2M68yRZQeqgli4_eTsew=s96-c"
    //     },
    //     "_token": {
    //         "accessToken": "ya29.a0AX9GBdVGGYaOUrUy6_pFDMf0q-4bckzBp4SL4F6Y6U-Mup_nT_21JKvMDQZaJr0R-BwuLmApCXw1SSb83WPr2Wf89zTMTq74C2h_9u6P3bRUvcaDPU3NzaJJBp5aqoybxUY4K5PNFgVSXxKIQgKAwg20FtKVaCgYKAdMSARMSFQHUCsbCcSJ4yqSXW-N9RcgdWZPM_w0163",
    //         "idToken": "eyJhbGciOiJSUzI1NiIsImtpZCI6IjhlMGFjZjg5MWUwOTAwOTFlZjFhNWU3ZTY0YmFiMjgwZmQxNDQ3ZmEiLCJ0eXAiOiJKV1QifQ.eyJpc3MiOiJhY2NvdW50cy5nb29nbGUuY29tIiwiYXpwIjoiMjg5MjI4OTgxMTgtcWI3Nm9nMTN0aWM3OXAwNnIwOXBqcWJ2ZG1hOGtoN3IuYXBwcy5nb29nbGV1c2VyY29udGVudC5jb20iLCJhdWQiOiIyODkyMjg5ODExOC1xYjc2b2cxM3RpYzc5cDA2cjA5cGpxYnZkbWE4a2g3ci5hcHBzLmdvb2dsZXVzZXJjb250ZW50LmNvbSIsInN1YiI6IjExMjQ3MzI5MjAxNzIzNzkzMjgxNiIsImVtYWlsIjoiaWFtY2hldGFuMDNAZ21haWwuY29tIiwiZW1haWxfdmVyaWZpZWQiOnRydWUsImF0X2hhc2giOiI2Tl80aElkLXc1cHVZOXhYbHNJVU1BIiwibmFtZSI6ImNoZXRhbiBuaWthbSIsInBpY3R1cmUiOiJodHRwczovL2xoMy5nb29nbGV1c2VyY29udGVudC5jb20vYS9BRWRGVHA1TnhTbzQ2R0x2RGhRT3gyc3ltSjJNNjh5UlpRZXFnbGk0X2VUc2V3PXM5Ni1jIiwiZ2l2ZW5fbmFtZSI6ImNoZXRhbiIsImZhbWlseV9uYW1lIjoibmlrYW0iLCJsb2NhbGUiOiJlbiIsImlhdCI6MTY3MjkzODIxMCwiZXhwIjoxNjcyOTQxODEwLCJqdGkiOiJhMmViM2Q1MzIxYWU2YjU4NWI1ZDIyNzVlNTMwMDUxZTFhMjNjOTBmIn0.mlZbhTX6C0gHcSIKWuZK09Nau4yCgK1s4ovrGcxhVHlL7-IilRgCCIWznNU0PmcIi6AE84sZiHS07n15cQxLM1eNMV2AWjjtmbEYo2BAzJXsGSBX0hpezGFmP9tNtTKGG0VIkAva0kQQ37rL_H3yuJOMcbjnKWiVoPKu0YsS0us5ylAdX1EiMVypWqtCFRkg4E61zBEoOrkmiMjkCLZF0Ufmn8lOj_Y7BGXURo37HQwRfBDGZacMSxHQR_AWlzHE_nzMd9vCdQFPWvi56mhTV04zuBDTCDaw7K5K9MArsthzljgbY8i_flQf3NlhdMinRxxRrPzLY5pM_0KkFk1_Og",
    //         "scope": "email profile openid https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email",
    //         "expiresIn": 3599,
    //         "firstIssued_at": 1672938210859,
    //         "expiresAt": 1672941809859
    //     }
    // }
  };
  const handleSocialLoginFailure = (err) => {
    console.error("scoial error---->", err);
  };

  const googlelogin = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      console.log(tokenResponse);
      const userInfo = await axios.get(
        'https://www.googleapis.com/oauth2/v3/userinfo?access_token=' + tokenResponse.access_token,

      );

      console.log(userInfo);
      sociallogin(
        userInfo.data.email,
        navigate,
        setLoading,
        setErrorMessage
      )
    },

  });
  return (
    <>
      
      <section className="bg-primary-subtle py-5 py-md-5  min-vh-100">
    <div className="container">
      <div className="row gy-4 align-items-center">
        <div className="col-12 col-md-6 col-xl-7">
          <div className="d-flex justify-content-center text-bg-primary">
            <div className="col-12 col-xl-9">
            <a href="https://solarspect.io/">
                
              <img
                className="img-fluid rounded mb-4"
                loading="lazy"
                src={logo} 
                width={245}
                height={80}
                alt="BootstrapBrain Logo"
              />
               </a>
              <hr className="border-primary-subtle mb-4" />
              <h2 className="h2 mb-4">
              The world’s most concise and comprehensive solar panel cleaning
              reports are here.              </h2>
              <p className="lead mb-5">
              Clean, inspect and grow like a pro. With only 10 accounts being released each
              month, join the waiting list now.
              </p>
              <div className="text-endx">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={48}
                  height={48}
                  fill="currentColor"
                  className="bi bi-grip-horizontal"
                  viewBox="0 0 16 16"
                >
                  <path d="M2 8a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm0-3a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm3 3a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm0-3a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm3 3a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm0-3a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm3 3a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm0-3a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm3 3a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm0-3a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
                </svg>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 col-md-6 col-xl-5">
          <div className="card border-0 rounded-4">
            <div className="card-body p-3 p-md-4 p-xl-5">
              <div className="row">
                <div className="col-12">
                  <div className="mb-4">
                    <h3>Join the waiting list</h3>
                  
                  </div>
                </div>
              </div>
              <form onSubmit={(e) => { e.preventDefault(); register() }}>
            <input onChange={e => setFirstname(e.currentTarget.value)} type="text" className="form-control border-green  mb-3" placeholder="First Name" required />
            <input onChange={e => setLastname(e.currentTarget.value)} type="text" className="form-control border-green  mb-3" placeholder="Last Name" required />
            <input onChange={e => setEmail(e.currentTarget.value)} size="30" type="email" className="form-control border-green  mb-3" placeholder="Email" pattern="(?![_.-])((?![_.-][_.-])[a-zA-Z\d_.-]){0,63}[a-zA-Z\d]@((?!-)((?!--)[a-zA-Z\d-]){0,63}[a-zA-Z\d]\.){1,2}([a-zA-Z]{2,14}\.)?[a-zA-Z]{2,14}" required />
            {/* <input type="text" max={new Date().toISOString().split("T")[0]}
              onFocus={(e) => (e.target.type = "date")} onBlur={(e) => (e.target.type = "text")} onChange={e => setBirthday(e.currentTarget.value)} className="form-control border-green  mb-3" placeholder="Fecha de nacimiento (DD/MM/AAAA)" /> */}

            {/* <select onChange={e => setAddress(e.currentTarget.value)} value={address} className="form-select border-green  mb-3">
              <option disabled selected value="">Country</option>
              {countries.map(e =>
                <option key={e.code} value={e.code}>{e.name}</option>
              )};
            </select> */}
   {/* <label class="form-label">What fault do you most see on the solar arrays while cleaning?</label> */}

<input onChange={e => setWhichIssueFoundInOldSystem(e.currentTarget.value)} type="text" className="form-control border-green  mb-3" placeholder="What fault do you most see on the solar arrays while cleaning?" required />

{/* <label className="form-label text-green">What Feature On A Solar Panel Cleaning Report Would Appeal To You Most?</label> */}
<textarea onChange={e => setWhichFeatureAppealsInOldSystem(e.currentTarget.value)} type="text" className="form-control border-green  mb-3" placeholder="What Feature On A Solar Panel Cleaning Report Would Appeal To You Most?" required />

            {/* {role === 'commercial' &&
              <div className="text-start">
                <input onChange={e => setSubjects(e.currentTarget.value)} type="text" className="form-control border-green  mb-3" placeholder="¿De qué materias quieres dar clases?" required />

                <label className="form-label text-green">Cuéntanos sobre tu experiencia profesional</label>
                <textarea onChange={e => setExperience(e.currentTarget.value)} type="text" className="form-control border-green  mb-3" placeholder="Máximo 100 palabras" required />
              </div>
            } */}
            <input onChange={e => setPassword(e.currentTarget.value)} type="password" minLength={6} className="form-control border-green  mb-3" placeholder="Password" required />
            <input onChange={e => setConfirmpassword(e.currentTarget.value)} type="password" minLength={6} className="form-control border-green  mb-3" placeholder="Confirm Password" pattern={"^" + password} required />
            <small></small>


            <Form.Check type="checkbox" className='mb-3 text-start'>
              <Form.Check.Input type="checkbox" onChange={(e) => setTermscheckbox(e.target.checked)} />
              <Form.Check.Label className='small'>I agree to Solarscope’s <Link to="/conditions" className='small' target={'_blank'}>Terms & Conditions</Link> & <Link to="/privacy-policies" className='small' target={'_blank'}>Privacy Policy</Link> {termscheckbox}</Form.Check.Label>

            </Form.Check>

            {/* <div className="text-center">
              <Reaptcha
                style={{ display: "inline-block" }}
                sitekey="6LcPOTQjAAAAAGP-GIS0DUpa6J3_O8nzcjejbjtu"
                onVerify={onChangeCaptcha}

              />
            </div> */}


            <button type="submit" className="btn btn-primary w-100 px-5 mb-2">
              {loading && <div className="spinner-border text-light spinner-border-sm" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>} Join Now</button>
          </form>
          <div className="mb-3">
            Already have an approved account? <Link  to={'/login'}className="fw-bold text-black">Login</Link>
          </div>
             
              
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
     

    </>
  );
}


const mapStateToProps = state => ({
  showLogin: state?.user?.showLogin,
})

const mapActionToProps = {

  updateUser: user.update,
  invokesignin: user.invokesignin

}

export default connect(mapStateToProps, mapActionToProps)(Register);