import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useLocation, useSearchParams } from "react-router-dom";
import API from '../../utils/api';

const Verify = () => {
  const navigate = useNavigate()
  let location = useLocation();
  let [searchParams, setSearchParams] = useSearchParams();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});

  useEffect(() => {
    console.log(searchParams.get("trackernum"))
    if(searchParams.get("trackernum")){
      API.user().update(searchParams.get("trackernum"), { verified: true })
      .then(res => {
        console.log(res.status === 200)
        if (res.status === 200) {
         
          setLoading(false)
          setData(res.data)
        } else {
          navigate('/')
        }
      })
      .catch(err => console.log(err))
    }else{
      navigate('/')
    }
   


  }, [location?.pathname])

  return (
    <>
     <div className='container bg-yellow py-4 mb-4 ptop'>

</div>

      <div className='container'>

        <div className='row mb-5'>
          <div className='col-lg-10 offset-lg-1 col-12 mb-4'>

            {loading ?
              <div className='mb-4 text-center'>
                <div className="spinner-border text-warning my-4" role="status">
                  <span className="sr-only"></span>
                </div>
                <h4 className='text-green'>Email Verification in progress</h4>
                <hr className="bg-success border-1 border-top border-success" />
                <p>Please wait, checking.</p>
              </div> :
              <div className='my-4 text-center'>
                <h4 className='text-green'> Congrats! Email Verification successful</h4>
                <hr className="bg-success border-1 border-top border-success" />
                <p>Your email verification is successful</p>
                {/* <p>{data.role==='residential'?'Ahora puede iniciar sesión':'Por favor, hasta que un administrador apruebe su solicitud.'}</p> */}

                <Link className='btn btn-lg rounded-0 bg-yellow text-white fw-bold px-5 mt-4' to="/">Go To Home</Link>
              </div>
            }
          </div>

        </div>
      </div>

    </>

  )
}

export default Verify
