import API from "../utils/api";

export const ACTION_TYPES = {
    COURSE_CREATE: 'COURSE_CREATE',
    COURSE_UPDATE: 'COURSE_UPDATE',
    COURSE_DELETE: 'COURSE_DELETE',
    COURSE_FETCH: 'COURSE_FETCH',
    COURSE_FETCH_ALL: 'COURSE_FETCH_ALL',
    COURSE_PAGINATION: 'COURSE_PAGINATION',
    COURSE_FETCH_RANDOM_COURSE: 'COURSE_FETCH_RANDOM_COURSE'
}

export const fetchAll = () => dispatch => {
    API.course().fetchAll()
        .then(res => {
      
            dispatch({
                type: ACTION_TYPES.COURSE_FETCH_ALL,
                payload: res.data
            })
        })
        .catch(err => console.log(err))
}

export const Pagination = (page = 1, limit = 10, title = "", type) => dispatch => {

       API.course().fetchPagination(page, Math.abs(limit), title, type)
           .then(res =>{
               console.log('API.course().', res.data)
               dispatch({
                   type: ACTION_TYPES.COURSE_PAGINATION,
                   payload: res.data
               })
           })
           .catch(err => console.log(err))
   }

export const fetchById = (id, onSuccess) => dispatch => {
    API.course().fetchById(id)
        .then(res =>{
            dispatch({
                type: ACTION_TYPES.COURSE_FETCH,
                payload: res.data
            })
            onSuccess(res.data)
        })
        .catch(err => console.log(err))
}

export const create = (input, onSuccess) => dispatch => {
    API.course().create(input)
        .then(res =>{
            dispatch({
                type: ACTION_TYPES.COURSE_CREATE,
                payload: res.data
            })
            onSuccess()
        })
        .catch(err => console.log(err))
}

export const update = (id, input, onSuccess) => dispatch => {
   
    API.course().update(id, input)
        .then(res =>{
            dispatch({
                type: ACTION_TYPES.COURSE_UPDATE,
                payload: res.data
            })
            onSuccess()
        })
        .catch(err => console.log(err))
}

export const Delete = (id, onSuccess) => dispatch => {
    API.course().delete(id)
        .then(res =>{
         
            dispatch({
                type: ACTION_TYPES.COURSE_DELETE,
                payload: res.data.id
            })
            onSuccess()
        })
        .catch(err => console.log(err))
}

export const fetchRandomCourses = (param) => dispatch => {
    API.course().fetchRandomCourses(param)
        .then(res => {
              
            dispatch({
                type: ACTION_TYPES.COURSE_FETCH_RANDOM_COURSE,
                payload: res.data
            })
        })
        .catch(err => console.log(err))
}