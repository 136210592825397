import { ACTION_TYPES } from "../actions/class";

const initialState = {
    classes: [],
    metaClass: {},
    randomClasses: [],
    searchedClasses: []
}

export const classes = (state = initialState, action) => {
    switch (action.type) {
        case ACTION_TYPES.CLASS_FETCH_ALL:
            return {
                ...state,
                classes: [...action.payload]
            }
        case ACTION_TYPES.CLASS_CREATE:
            return {
                ...state,
                classes: [...state.classes, action.payload]
            }
        case ACTION_TYPES.CLASS_UPDATE:
            return {
                ...state,
                classes: state.classes.map(x => x.id === action.payload.id ? action.payload : x)
            }
        case ACTION_TYPES.CLASS_DELETE:
            return {
                ...state,
                classes: state.classes.filter(x => x.id !== action.payload)
            }
        case ACTION_TYPES.CLASS_PAGINATION:

            return {
                ...state,
                classes: [...action.payload.classes],
                metaClass: action.payload.meta
            }
        case ACTION_TYPES.COURSE_PAGINATION:

            return {
                ...state,
                metaClass: action.payload.meta
            }
        case ACTION_TYPES.CLASS_FETCH_RANDOM_CLASSES:
          
                return {
                    ...state,
                    randomClasses: [...action?.payload]
                }
                case ACTION_TYPES.CLASS_FETCH_SEARCHED_CLASSES:
          
                return {
                    ...state,
                    searchedClasses: [...action?.payload]
                }

        default:
            return state;
    }
}