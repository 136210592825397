import React, { useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from '@mui/material/Dialog';
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { IconButton, Select, MenuItem, InputLabel, FormControl, Checkbox, FormControlLabel } from '@mui/material';
import EyeIcon from '@mui/icons-material/RemoveRedEye';
import { toast } from 'react-toastify';
import Grow from '@mui/material/Grow';
import API from "../../../../utils/api";
import { initialFormStateTemplate } from "../../../../utils/enums";
import moment from "moment";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Form } from '@flipbyte/formik-json';
import { handleFormSubmitedSchemaEvent, handleFormUploadSchemaEvent } from '../../../../utils/templates_util';
import { CloseOutlined } from "@mui/icons-material";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Grow ref={ref} {...props} />;
});


const FormDialogViewTemplates = (props) => {
  const [open, setOpen] = useState(false);
  const [existingData, setTemplates] = useState(initialFormStateTemplate);
  const [errors, setErrors] = useState({});
  const [avatar, setAvatar] = useState(existingData?.image);
  const [serverActionIsInProgress, setServerActionIsInProgress] = useState(false);

  const onError = (err) => {
    setServerActionIsInProgress(false);
    if (err?.show_message2user) {
      props.refresh();
      setOpen(false);
      toast.error(err.message);
    }
  };


  const updateObjectFieldsForCustomDropFn = (_temp_existingData) => {
    for (var prop in _temp_existingData) {
      //console.log(prop);
      switch (prop) {
        case 'elements':
          _temp_existingData[prop] = updateObjectFieldsForCustomDropFn(_temp_existingData[prop]);
          break;
        case 'fieldType':
          switch (_temp_existingData[prop]) {
            case "file-uploader":
              if (!_temp_existingData.options) {
                _temp_existingData.options = {};
              }
              _temp_existingData.options.onDrop = handleFormUploadSchemaEvent;
              break;
          }
          break;
        default:
          if (_temp_existingData[prop] && _temp_existingData[prop]?.renderer) {
            _temp_existingData[prop] = updateObjectFieldsForCustomDropFn(_temp_existingData[prop]);
          }
          break;
      }
    }
    return _temp_existingData;
  }

  const handleClickOpen = () => {
    setErrors({});

    let _temp_existingData = {
      id: props.dataTemplates[0],
      title: props.dataTemplates[1],
      description: props.dataTemplates[2],
      template_schema: props.dataTemplates[3],
      template_schema_pdf_url: props.dataTemplates[4],
      image: props.dataTemplates[5],
      status: props.dataTemplates[6],
      type: props.dataTemplates[7],
      owner: props.dataTemplates[8],
      createdBy: (props.dataTemplates[9]?._id || (props.dataTemplates[9]?.id || props.dataTemplates[9]))
    };

    _temp_existingData.template_schema = updateObjectFieldsForCustomDropFn(_temp_existingData.template_schema);

    setTemplates(_temp_existingData);
    setOpen(true);
  }

  const handleClose = () => {
    setOpen(false);
  }

  const handleInputChange = event => {
    const { name, value } = event.target
    setTemplates({ ...existingData, [name]: value })

  }

  const handleInputChangeForFileType = event => {
    // if (event.target.files[0].size / 1024 > 200) {
    //   toast.warning('maximum size allowed 200 kbs');
    //   return
    // }
    var formdata = new FormData();
    formdata.append("file", event.target.files[0]);
    formdata.append("documentId", Date.now());
    API.utility().upload(formdata)
      .then(res => {
        setTemplates({ ...existingData, image: res.data.data.path })
      })

  }

  const handleFormBtnEvent = (type) => {
    switch (type) {
      case 'submitted':
        return handleFormSubmitedSchemaEvent.bind(this, handleClose);
      case 'fileupload':
        return handleFormUploadSchemaEvent.bind(this);
        break;
        break;
      default:
        return console.log.bind(console, type);
        break;
    }
  }


  return (
    <div>
      <IconButton onClick={handleClickOpen}>
        <EyeIcon />
      </IconButton>
      {/* <form> */}
      <Dialog
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth="md"
      >

        {/* <DialogTitle id="form-dialog-title" className="text-lg-center">View Template</DialogTitle> */}
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          <button type="button" className="btn btn-link float-end p-0" onClick={handleClose} color="primary">
            <CloseOutlined />
          </button>
        </DialogTitle>
        <DialogContent >

          <div className="col-lg-12 col-12 mb-4">
            <div className="row">
              <div className="col-lg-4 col-8 mb-4">
                <img src={process.env.REACT_APP_IMG_URL + existingData.image} className=" me-2 w-100" onError={({ currentTarget }) => {
                  currentTarget.onerror = null; // prevents looping
                  currentTarget.src = process.env.REACT_APP_IMG_URL + "uploads/logo.png";
                }} />
              </div>
              <div className="col-lg-8 col-8 mb-4">
                <div className="mb-3">
                  <h4>
                    {existingData?.title}
                  </h4>
                  <p>
                    {existingData?.description}
                  </p>
                </div>
              </div>
              <div className="col-lg-12 col-12 mb-4">
                <Form
                  // initialValues={{ label: 'Custom-event' }}
                  schema={existingData?.template_schema}
                  // onChange={handleFormBtnEvent('fileupload')}
                  // onUpdate={handleFormBtnEvent('fileupload')}
                  onSubmit={handleFormBtnEvent('submitted')}
                  onError={handleFormBtnEvent('errors')}
                />



              </div>


            </div>
          </div>




        </DialogContent>

        <DialogActions >

        </DialogActions>

      </Dialog>
      {/* </form> */}
    </div>
  );
}

export default FormDialogViewTemplates;