import React from "react";
import {
  Route,
  Routes,
  Outlet
} from "react-router-dom";

// components
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import HeaderBottom from "../Header/HeaderBottom";
// pages
import { useLocation } from "react-router-dom";

function CommonLayout() {
  const location = useLocation()
  return (

    <>
      <Header location={location} />
      {/* <HeaderBottom /> */}
      <Outlet />
      <Footer />
    </>

  );
}

export default CommonLayout;
