import { combineReducers } from "redux";

import { user } from "./user";
import { classes } from "./class";
import { course } from "./course"
import { order } from "./order";
import { templates } from "./templates";
import { inspections } from "./inspections";

export const reducers = combineReducers({
    user, classes, course, order, templates, inspections
})