import React, { useEffect, useState, useContext } from 'react'
import { connect } from "react-redux";
import { useParams, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import _ from "lodash";
import * as actions from "../../actions/inspections";
import { initialFormStateInspections } from "../../utils/enums";
import { handleFormUploadSchemaEvent } from "../../utils/templates_util";
import { Form } from '@flipbyte/formik-json';
import './style.css'
import moment from 'moment/min/moment-with-locales';


import MUIDataTable from "mui-datatables";
import * as templates from "../../actions/templates";
import ModalEditInspections from "./ModalEditInspections";

import FormDialogAddInspections from "../inspections/inspections-table/formDialog/FormDialogAddInspections";
import FormDialogEditInspections from "../inspections/inspections-table/formDialog/FormDialogEditInspections";
import FormDialogDeleteInspections from "../inspections/inspections-table/formDialog/FormDialogDeleteInspections";
import FormDialogViewInspections from "../inspections/inspections-table/formDialog/FormDialogViewInspections";

import Popover from 'react-bootstrap/Popover';
import { AuthContext } from "../../context/AuthContext";


const Landing4EditInspections = ({ inspections, ...props }) => {
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const [existingData, setInspections] = useState([initialFormStateInspections]);
  const [errors, setErrors] = useState({});
  const [avatar, setAvatar] = useState(existingData?.image);


  let { getAuthUserIdFromJwt, getAuthUser, isAuthenticated } = useContext(AuthContext)
  let authUser = getAuthUser();
  let isAuthentic = isAuthenticated();

  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(200)

  const [type, setType] = useState("")
  const onError = (err) => {
    if (err?.show_message2user) {
      props.refresh();
      // setOpen(false);
      // toast.error(err.message);
    }
  };
  useEffect(() => {
    debugger;
    let inspection_id = location.pathname.split("/").pop();
    const token = location.search.split('=')[1];
    if (token) {
      localStorage.setItem('token', token);
    }
    const user_id = getAuthUserIdFromJwt();
    authUser = getAuthUser();
    isAuthentic = isAuthenticated();
    console.log('inspection_id', inspection_id);
    // const onSuccess = (_temp_existingData) => {
    //   console.log('15 :: inspection :: ', _temp_existingData);
    //   //_temp_existingData.template_schema = updateObjectFieldsForCustomDropFn(_temp_existingData.template_schema);
    //   setInspections([_temp_existingData]);
    // }
    //props.fetchById(inspection_id, onSuccess, onError);
    if (user_id) {
      props.fetchPagination(1, rowsPerPage, null, user_id, onError);
      props.fetchTemplates(user_id, onError);
    }
  }, [location]);
  useEffect(() => {
    debugger;
    console.log('38 :: template data');

  }, [existingData.template_data])
  useEffect(() => {
    console.log('props.templates', props.alltemplates)
  }, [props])

  function return_query_string_from_json_object(obj, without_qm) {
    let s = "";
    if (_.isObject(obj)) {
      for (var key in obj) {
        if (s != "") {
          s += "&";
        }
        s += (key + "=" + encodeURIComponent(obj[key]));
      }
    }
    return (without_qm ? "" : "?") + s + "";
  }

  const handleChangePage = async (newPage) => {
    await setPage(newPage);
    props.fetchPagination(newPage + 1, rowsPerPage, null, authUser?.id, onError);
  };

  const handleChangeRowsPerPage = async (rowsPerPage) => {
    await setRowsPerPage(rowsPerPage);
    await setPage(0);
    props.fetchPagination(1, rowsPerPage, null, authUser?.id, onError);

  };

  const handleSearch = async (searchText) => {
    await setPage(0);
    props.fetchPagination(1, rowsPerPage, searchText, authUser?.id, onError);
  };

  const handleFilterChange = async (title) => {
    await setPage(0);
    props.fetchPagination(1, rowsPerPage, title, authUser?.id, onError);
  };

  const refresh = async () => {
    await setPage(0);
    props.fetchPagination(1, rowsPerPage, null, authUser?.id, onError);
  }

  const popover = (inspections) => (
    <Popover id="popover-basic">

      <Popover.Body>
        <table className="table table-bordered fw-normal small mb-0">
          <tbody>
            {inspections?.map((item, index) =>

              <tr key={index}>
                <td>{index + 1}</td>
                <td>{item.title}</td>
                <td>{moment(item.datetime).local().locale('es').format('lll')}</td>

              </tr>

            )}
          </tbody>
        </table>

      </Popover.Body>
    </Popover>
  );

  const columns = [
    {
      name: "id",
      label: "ID",
      options: {
        display: false,
        filter: false,
        sort: false,
      }
    },
    {
      // left side of first column is too close with the container, give more space on it
      name: "title",
      label: "Template",
      options: {
        filter: true,
        sort: false,
        display: false
      },

    },
    {
      name: "description",
      label: "Description",
      options: {
        filter: true,
        sort: false,
        display: false
      }
    },
    {
      name: "template_data",
      label: "Inspection",
      options: {
        filter: false,
        sort: false,
        display: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          console.log(tableMeta)
          return (value?.page_question_report_title) || '';
        },

      },
    },
    {
      name: "template_schema",
      label: "Template Schema",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    }, {
      name: "template_schema_pdf_url",
      label: "PDF Url",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "image",
      label: "Image",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "status",
      label: "status",
      options: {
        filter: false,
        sort: false,
        display: false,
      },

    },
    {
      name: "type",
      label: "type",
      options: {
        filter: false,
        sort: false,
        display: false,
      },

    },
    {
      name: "owner",
      label: "owner",
      options: {
        filter: false,
        sort: false,
        display: false,
      },

    },
    {
      name: "createdBy",
      label: "Created By",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return value?.email;
        },
        display: authUser?.role === "admin" ? true : false,
      },

    },
    {
      name: "createdDate",
      label: "Created On",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return moment(value).format('lll');
        },
        display: false
      }
    },
    {
      name: "createdBy",
      label: "createdBy",
      options: {
        filter: false,
        sort: false,
        display: false,
      },

    },
    {
      name: "updatedOn",
      label: "Updated on",
      options: {
        filter: true,
        sort: false,
        display: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return moment(value).format('lll');
        }
      }
    },
    {
      name: "",
      options: {
        filter: false,
        sort: false,
        empty: true,
        customHeadRender: (columnMeta, handleToggleColumn) => {
          return (
            <th key={columnMeta.index} style={{ paddingRight: "1px", width:86 }}>
              <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end" }}>
                <FormDialogAddInspections
                  create={props.create}
                  refresh={refresh}
                  type={type}
                  alltemplates={props.alltemplates}
                />
              </div>
            </th>
          );
        },
        customBodyRender: (value, tableMeta, updateValue) => {
          console.log(moment(tableMeta.rowData[15]).add(4, 'hours') < moment())
          return (
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end" }}>

              {/* {type === 'multiple' && <FormDialogEditInspections
                      dataInspections={tableMeta.rowData}
                      update={props.update}
                      type={type}
                  />} */}
              <div class="dropdown">
                <a class="btn btn-light btn-sm-square dropdown-toggle" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">

                </a>

                <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                  <li><a class="dropdown-item"><FormDialogViewInspections
                    dataInspections={tableMeta.rowData}
                    refresh={refresh}
                    createPdf={props.createPdf}
                  /> </a></li>
                  <li><a class="dropdown-item"><FormDialogEditInspections
                    dataInspections={tableMeta.rowData}
                    update={props.update}
                    refresh={refresh}
                    alltemplates={props.alltemplates}
                  /></a></li>
                  <li><a class="dropdown-item">Download</a></li>
                  {authUser?.role === 'admin' && <li><a class="dropdown-item"><FormDialogDeleteInspections
                    dataInspections={tableMeta.rowData}
                    delete={props.delete}
                    refresh={refresh}
                  /></a></li>}
                </ul>
              </div>



            </div>
          );
        }
      }
    }
  ];

  const options = {

    elevation: 0,
    search: false,
    filter: false,
    filterType: 'textField',
    responsive: 'standard',
    selectableRows: 'none',
    rowsPerPageOptions: [5, 10, 25],
    serverSide: true,
    viewColumns: false,
    print: false,
    download: false,
    rowsPerPage: rowsPerPage,
    count: props?.meta?.totalDocs || 0,
    page: page,
    pagination: false,
    textLabels: {
      pagination: {
        next: "Next",
        previous: "previous",
        rowsPerPage: "Page per rows:",
        displayRows: "Page",
      },
    },

    onTableChange: (action, tableState) => {
      switch (action) {
        case 'changePage':
          handleChangePage(tableState.page)
          break;

        case 'changeRowsPerPage':
          handleChangeRowsPerPage(tableState.rowsPerPage)
          break;

        case 'search':
          handleSearch(tableState.searchText)
          break;

        case 'filterChange':
          handleFilterChange(tableState.filterList[1], tableState.filterList[2])
          break;

        case 'resetFilters':
          handleSearch("")
          break;

        default:
          break;
      }
    },
  };

  return (
    <MUIDataTable
      // title="Clases"

      data={type === 'single' ? props.allinspections : props.allinspections}
      columns={columns}
      options={options}
      style={{ zIndex: 2990 }}
    />
  );
}

const mapStateToProps = state => ({
  allinspections: state?.inspections?.inspections,
  meta: state?.inspections?.metaClass,
  alltemplates: state?.templates?.templates
})

const mapActionToProps = {
  fetchById: actions.fetchById,
  createPdf: actions.createPdf,
  fetchPagination: actions.Pagination,
  create: actions.create,
  update: actions.update,
  delete: actions.Delete,
  fetchTemplates: templates.fetchAll,
}

export default connect(mapStateToProps, mapActionToProps)(Landing4EditInspections);
