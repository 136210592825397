import API from "../utils/api";

export const ACTION_TYPES = {
    USER_CREATE: 'USER_CREATE',
    USER_UPDATE: 'USER_UPDATE',
    USER_DELETE: 'USER_DELETE',
    USER_FETCH: 'USER_FETCH',
    USER_FETCH_ALL: 'USER_FETCH_ALL',
    USER_PAGINATION: 'USER_PAGINATION',
    USER_PAGINATION_RESIDENTIAL: 'USER_PAGINATION_RESIDENTIAL',
    USER_PAGINATION_COMMERCIAL: 'USER_PAGINATION_COMMERCIAL',
    USER_SIGNIN_MODAL: 'USER_SIGNIN_MODAL',
}

export const fetchAll = () => dispatch => {
    API.user().fetchAll()
        .then(res => {
            dispatch({
                type: ACTION_TYPES.USER_FETCH_ALL,
                payload: res.data
            })
        })
        .catch(err => console.log(err))
}

export const Pagination = (page = 1, limit = 5, firstName = "", email = "", role) => dispatch => {
    API.user().fetchPagination(page, Math.abs(limit), firstName, email)
        .then(res =>{
            console.log(firstName,res.data)
            switch(role) {
                case "residential":
                    dispatch({
                        type: ACTION_TYPES.USER_PAGINATION_RESIDENTIAL,
                        payload: res.data
                    })
                  break;
                case "commercial":
                    dispatch({
                        type: ACTION_TYPES.USER_PAGINATION_COMMERCIAL,
                        payload: res.data
                    })
                  break;
                default:
                    dispatch({
                        type: ACTION_TYPES.USER_PAGINATION,
                        payload: res.data
                    })
              }
            
        })
        .catch(err => console.log(err))
}

export const fetchById = (id, onSuccess) => dispatch => {
    API.user().fetchById(id)
        .then(res =>{
            dispatch({
                type: ACTION_TYPES.USER_FETCH,
                payload: res.data
            })
            onSuccess(res.data)
        })
        .catch(err => console.log(err))
}

export const create = (input, onSuccess) => dispatch => {
    API.user().create(input)
        .then(res =>{
            dispatch({
                type: ACTION_TYPES.USER_CREATE,
                payload: res.data
            })
            onSuccess()
        })
        .catch(err => console.log(err))
}

export const update = (id, input, onSuccess) => dispatch => {

    API.user().update(id, input)
        .then(res =>{
            dispatch({
                type: ACTION_TYPES.USER_UPDATE,
                payload: res.data
            })
            onSuccess()
        })
        .catch(err => console.log(err))
}

export const Delete = (id, onSuccess) => dispatch => {
    API.user().delete(id)
        .then(res =>{
         
            dispatch({
                type: ACTION_TYPES.USER_DELETE,
                payload: res.data.id
            })
            onSuccess()
        })
        .catch(err => console.log(err))
}

export const invokesignin = (value) => dispatch => {
    console.log(value)
    dispatch({
        type: ACTION_TYPES.USER_SIGNIN_MODAL,
        payload: value
    })
}