import React, { useEffect, useState, useContext } from "react";

import MUIDataTable from "mui-datatables";
import { connect } from "react-redux";
import * as actions from "../../../../actions/order";
import * as users from "../../../../actions/user";
import moment from 'moment/min/moment-with-locales';
import { Link, useNavigate, useLocation } from "react-router-dom";
import { AuthContext } from "../../../../context/AuthContext";
import sha1 from 'crypto-js/sha1'
import _ from 'lodash'
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Swal from "sweetalert2";

const PaymentsTable = ({ Payments, ...props }) => {
    let location = useLocation();
    const { getAuthUser } = useContext(AuthContext)
    const authUser = getAuthUser()
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(10)
    const [show, setShow] = useState(false);
    const [paypalname, setPaypalname] = useState("");
    const [paypalid, setPaypalid] = useState("");

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    useEffect(() => {
        props.fetchPagination(1, rowsPerPage, authUser.id)

        const onSuccess = (data) => {
            console.log(data)
            setPaypalname(data?.paypalname)
            setPaypalid(data?.paypalid)
        }

        props.fetchById(authUser.id, onSuccess)

    }, [location?.pathname])

    function return_query_string_from_json_object(obj, without_qm) {
        let s = "";
        if (_.isObject(obj)) {
            for (var key in obj) {
                if (s !== "") {
                    s += "&";
                }
                s += (key + "=" + encodeURIComponent(obj[key]));
            }
        }
        return (without_qm ? "" : "?") + s + "";
    }

    const handleChangePage = async (newPage) => {
        await setPage(newPage);
        props.fetchPagination(newPage + 1, rowsPerPage, authUser.id)
    };

    const handleChangeRowsPerPage = async (rowsPerPage) => {
        await setRowsPerPage(rowsPerPage);
        await setPage(0);
        props.fetchPagination(1, rowsPerPage, authUser.id)

    };

    const handleSearch = async (searchText) => {
        await setPage(0);
        props.fetchPagination(1, rowsPerPage, authUser.id)
    };

    const handleFilterChange = async (title) => {
        await setPage(0);
        props.fetchPagination(1, rowsPerPage, authUser.id)
    };

    const refresh = async () => {
        await setPage(0);
        props.fetchPagination(1, rowsPerPage, authUser.id)
    }

    const savepaymentdetails = async (e) => {
        if (!paypalname) {
            Swal.fire("Oops!", "Enter full name", 'warning')
            return
        }

        if (!paypalid) {
            Swal.fire("Oops!", "Enter paypal ID", 'warning')
            return
        }
        let obj = {
            paypalname: paypalname,
            paypalid: paypalid
        }
        const onSuccess = () => {

        }
        e.preventDefault();

        props.updateUser(authUser.id, obj, onSuccess)
        handleClose()
    }



    const columns = [
        {
            name: "id",
            label: "ID",
            options: {
                display: false,
                filter: false,
                sort: false,
            }
        },
        {
            name: "transaction_amount",
            label: "Amount",
            options: {
                filter: false,
                sort: false,
            },

        },

        {
            name: "id",
            label: "ID",
            options: {
                filter: false,
                sort: false,
                display: false
            },

        },
        {
            name: "plan",
            label: "Plan",
            options: {
                filter: false,
                sort: false,
            },

        },
        {
            name: "createdBy",
            label: "createdBy",
            options: {
                filter: false,
                sort: false,
                display: false,
            },

        },
        {
            name: "createdOn",
            label: "Created on",
            options: {
                filter: true,
                sort: true,
                display: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return moment(value).format('lll');
                }
            }
        },

    ];

    const options = {

        elevation: 1,
        search: false,
        filter: false,
        filterType: 'textField',
        responsive: 'standard',
        selectableRows: 'none',
        rowsPerPageOptions: [5, 10, 25],
        serverSide: true,
        viewColumns: false,
        print: false,
        download: false,
        rowsPerPage: rowsPerPage,
        count: props?.meta?.totalDocs || 0,
        page: page,
        textLabels: {
            pagination: {
                next: "Next",
                previous: "previous",
                rowsPerPage: "Page per rows:",
                displayRows: "Page",
            },
        },

        onTableChange: (action, tableState) => {
            switch (action) {
                case 'changePage':
                    handleChangePage(tableState.page)
                    break;

                case 'changeRowsPerPage':
                    handleChangeRowsPerPage(tableState.rowsPerPage)
                    break;

                case 'search':
                    handleSearch(tableState.searchText)
                    break;

                case 'filterChange':
                    handleFilterChange(tableState.filterList[1], tableState.filterList[2])
                    break;

                case 'resetFilters':
                    handleSearch("")
                    break;

                default:
                    break;
            }
        },
    };

    return (
        <>
            <div className="row mb-4">
                <div className="col-xl-4 text-start">
                    <div className="card  shadow py-2 mb-3">
                        <div className="card-body">
                            <div className="row no-gutters align-items-center">
                                <div className="col mr-2">
                                    <div className="text-xs font-weight-bold text-warning text-uppercase mb-1">
                                        Subscribed Plan
                                    </div>
                                    <div className="h3 mb-0 font-weight-bold text-gray-800 text-primary">
                                    {authUser?.plantype}
                                    </div>
                                </div>
                               
                            </div>
                        </div>
                    </div>
                    <div className="card  shadow py-2 mb-3">
                        <div className="card-body">
                            <div className="row no-gutters align-items-center">
                                <div className="col mr-2">
                                    <div className="text-xs font-weight-bold text-warning text-uppercase mb-1">
                                    Validity
                                    </div>
                                    <div className="h3 mb-0 font-weight-bold text-gray-800 text-primary">
                                    {moment(authUser?.planexpiry).format("ll")}
                                    </div>
                                </div>
                               
                            </div>
                        </div>
                    </div>

                    <div className="card  shadow py-2 ">
                        <div className="card-body">
                            <div className="row no-gutters align-items-center">
                                <div className="col mr-2">
                                    <div className="text-xs font-weight-bold text-warning text-uppercase mb-1">
                                    Total Payments
                                    </div>
                                    <div className="h3 mb-0 font-weight-bold text-gray-800 text-primary">
                                    {props.sum}
                                    </div>
                                </div>
                               
                            </div>
                        </div>
                    </div>
                  
                </div>
                <div className="col-xl-8 text-end">
                 

                    <MUIDataTable
                        // title="Clases"
                        data={props.orders.filter(e=>e.transaction_amount !=0)}
                        columns={columns}
                        options={options}
                    />

                </div>
            </div>

        </>

    );
}

const mapStateToProps = state => ({
    orders: state?.order?.orders,
    meta: state?.order?.metaOrder,
    metaCount: state?.order?.metaCount,
    sum: state?.order?.sum
})

const mapActionToProps = {
    fetchPagination: actions.getEarnings,
    create: actions.create,
    update: actions.update,
    delete: actions.Delete,
    getCount: actions.getCount,
    updateUser: users.update,
    fetchById: users.fetchById
}


export default connect(mapStateToProps, mapActionToProps)(PaymentsTable);