import React, { useState, useContext } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from '@mui/material/Dialog';
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { IconButton, Select, MenuItem, InputLabel, FormControl, Checkbox, FormControlLabel } from '@mui/material';
import { EnumTemplatesFieldType, EnumTemplatesFieldTypeWithLabel, initialFormStateTemplate, EnumTemplateFieldConditionalAction, defaultMessageIfNotAnswerReceivedFromUser } from "../../../../utils/enums";
import EyeIcon from '@mui/icons-material/RemoveRedEye';
import { toast } from 'react-toastify';
import Grow from '@mui/material/Grow';
import API from "../../../../utils/api";
import { initialFormStateInspections } from "../../../../utils/enums";
import moment from "moment";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Form } from '@flipbyte/formik-json';
import _ from "lodash";
import { green, grey } from "@mui/material/colors";
import { useDrawingArea } from '@mui/x-charts/hooks';
import { styled } from '@mui/material/styles';
import { PieChart } from '@mui/x-charts/PieChart';
import { AuthContext } from "../../../../context/AuthContext";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Grow ref={ref} {...props} />;
});


const FormDialogViewInspections = (props) => {
  const { getAuthUser } = useContext(AuthContext);
  const authUser = getAuthUser();
  const [open, setOpen] = useState(false);
  const [existingData, setInspections] = useState(initialFormStateInspections);
  const [errors, setErrors] = useState({});
  const [cavatar, setCAvatar] = useState(authUser?.image);
  const [template_schema_pdf_url, settemplate_schema_pdf_url] = useState(existingData?.template_schema_pdf_url);
  const [showPdf, setShowPdf] = useState(false);
  const [isPdfGenerating, setIsPdfGenerating] = useState(false);
  const [serverActionIsInProgress, setServerActionIsInProgress] = useState(false);

  const onError = (err) => {
    setServerActionIsInProgress(false);
    if (err?.show_message2user) {
      props.refresh();
      setOpen(false);
      setShowPdf(false);
      toast.error(err.message);
    }
  };

  const handleClickOpen = () => {
    setErrors({});
    setInspections({
      id: props.dataInspections[0],
      report_title: props.dataInspections[1],
      title: props.dataInspections[2],
      description: props.dataInspections[3],
      template_data: props.dataInspections[4] || {},
      template_schema: props.dataInspections[5],
      template_schema_pdf_url: props.dataInspections[6],
      image: props.dataInspections[7],
      status: props.dataInspections[8],
      type: props.dataInspections[9],
      owner: props.dataInspections[10],
      createdBy: (props.dataInspections[11]?._id || (props.dataInspections[11]?.id || props.dataInspections[11]))
    });
    settemplate_schema_pdf_url(props.dataInspections[5]);
    setOpen(true);
    setShowPdf(false);
  }

  const handleClose = () => {
    setOpen(false);
    setShowPdf(false);

  }
  const toggleViewPDF = () => {
    // setShowPdf(!showPdf);
    if (showPdf) {
      setShowPdf(false);
    } else {
      handlePrint();
    }
  }
  let array_of_all_opened_toast = [];
  const handlePrint = () => {
    if (isPdfGenerating) {
      ////already in progress
      return;
    }
    let id = toast.loading("Opening...", {
      closeOnClick: true,
    });
    console.log("87 :: ", id);
    setTimeout((id) => {
      for (let index in array_of_all_opened_toast) {
        let _id = array_of_all_opened_toast.pop();
        toast.update(_id, { render: "Closing!!!", type: "success", isLoading: false, closeButton: true, autoClose: 500, closeOnClick: true });;///just close it
        console.log("92 :: ", _id);
      }
      toast.update(id, { render: "Closing!!!", type: "success", isLoading: false, closeButton: true, autoClose: 500, closeOnClick: true });;///just close it
    }, 7000, id);
    array_of_all_opened_toast.push(id);
    setIsPdfGenerating(true);
    const onSuccess = (data) => {
      setIsPdfGenerating(false);
      //setOpen(false);
      if (data?.path) {
        settemplate_schema_pdf_url(data?.path);
        setInspections({ ...existingData, template_schema_pdf_url: data?.path });
        // toast.success('Success!!!');
        toast.update(id, { render: "Success!!!", type: "success", isLoading: false, closeButton: true, autoClose: 5000, closeOnClick: true });
        setShowPdf(true);
      } else {
        // toast.error('Print failed!!!');
        toast.update(id, { render: "Failed!!!", type: "error", isLoading: false, closeButton: true, autoClose: 5000, closeOnClick: true });
      }
    };
    const onErrorInner = (err) => {
      toast.update(id, { render: "Failed!!!", type: "error", isLoading: false, closeButton: true, autoClose: 5000, closeOnClick: true });
      onError(err);
    };
    let _temp_existingData = existingData;
    _temp_existingData.app_server_url = process.env.REACT_APP_IMG_URL;
    _temp_existingData.company_image = cavatar;
    props.createPdf(existingData.id, _temp_existingData, onSuccess, onErrorInner);
    //handleClose();
  }

  const handleInputChange = event => {
    const { name, value } = event.target
    setInspections({ ...existingData, [name]: value })

  }

  const handleInputChangeForFileType = event => {
    if (event.target.files[0].size / 1024 > 200) {
      toast.warning('maximum size allowed 200 kbs');
      return
    }
    var formdata = new FormData();
    formdata.append("file", event.target.files[0]);
    formdata.append("documentId", Date.now());
    API.utility().upload(formdata)
      .then(res => {
        setInspections({ ...existingData, image: res.data.data.path })
      })

  }

  const handleFormSubmitedEvent = (event) => {
    handleClose();
  }

  const handleFormBtnEvent = (type) => {
    switch (type) {
      case 'submitted':
        return handleFormSubmitedEvent.bind(this);
        break;
      default:
        return console.log.bind(console, type);
        break;
    }
  }
  const _returnT_for_question_is_set_validation_required = (_nestedPathFromRoot) => {
    let _is_requiredT = false;
    let _existing_validation = _.get(existingData, _nestedPathFromRoot) || [];
    _existing_validation.map((obj, index) => {
      if (obj) {
        if (obj.indexOf("required") > -1) {
          _is_requiredT = true;
        }
      }
    });
    return _is_requiredT;
  }
  //#region pie chart
  const _return_if_enabled_then_single_point_value_out_of_100_for_question_points_enabled = () => {
    let _total_questions_with_points_found = 0;

    //#region here value is 1 so calculate from rest all points
    let _list_of_all_pages = _.values(_.get(existingData, "template_schema.elements"));
    for (let _single_page of _list_of_all_pages) {
      for (let _single_question of _.values(_single_page.elements)) {
        if (_single_question?.points) {
          _total_questions_with_points_found = _total_questions_with_points_found + 1;
        }
      }
    }
    if (_total_questions_with_points_found) {
      return (100 / _total_questions_with_points_found).toFixed(2);
    } else {
      return 0;
    }
  }

  const _return_total_point_value_out_of_100_for_question_points_enabled = (percentage_string_enabledT) => {
    let _total_questions_with_points_found_ids = [];
    //#region here value is 1 so calculate from rest all points
    let _list_of_all_pages = _.values(_.get(existingData, "template_schema.elements"));
    for (let _single_page of _list_of_all_pages) {
      for (let _single_question of _.values(_single_page.elements)) {
        if (_single_question?.points) {
          _total_questions_with_points_found_ids.push(_single_question.id || _single_question.name);
        }
      }
    }
    let _list_of_all_questions_answered = _.keys(_.get(existingData, "template_data"));
    let _total_questions_with_points_found = 0;
    for (let _single_question of _total_questions_with_points_found_ids) {
      if (_list_of_all_questions_answered.includes(_single_question)) {
        _total_questions_with_points_found = _total_questions_with_points_found + 1;
      }
    }

    let _return_percentage_value = 0;

    if (_total_questions_with_points_found) {
      _return_percentage_value = (_total_questions_with_points_found * 1) / _total_questions_with_points_found_ids.length;
    }
    //#endregion here value is 1 so calculate from rest all points

    if (percentage_string_enabledT) {
      return (_return_percentage_value * 100) + "%";
    } else {
      return _return_percentage_value;
    }

  }

  const _return_data_for_point_value_out_of_100_for_question_points_enabled = () => {
    let _success_color = 'green';
    let _failure_color = 'gray';

    let _total_questions_with_points_found_ids_obj = [];
    //#region here value is 1 so calculate from rest all points
    let _list_of_all_pages = _.values(_.get(existingData, "template_schema.elements"));
    for (let _single_page of _list_of_all_pages) {
      for (let _single_question of _.values(_single_page.elements)) {
        if (_single_question?.points) {
          _total_questions_with_points_found_ids_obj.push({ name: _single_question.name, label: _single_question.label });
        }
      }
    }

    let _single_question_percentage = (100 / _total_questions_with_points_found_ids_obj.length).toFixed(2);

    let _list_of_all_questions_answered = _.keys(_.get(existingData, "template_data"));
    let _total_questions_with_points_found = 0;
    let _data_set_for_chart_fill_questions = [];
    for (let _single_question of _total_questions_with_points_found_ids_obj) {
      let _single_data = { id: _single_question.name, value: _single_question_percentage, label: _single_question.label, color: _failure_color }
      if (_list_of_all_questions_answered.includes(_single_question.name)) {
        _total_questions_with_points_found = _total_questions_with_points_found + 1;
        _single_data.color = _success_color;
      }
      _data_set_for_chart_fill_questions.push(_single_data);
    }
    //#endregion here value is 1 so calculate from rest all points
    return _data_set_for_chart_fill_questions;

  }

  const PieCenterLabel = ({ children }) => {
    const { width, height, left, top } = useDrawingArea();
    return (
      <StyledText x={left + width / 2} y={top + height / 2}>
        {children}
      </StyledText>
    );
  }
  const StyledText = styled('text')(({ theme }) => ({
    fill: theme.palette.text.primary,
    textAnchor: 'middle',
    dominantBaseline: 'central',
    fontSize: 20,
  }));

  //#endregion pie chart


  const TemplatePageValueOfSchemaHtml = (props) => {

    const _attrName = props.name;
    const _fieldType = props.object.fieldType || props.object.type;
    const _attrOptions = props.object.options || [];
    const _attrValue = existingData.template_data[_attrName] || existingData.template_data[props.object.name];
    switch (_fieldType) {
      case EnumTemplatesFieldType.radio:

        if (_attrValue?.length > 0) {
          return (
            <div className="mb-1 col-lg-12 col-12">
              {
                _attrOptions?.map((optionsKey, indexOptions) => {

                  const _current_options_result_is_matched = (optionsKey.value === _attrValue) ? true : false;
                  if (optionsKey?.colorCode?.hex) {
                    return (
                      (_current_options_result_is_matched === true && <label className="m-1 px-2 pb-1 badge" style={{ 'backgroundColor': optionsKey?.colorCode?.hex, 'color': '#ffffff' }}>{optionsKey?.label || optionsKey?.title} </label>)
                      // || (_current_options_result_is_matched !== true && <label className="badge bg-danger fw-light m-1 px-2 pb-1">{optionsKey?.label || optionsKey?.title} </label>)
                    )
                  } else {
                    return (
                      (_current_options_result_is_matched === true && <label className="badge bg-secondary m-1 px-2 pb-10">{optionsKey?.label || optionsKey?.title} </label>)
                      // || (_current_options_result_is_matched !== true && <label className="badge bg-danger fw-light m-1 px-2 pb-1">{optionsKey?.label || optionsKey?.title} </label>)
                    )
                  }

                })
              }
            </div>
          )
        } else {
          return (
            <div className="mb-w col-lg-12 col-12">
              <div className="row">
                <div className="col-lg-3 col-8 mb-2">
                  <label className="form-label fw-bold"><i>{defaultMessageIfNotAnswerReceivedFromUser}</i></label>
                </div>
              </div>
            </div>
          )
        }
        break;
      case EnumTemplatesFieldType.checkbox:
        if (_attrValue?.length > 0) {
          return (
            <div className="mb-1 col-lg-12 col-12">
              {
                _attrValue?.map((optionsValue, indexOptions) => {

                  const optionsKey = _attrOptions[indexOptions]
                  const _current_options_result_is_matched = (['on', true, 1].indexOf(optionsValue) > -1 || _.intersection(['on', true, 1], optionsValue).length > 0) ? true : false;
                  if (optionsKey?.colorCode?.hex) {
                    return (
                      (_current_options_result_is_matched === true && <label className="fw-light m-1 px-2 pb-1 badge" style={{ 'backgroundColor': optionsKey?.colorCode?.hex }}>{optionsKey?.label || optionsKey?.title} </label>)
                      // || (_current_options_result_is_matched !== true && <label className="badge bg-danger fw-light m-1 px-2 pb-1">{optionsKey?.label || optionsKey?.title} </label>)
                    )
                  } else {
                    return (
                      (_current_options_result_is_matched === true && <label className="badge bg-success fw-light m-1 px-2 pb-1">{optionsKey?.label || optionsKey?.title} </label>)
                      // || (_current_options_result_is_matched !== true && <label className="badge bg-danger fw-light m-1 px-2 pb-1">{optionsKey?.label || optionsKey?.title} </label>)
                    )
                  }

                })
              }
            </div>
          )
        } else {
          return (
            <div className="mb-w col-lg-12 col-12">
              <div className="row">
                <div className="col-lg-3 col-8 mb-2">
                  <label className="form-label fw-bold"><i>{defaultMessageIfNotAnswerReceivedFromUser}</i></label>
                </div>
              </div>
            </div>
          )
        }
        break;
      case EnumTemplatesFieldType["file-uploader"]:
        if (_attrValue?.length > 0) {
          return (
            <div className="mb-w col-lg-12 col-12">
              <div className="row">
                {_attrValue?.map((optionsValue, indexOptions) => {
                  return (
                    <div className="col-lg-3 col-8 mb-2">
                      <img src={process.env.REACT_APP_IMG_URL + optionsValue} className="w-100" />
                    </div>
                  )
                })}
              </div>
            </div>
          )

        } else {
          return (
            <div className="mb-w col-lg-12 col-12">
              <div className="row">
                <div className="col-lg-3 col-8 mb-2">
                  <label className="form-label fw-bold"><i>{defaultMessageIfNotAnswerReceivedFromUser}</i></label>
                </div>
              </div>
            </div>
          )
        }
        break;
      case EnumTemplatesFieldType.switch:
        if (!_.isUndefined(_attrValue) || !_.isNull(_attrValue)) {
          if (_attrValue) {
            return (
              <div className="mb-1 col-lg-12 col-12">
                <label className="form-label fw-bold">YES </label>
              </div>
            );
          } else {
            return (
              <div className="mb-1 col-lg-12 col-12">
                <label className="form-label fw-bold">NO </label>
              </div>
            );
          }
        } else {
          return (
            <div className="mb-1 col-lg-12 col-12">
              <label className="form-label fw-bold"><i>{defaultMessageIfNotAnswerReceivedFromUser}</i></label>
            </div>
          );
        }
        break;
        case EnumTemplatesFieldType["inner-text"]:
          ///no text to show user
          break;
      default:
        if (!_.isUndefined(_attrValue) && !_.isNull(_attrValue) && _.trim(_attrValue) !== "") {
          return (
            <div className="mb-1 col-lg-12 col-12">
              <label className="form-label fw-bold">{_attrValue} </label>
            </div>
          );
        } else {
          return (
            <div className="mb-1 col-lg-12 col-12">
              <label className="form-label fw-bold"><i>{defaultMessageIfNotAnswerReceivedFromUser}</i></label>
            </div>
          );
        }
        break;
    }

  }
  const TemplatesPageSchemaHtml = (props) => {

    return (
      <div className=" mb-4">
        <div className="bg-xlight py-2 px-2 mb-2">
          <div className="form-label fw-bold mb-0">{_.get(existingData, 'template_schema.elements.' + props.elementsObjAttrPage + '.title')} :</div>
        </div>
        <div className="p-2">

          <div className="row">
            <div className="col-lg-12 col-12">
              {Object.keys(_.get(existingData, 'template_schema.elements.' + props.elementsObjAttrPage + '.elements')).length > 0 && <div className="mb-2">
                {Object.keys(_.get(existingData, 'template_schema.elements.' + props.elementsObjAttrPage + '.elements')).map((elementsObjAttrPageInner, indexInner) => {

                  /*check whether current quetion is depends on another question's response or now */
                  if (_.get(existingData, 'template_schema.elements.' + props.elementsObjAttrPage + '.elements.' + elementsObjAttrPageInner + '.showWhen')) {

                    let _show_when_condition = _.get(existingData, 'template_schema.elements.' + props.elementsObjAttrPage + '.elements.' + elementsObjAttrPageInner + '.showWhen');
                    let _super_question_data = _.get(existingData, 'template_schema.elements.' + props.elementsObjAttrPage + '.elements.' + elementsObjAttrPageInner + '.super_question_data');
                    let _super_question_id = _super_question_data.name_of_super_question;
                    let _response_for_super_question = _.get(existingData, 'template_data.' + _super_question_id);
                    console.log("381 :: ", _show_when_condition, _super_question_id, _response_for_super_question);
                    _show_when_condition = _show_when_condition || [];
                    let _received_expected_response_from_user_to_show_current_q_n_a = false;
                    // debugger;
                    if (_super_question_data) {
                      if (_.isArray(_response_for_super_question)) {
                        switch (_super_question_data.add_logic_condition) {
                          case EnumTemplateFieldConditionalAction.is:
                            ///single selected  
                            if (_super_question_data.add_logic_value) {
                              if (_response_for_super_question[(parseInt(_super_question_data.add_logic_value.split('_')[1]) - 1)]?.[0] === "on") {
                                _received_expected_response_from_user_to_show_current_q_n_a = true;
                              }
                            }

                            break;
                          case EnumTemplateFieldConditionalAction.is_not:
                            ///single not selected  
                            if (_super_question_data.add_logic_value) {
                              if (_response_for_super_question[(parseInt(_super_question_data.add_logic_value.split('_')[1]) - 1)]?.[0] !== "on") {
                                _received_expected_response_from_user_to_show_current_q_n_a = true;
                              }
                            }

                            break;
                          case EnumTemplateFieldConditionalAction.is_selected:
                            ///either selected any  
                            for (let _current_selected_response of _response_for_super_question) {
                              if (_response_for_super_question && _response_for_super_question[0]) {
                                _received_expected_response_from_user_to_show_current_q_n_a = true;
                              }
                            }
                            break;
                          case EnumTemplateFieldConditionalAction.is_not_selected:
                            ///either not selected any  
                            for (let _current_selected_response of _response_for_super_question) {
                              if (!_response_for_super_question || (_response_for_super_question && !_response_for_super_question[0])) {
                                _received_expected_response_from_user_to_show_current_q_n_a = true;
                              }
                            }
                            break;
                          case EnumTemplateFieldConditionalAction.is_one_of:
                            ///either one of the   
                            for (let _current_add_logic_value of _super_question_data.add_logic_value) {
                              if (_current_add_logic_value) {
                                if (_response_for_super_question[(parseInt(_current_add_logic_value.split('_')[1]) - 1)]?.[0] == "on") {
                                  _received_expected_response_from_user_to_show_current_q_n_a = true;
                                }
                              }
                            }
                            break;
                          case EnumTemplateFieldConditionalAction.is_not_one_of:
                            ///neither one of the  
                            for (let _current_add_logic_value of _super_question_data.add_logic_value) {
                              if (_current_add_logic_value) {
                                if (_response_for_super_question[(parseInt(_current_add_logic_value.split('_')[1]) - 1)]?.[0] == "on") {
                                  _received_expected_response_from_user_to_show_current_q_n_a = true;
                                }
                              }
                            }
                            //here reversing the condition as checked for atleast one occurance of option which need to false
                            _received_expected_response_from_user_to_show_current_q_n_a = !_received_expected_response_from_user_to_show_current_q_n_a;

                            break;

                        }
                      } else {
                        switch (_super_question_data.add_logic_condition) {
                          case EnumTemplateFieldConditionalAction.is:
                            ///single selected  
                            if ((_super_question_data.add_logic_value === _response_for_super_question) || (!_super_question_data.add_logic_value && !_response_for_super_question)) {
                              _received_expected_response_from_user_to_show_current_q_n_a = true;
                            }
                            break;
                          case EnumTemplateFieldConditionalAction.is_not:
                            ///single not selected  
                            if (_super_question_data.add_logic_value !== _response_for_super_question) {
                              _received_expected_response_from_user_to_show_current_q_n_a = true;
                            }
                            break;
                          case EnumTemplateFieldConditionalAction.is_selected:
                            ///either selected any  
                            if (_response_for_super_question) {
                              _received_expected_response_from_user_to_show_current_q_n_a = true;
                            }
                            break;
                          case EnumTemplateFieldConditionalAction.is_not_selected:
                            ///either not selected any  
                            if (!_response_for_super_question) {
                              _received_expected_response_from_user_to_show_current_q_n_a = true;
                            }
                            break;
                          case EnumTemplateFieldConditionalAction.is_one_of:
                            ///either one of the   
                            if (_super_question_data.add_logic_value.includes(_response_for_super_question) || _super_question_data.add_logic_value === _response_for_super_question) {
                              _received_expected_response_from_user_to_show_current_q_n_a = true;
                            }
                            break;
                          case EnumTemplateFieldConditionalAction.is_not_one_of:
                            ///neither one of the  
                            if (_super_question_data.add_logic_value.includes(_response_for_super_question) == false && _super_question_data.add_logic_value !== _response_for_super_question) {
                              _received_expected_response_from_user_to_show_current_q_n_a = true;
                            }
                            break;

                        }
                      }
                    }
                    if (!_received_expected_response_from_user_to_show_current_q_n_a) {
                      ////consition not meet so not showing current question
                      return;
                    }

                  }
                  return (
                    <div className="pt-2 pb-1 border-bottom" key={elementsObjAttrPageInner + '_' + indexInner}>
                      <div className="row ">
                        <div className="col-lg-12 col-12 ">

                          <p className="mb-2 text-black-50">
                            {_.get(existingData, 'template_schema.elements.' + props.elementsObjAttrPage + '.elements.' + elementsObjAttrPageInner + '.label')}
                            {_returnT_for_question_is_set_validation_required('template_schema.elements.' + props.elementsObjAttrPage + '.elements.' + elementsObjAttrPageInner + '.validation') === true && <label className="form-check-label"> *</label>}
                          </p>

                        </div>

                        <TemplatePageValueOfSchemaHtml key={elementsObjAttrPageInner} name={elementsObjAttrPageInner} object={_.get(existingData, 'template_schema.elements.' + props.elementsObjAttrPage + '.elements.' + elementsObjAttrPageInner + '')} />

                      </div>

                    </div>
                  );

                })}
              </div>}

            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div>
      <IconButton color="primary" onClick={handleClickOpen}>
        <EyeIcon />
      </IconButton>
      {/* <form> */}
      <Dialog
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth="md"
        sx={{
          '.MuiPaper-root': {
            padding: 0,
            margin: 0,
            width: '100%',
            maxHeight: '100%',
            height: '100%'
          },
        }}
      >

        {/* <DialogTitle id="form-dialog-title" className="text-lg-center">View Template</DialogTitle> */}

        <DialogContent >

          {showPdf == false && <div className="col-lg-12 col-12 ">
            <div className="row">
              <div className="col-lg-4 col-4 mb-4">
                <img src={process.env.REACT_APP_IMG_URL + existingData.image} className="avatarprofle me-2" onError={({ currentTarget }) => {
                  currentTarget.onerror = null; // prevents looping
                  currentTarget.src = process.env.REACT_APP_IMG_URL + "uploads/logo.png";
                }} />
              </div>
              <div className="col-lg-6 col-6 mb-4">
                {/* <div className="mb-3">
                  <h4>
                    {existingData?.title}
                  </h4>
                  <p>
                    {existingData?.description}
                  </p>
                </div> */}
                <div className="mb-3">
                  <h4>
                    {existingData?.template_data?.page_question_report_title}
                  </h4>
                  <p>
                    {existingData?.template_data?.page_question_site_name}/{existingData?.template_data?.page_question_report_created_by}/{existingData?.template_data?.page_question_works_date}/{existingData?.template_data?.page_question_report_title}
                  </p>
                </div>
              </div>
              <div className="col-lg-2 col-2 mb-4">
                <img src={process.env.REACT_APP_IMG_URL + cavatar} className="avatarprofle me-2" onError={({ currentTarget }) => {
                  currentTarget.onerror = null; // prevents looping
                  currentTarget.src = process.env.REACT_APP_IMG_URL + "uploads/company.jpg";
                }} />
              </div>
              <div className="col-lg-12 col-12">
                {_return_if_enabled_then_single_point_value_out_of_100_for_question_points_enabled() > 0 &&
                  <>
                    <progress value={_return_total_point_value_out_of_100_for_question_points_enabled()} className="form-control progress-bar" />
                    <PieChart
                      series={[
                        {
                          data: _return_data_for_point_value_out_of_100_for_question_points_enabled(),
                          innerRadius: 30
                        },
                      ]}
                      width={200}
                      height={100}
                      slotProps={{
                        legend: { hidden: true },
                      }}
                    >
                      <PieCenterLabel>{_return_total_point_value_out_of_100_for_question_points_enabled(true)}</PieCenterLabel>
                    </PieChart>
                  </>
                }

              </div>
              <div className="col-lg-12 col-12">

                {
                  Object.keys(existingData?.template_schema?.elements).map((elementsObjAttrPage, indexOuter) => {

                    let _type_of_element = _.get(existingData, 'template_schema.elements.' + elementsObjAttrPage + '.type');
                    let _renderer_of_element = _.get(existingData, 'template_schema.elements.' + elementsObjAttrPage + '.renderer');

                    if (['button-group'].indexOf(_renderer_of_element) === -1) {

                      switch (_type_of_element) {
                        case EnumTemplatesFieldType.container:
                          switch (indexOuter) {
                            default:
                              return (
                                <TemplatesPageSchemaHtml key={elementsObjAttrPage + '_' + indexOuter} currentPageIndex={indexOuter} PageHeaderLable={false} PageHeaderEdit={false} elementsObjAttrPage={elementsObjAttrPage} />
                              );
                              break;
                          }
                          break;
                      }
                    }
                  })}


              </div>


            </div>
          </div>}

          {showPdf == true && <div className="col-lg-12 col-12 h-100">
            <object data={process.env.REACT_APP_IMG_URL + template_schema_pdf_url} type="application/pdf" width="100%" height="100%"></object>
          </div>}

        </DialogContent>

        <DialogActions >

          {template_schema_pdf_url && <button type="button" className="btn btn-light" onClick={toggleViewPDF}>
            {/* {showPdf ? 'Hide' : 'View'} PDF */}
            {isPdfGenerating === true && <>
              <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
              &nbsp;Opening...
            </>}
            {isPdfGenerating === false ? (showPdf ? 'Hide PDF' : 'View PDF') : ''}
          </button>}
          {/* <button type="button" className="btn btn-light" onClick={handlePrint}>
            {isPdfGenerating === true && <>
              <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
              &nbsp;Creating...
            </>}
            {isPdfGenerating === false ? (template_schema_pdf_url ? 'Update PDF' : 'Create PDF') : ''}
          </button> */}
          <button type="button" className="btn btn-light" onClick={handleClose} color="primary">
            Close
          </button>
        </DialogActions>

      </Dialog>
      {/* </form> */}
    </div>
  );
}

export default FormDialogViewInspections;