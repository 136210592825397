import React from 'react'

const Privacy = () => {

  return (
    <>
      <div className='container bg-yellow py-4 mb-4 ptop'>

      </div>

      <div className='container'>
        <div className='text-center mb-4'>
          <h3 className='fw-bold'>Privacy policy</h3>
        </div>
        <div className='row mb-5' style={{textAlign:'justify'}}>
          <div className='col-lg-10 offset-lg-1 col-12 mb-4'>
          <div className='mb-4'>
            <p>Coming soon...</p>
             </div>
           


          </div>

        </div>
      </div>

    </>

  )
}

export default Privacy
