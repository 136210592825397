import React from "react";
import './style.css';
import SubscriptionsTable from "./table/SubscriptionsTable"



const Order = ({ subscriptions, ...props }) => {
    return (
        <React.Fragment>
            <div className='container ptop px-3'>
                <div className="row mb-4">
                <SubscriptionsTable />
                </div>
                
            </div>

        </React.Fragment>
    );
}


export default Order;