import API from "../utils/api";

export const ACTION_TYPES = {
    INSPECTIONS_CREATE: 'INSPECTIONS_CREATE',
    INSPECTIONS_UPDATE: 'INSPECTIONS_UPDATE',
    INSPECTIONS_DELETE: 'INSPECTIONS_DELETE',
    INSPECTIONS_FETCH: 'INSPECTIONS_FETCH',
    INSPECTIONS_FETCH_ALL: 'INSPECTIONS_FETCH_ALL',
    INSPECTIONS_PAGINATION: 'INSPECTIONS_PAGINATION',
    INSPECTIONS_FETCH_RANDOM_INSPECTIONS: 'INSPECTIONS_FETCH_RANDOM_INSPECTIONS',
    INSPECTIONS_CREATE_PDF: 'INSPECTIONS_CREATE_PDF',
}

export const fetchAll = (_userId, onError) => dispatch => {
    API.inspections().fetchAll(_userId)
        .then(res => {

            dispatch({
                type: ACTION_TYPES.INSPECTIONS_FETCH_ALL,
                payload: res.data
            })
        })
        .catch(err => {
            onError({ ...err.response.data, status: err.response.status })
        })

}

export const Pagination = (page = 1, limit = 10, title = "", _userId, onError) => dispatch => {

    API.inspections().fetchPagination(page, Math.abs(limit), title, _userId)
        .then(res => {
            console.log('API.inspections().', res.data)
            dispatch({
                type: ACTION_TYPES.INSPECTIONS_PAGINATION,
                payload: res.data
            })
        })
        .catch(err => {
            onError({ ...err.response.data, status: err.response.status })
        })
}

export const fetchById = (id, onSuccess, onError) => dispatch => {
    API.inspections().fetchById(id)
        .then(res => {
            dispatch({
                type: ACTION_TYPES.INSPECTIONS_FETCH,
                payload: res.data
            })
            onSuccess(res.data)
        })
        .catch(err => {
            onError({ ...err.response.data, status: err.response.status })
        })
}

export const create = (input, onSuccess, onError) => dispatch => {
    API.inspections().create(input)
        .then(res => {
            dispatch({
                type: ACTION_TYPES.INSPECTIONS_CREATE,
                payload: res.data
            })
            onSuccess(res.data)
        })
        .catch(err => {
            onError({ ...err.response.data, status: err.response.status })
        })
}

export const update = (id, input, _userId, onSuccess, onError) => dispatch => {

    API.inspections().update(id, input, _userId)
        .then(res => {
            dispatch({
                type: ACTION_TYPES.INSPECTIONS_UPDATE,
                payload: res.data
            })
            onSuccess(res.data)
        })
        .catch(err => {
            onError({ ...err.response.data, status: err.response.status })
        })

}

export const Delete = (id, onSuccess, onError) => dispatch => {
    API.inspections().delete(id)
        .then(res => {

            dispatch({
                type: ACTION_TYPES.INSPECTIONS_DELETE,
                payload: res.data.id
            })
            onSuccess()
        })
        .catch(err => {
            onError({ ...err.response.data, status: err.response.status })
        })
}

export const fetchRandomInspections = (param, onError) => dispatch => {
    API.inspections().fetchRandomInspections(param)
        .then(res => {

            dispatch({
                type: ACTION_TYPES.INSPECTIONS_FETCH_RANDOM_INSPECTIONS,
                payload: res.data
            })
        })
        .catch(err => {
            onError({ ...err.response.data, status: err.response.status })
        })
}

export const createPdf = (id, input, onSuccess, onError) => dispatch => {

    API.inspections().createPdf(id, input)
        .then(res => {
            dispatch({
                type: ACTION_TYPES.INSPECTIONS_CREATE_PDF,
                payload: res.data
            })
            onSuccess(res.data)
        })
        .catch(err => {
            onError({ ...err.response.data, status: err.response.status })
        })

}