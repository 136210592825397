import { ACTION_TYPES } from "../actions/course";

const initialState = {
    courses: [],
    metaCourse: {}
}

export const course = (state = initialState, action) => {
    switch (action.type) {
        case ACTION_TYPES.COURSE_FETCH_ALL:
            return {
                ...state,
                courses: [...action.payload]
            }
        case ACTION_TYPES.COURSE_CREATE:
            return {
                ...state,
                courses: [...state.courses, action.payload]
            }
        case ACTION_TYPES.COURSE_UPDATE:
            return {
                ...state,
                courses: state.courses.map(x => x.id === action.payload.id ? action.payload : x)
            }
        case ACTION_TYPES.COURSE_DELETE:
            return {
                ...state,
                courses:state.courses.filter(x => x.id !== action.payload)
            }
        case ACTION_TYPES.COURSE_PAGINATION:
         
            return {
                ...state,
                courses: [...action.payload.courses],
                metaCourse: action.payload.meta
            }
            case ACTION_TYPES.COURSE_FETCH_RANDOM_COURSE:
      
                    return {
                        ...state,
                        randomCourses: [...action.payload]
                    }
        default:
            return state;
    }
}