import React ,{useState} from 'react'

import {loadStripe} from '@stripe/stripe-js';
import {Elements, useStripe, useElements,PaymentElement} from '@stripe/react-stripe-js';
import { useNavigate } from "react-router-dom";



const Checkout = (props) => {
    const stripePromise = loadStripe('pk_live_51LZo59AWoFNImYiq5DVItJap48ErCGTXQ3oTskKXnCaMrLR8ViED5EUi1WBhqkZRx7yM3sYw81hByIHWmBlr7ur300yomgSx3f');
    const CheckoutForm = () => {
      const [disableForm, setDisableForm] = useState(false);
      const stripe = useStripe();
      const elements = useElements();
      const history = useNavigate();
    
      const handleSubmit = async (event) => {
        // We don't want to let default form submission happen here,
        // which would refresh the page.
        event.preventDefault();
        setDisableForm(true)
        if (!stripe || !elements) {
          // Stripe.js has not yet loaded.
          // Make sure to disable form submission until Stripe.js has loaded.
          return;
        }
    
        const result = await stripe.confirmPayment({
          //`Elements` instance that was used to create the Payment Element
          elements,
          confirmParams: {
            return_url: "https://solarspect.io/commercial/dashboard",
          },
          redirect: 'if_required' 

        });
    
    
        if (result.error) {
          props.paymentStatus(false,result.error.message)
          // Show error to your customer (for example, payment details incomplete)
          console.log(result.error.message);
          setDisableForm(false)
        } else {
          console.log(result);
          props.paymentStatus(true,result)
          setDisableForm(false)
            //history.push("/order-complete",result)
          // Your customer will be redirected to your `return_url`. For some payment
          // methods like iDEAL, your customer will be redirected to an intermediate
          // site first to authorize the payment, then redirected to the `return_url`.
        }
      };
    
      return (
        <form className={disableForm?'readonlyform':''} onSubmit={handleSubmit}>
         {disableForm && <div className='text-center mb-3 h3 font-weight-lighter'>
          <div className="spinner-border text-dark"> </div> Fetching card details...
          </div>}
         
          <PaymentElement />
          <button className='btn btn-primary btn-lg w-100 mt-3' disabled={!stripe}>Submit</button>
        </form>
      )
    };
      return (
        <>
        {/* <Banner title="Checkout" /> */}
        <div className='row pt-5'>
        <div className='col-lg-3 offset-lg-1'>
        <div class="card mb-4 rounded-3 shadow-sm">
                                    <div class="card-header py-3 text-center">
                                        <h4 class="my-0 fw-normal">Premium</h4>
                                    </div>
                                    <div class="card-body text-center">
                                        <h1 class="card-title pricing-card-title">{props.isannual?'$529/yr' : '$49/mo'}</h1>
                                        <ul class="list-unstyled mt-3 mb-4">
                                            <li>Unlimited Templates</li>
                                            <li>Unlimited Inspections</li>
                                            <li>Phone and email support</li>
                                            <li>Help center access</li>
                                        </ul>
                                        <button type="button" class="w-100 btn btn-lg btn-primary">{props.isannual?'Annual':'Monthly'} Plan</button>
                                    </div>
                                </div>
        </div>
            <div className='col-lg-7 '>
                <h4>Checkout Now</h4>
                <p className='mb-5'>A better way of working starts here, Securly pay with card</p>
            <Elements stripe={stripePromise} options={props.stripeOptions}>
              <CheckoutForm />
            </Elements>
            </div>
        </div>
        
        </>
      );
    
}

export default Checkout

