import React from 'react'
import './style.css'
import howit from '../../../images/howit.mp4'
import howitpng from '../../../images/howit.png'
const Howworks = () => {

    return (
        <>


            <div className='container'>
                <div className='text-center mb-4'>
                    <h3 className='fw-bold'>¿Qué es y cómo funciona ClassForUs?</h3>
                </div>
                <div className='row mb-5'>
                    <div className='col-lg-8 offset-lg-2 col-12 mb-4'>
                        <div className="ratio ratio-16x9 text-center mt-4 mb-4 ">
                        <video src={howit} controls preload="none" poster={howitpng} className="w-100"/>
                            {/* <iframe width="560" height="315" src="https://www.youtube.com/embed/YrPUcOZWXOM?controls=0" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe> */}
                        </div>

                        {/* <p className='py-4'>Enlace de descarga tutorial</p> */}

                        <h4 className='text-green mb-4'>Recomendaciones</h4>

                        <ol className='ols' start="1">
                            <li>Comprueba que tienes una buena conexión a internet.</li>
                            <li>Prepara la clase dentro de la plataforma para evitar repetidas pausas.</li>
                            <li>Prueba las diferentes funcionalidades de la plataforma para mejorar tu experiencia y la de los estudiantes.</li>
                            <li>Piensa cómo puedes mejorar tus clases con las herramientas disponibles en la plataforma. </li>

                        </ol>

                    </div>

                </div>
            </div>

        </>

    )
}

export default Howworks
