import API from "./api";
import * as actions from "../actions/inspections";
import { toast } from 'react-toastify';
import { initialFormStateInspections } from "./enums";
const handleFormSubmitedSchemaEvent = (handleClose, formValues, event) => {
    const onSuccess = () => {
        //setOpen(false);
        toast.success('Inspection Saved');
        if (handleClose) {
            handleClose();
        }
    }
    let existingData = initialFormStateInspections;
    existingData.template_data = formValues;
    existingData.report_title = (formValues?.page_question_report_title) || (existingData.report_title || "");
    actions.create(existingData, onSuccess);
}
const handleFormUploadSchemaEvent = (formik, config, acceptedFiles) => {
    const id = toast.loading("Please wait, file upload is in progress.")
    //do something else

    // console.log('22 :: ', formik, config, acceptedFiles);
    let _current_field_key = config.name;
    if (!formik.values) {
        formik.values = {};
    }
    formik.values[_current_field_key] = formik.values[_current_field_key] || [];
    let _existing_files_data_arr = formik.values[_current_field_key] || [];
    let myFiles = Array.from(acceptedFiles);
    let count_of_files = myFiles.length;
    let count_of_files_success_or_failed = 0;
    myFiles.map((file => {
        // if (file.size / 1024 > 200) {
        //     toast.warning('maximum size allowed 200 kb');
        //     return false;
        // }
        var formdata = new FormData();
        formdata.append("file", file);
        formdata.append("documentId", Date.now());
        console.log('res/data', file)
        API.utility().upload(formdata)
            .then(res => {
                count_of_files_success_or_failed = count_of_files_success_or_failed + 1;
                if (count_of_files_success_or_failed >= count_of_files) {
                    toast.update(id, { render: "Upload done!", type: "success", isLoading: false, closeButton: true, autoClose: 5000, closeOnClick: true });
                }
                if (res?.data?.data?.path) {
                    _existing_files_data_arr.push(res.data.data.path);
                    //formik.setFieldValue(_current_field_key, myFiles[0]);//not working
                    //formik.setFieldValue(_current_field_key, myFiles);//working but it saves file object array
                    //formik.setFieldValue(_current_field_key, 'set-this-later');//not working
                    //formik.setFieldValue(_current_field_key, ['set-this-later']);//working but it need to uploaded file right now here
                    formik.setFieldValue(_current_field_key, _existing_files_data_arr);
                }
            }).catch(err => {
                count_of_files_success_or_failed = count_of_files_success_or_failed + 1;
                if (count_of_files_success_or_failed >= count_of_files) {
                    toast.update(id, { render: "Upload done!", type: "success", isLoading: false, closeButton: true, autoClose: 5000, closeOnClick: true });
                }
            });
    }));
}

export { handleFormSubmitedSchemaEvent, handleFormUploadSchemaEvent }