
import React from "react";
import './style.css';
import UserTable from "./table/UserTable"



const User = ({ classes, ...props }) => {
    return (
        <React.Fragment>
            <div className='container ptop px-3'>
                <div className="row mb-4">
                <UserTable />
                </div>
                
            </div>

        </React.Fragment>
    );
}


export default User;