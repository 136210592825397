import React, { useEffect, useState, useContext } from "react";
import moment from 'moment/min/moment-with-locales';
import { AuthContext } from '../../../context/AuthContext'
import API from '../../../utils/api'
import Swal from 'sweetalert2'
import * as actions from "../../../actions/inspections";
import * as templates from "../../../actions/templates";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

const Dashboard = ({ ...props }) => {
  const { getAuthUser } = useContext(AuthContext)
  const authUser = getAuthUser()
  console.log('authUser', authUser)
  useEffect(() => {
    props.fetchInspections()
    props.fetchTemplates()
  }, [])

  //   useEffect(() => {
  //     console.log('props.templates', props)
  // }, [props])
  return (
    <>
      <div className='container-fluid ptop'>
        <div className="container px-0">
          {/* Page Heading */}
          <div className="d-sm-flex align-items-center justify-content-between mb-4">
            <h1 className="h3 mb-0 text-gray-800">Dashboard</h1>
            <Link className="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm" to={"/" + authUser?.role + "/inspections"} ><i className="fas fa-chart-pie " />  Go to Inspections</Link>

          </div>
          {/* Content Row */}
          <div className="row">

            <div className="col-xl-3 col-md-6 mb-4">
              <div className="card  shadow h-100 py-2">
                <div className="card-body">
                  <div className="row no-gutters align-items-center">
                    <div className="col mr-2">
                      <div className="text-xs font-weight-bold text-warning text-uppercase mb-1">
                        Templates
                      </div>
                      <div className="h3 mb-0 font-weight-bold text-gray-800 text-primary">
                        {props?.metatemplate?.totalDocs}
                      </div>
                    </div>
                    <div className="col-auto">
                      <i className="fas fa-file fa-2x text-black-50" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-md-6 mb-4">
              <div className="card  shadow h-100 py-2">
                <div className="card-body">
                  <div className="row no-gutters align-items-center">
                    <div className="col mr-2">
                      <div className="text-xs font-weight-bold text-warning text-uppercase mb-1">
                        Inspections
                      </div>
                      <div className="h3 mb-0 font-weight-bold text-gray-800 text-primary">
                        {props?.metainspection?.totalDocs}
                      </div>
                    </div>
                    <div className="col-auto">
                      <i className="fas fa-chart-pie fa-2x text-black-50" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {authUser?.role !== 'admin' && <div className="col-xl-3 col-md-6 mb-4">
              <div className="card  shadow h-100 py-2">
                <div className="card-body">
                  <div className="row no-gutters align-items-center">
                    <div className="col mr-2">
                      <div className="text-xs font-weight-bold text-warning text-uppercase mb-1">
                        Plan
                      </div>
                      <div className="h3 mb-0 font-weight-bold text-gray-800 text-primary">
                        {(authUser?.role === 'commercial') ? authUser?.plantype : 'Free'}
                      </div>
                    </div>
                    <div className="col-auto">
                      <i className="fas fa-bookmark fa-2x text-black-50" />
                    </div>
                  </div>
                </div>
              </div>
            </div>}
            {authUser?.role !== 'admin' && <div className="col-xl-3 col-md-6 mb-4">
              <div className="card  shadow h-100 py-2">
                <div className="card-body">
                  <div className="row no-gutters align-items-center">
                    <div className="col mr-2">
                      <div className="text-xs font-weight-bold text-warning text-uppercase mb-1">
                        Validity
                      </div>
                      <div className="h3 mb-0 font-weight-bold text-gray-800 text-primary">
                        {moment(authUser?.planexpiry).format("ll")}
                      </div>
                    </div>
                    <div className="col-auto">
                      <i className="fas fa-calendar fa-2x text-black-50" />
                    </div>
                  </div>
                </div>
              </div>
            </div>}

          </div>
          {/* Content Row */}
          <div className="row mt-4">

            <div className="col-xl-12 col-12">

           {authUser?.role==='residential' &&  <div class="alert alert-danger" role="alert">
            <div className="row">
              <div className="col-lg-10">
              You are currently on a free membership which allows you to carry out 4 residential inspections this month. To have unlimited residential inspections and access to our commercial templates, please upgrade now!
              </div>
              <div className="col-lg-2">
              <Link className="btn btn-danger w-100 btn-lg" to={"/" + authUser?.role + "/pricing"}>Upgrade Now</Link>
              </div>
              </div>
                 
              </div>}

              {authUser?.role==='commercial' &&  <div class="alert alert-success" role="alert">
               
You are currently on a premium membership, which grants you the ability to conduct unlimited residential and commercial inspections each month.   
              </div>}
            </div>
          </div>
          {/* Content Row */}

        </div>


      </div>

    </>
  )
}


const mapStateToProps = state => ({
  metainspection: state?.inspections?.metaInspection,
  metatemplate: state?.templates?.metaTemplate
})

const mapActionToProps = {
  fetchInspections: actions.Pagination,
  fetchTemplates: templates.Pagination,
}

export default connect(mapStateToProps, mapActionToProps)(Dashboard);
