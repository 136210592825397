import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { IconButton } from '@mui/material';
import DeleteIcon from "@mui/icons-material/Delete";
import { toast } from 'react-toastify';
import Grow from '@mui/material/Grow';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Grow ref={ref} {...props} />;
});

const FormDialogDeleteTemplates = (props) => {
  const [open, setOpen] = useState(false);
  const [userId, setUserId] = useState(null);
  const [serverActionIsInProgress, setServerActionIsInProgress] = useState(false);

  const onError = (err) => {
    setServerActionIsInProgress(false);
    if (err?.show_message2user) {
      props.refresh();
      setOpen(false);
      toast.error(err.message);
    }
  };
  useEffect(() => {
    setUserId(props.dataTemplates[0])
  }, [props.dataTemplates])

  const handleOpen = () => {
    setOpen(true);
  }

  const handleClose = () => {
    setOpen(false);
  }

  const handleSubmit = (e) => {
    const onSuccess = () => {
      setServerActionIsInProgress(false);
      props.refresh()
      setOpen(false);
      toast.success('Data succesfully deleted');
    }
    e.preventDefault();
    if (serverActionIsInProgress == false) {
      setServerActionIsInProgress(true);
      props.delete(userId, onSuccess, onError);
    }
  }

  return (
    <div>
      <IconButton style={{ color: 'red' }} onClick={handleOpen}>
        <DeleteIcon />
      </IconButton>
      <Dialog
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
        aria-labelledby="form-dialog-title"
        fullWidth
        disablePortal
      >
        <DialogTitle id="form-dialog-title" className="text-lg-center">Delete Template</DialogTitle>

        <DialogContent style={{ padding: "30px 30px 10px 30px" }}>
          <DialogContentText>
            Are you sure want to delete this record?
          </DialogContentText>
        </DialogContent>

        <DialogActions style={{ padding: 30 }}>
          <Button variant="contained" onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button variant="contained" onClick={handleSubmit} color="secondary">
            Delete
          </Button>
        </DialogActions>

      </Dialog>
    </div>
  );
}

export default FormDialogDeleteTemplates;