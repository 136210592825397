import React, { useState, useContext, useEffect } from "react";
import { AuthContext } from "../../context/AuthContext";
import { Link, useNavigate, useLocation } from "react-router-dom";
import facebook from '../../images/facebook.png'
import insta from '../../images/insta.png'
import linked from '../../images/linked.png'

export default function Footer(props) {
  let location = useLocation();
  const [currency, setCurrency] = useState(localStorage.getItem("currency"));
  const { logout, getAuthUser } = useContext(AuthContext)
  const authUser = getAuthUser()




  const currencyChange = (currencytochange) => {

    var requestURL = 'https://api.exchangerate.host/convert?from=EUR&to=USD';
    var request = new XMLHttpRequest();
    request.open('GET', requestURL);
    request.responseType = 'json';
    request.send();

    request.onload = function () {
      var response = request.response;
      localStorage.setItem('currency', currencytochange)
      if(currencytochange==='EUR'){
        localStorage.setItem('exchangerate', "1")
      }else{
        localStorage.setItem('exchangerate', response.result)
      }
      setCurrency(currencytochange)
      window.location.reload();
    }

  }

  const scrollToTop = () => {
    window.scrollTo({
        top: 0,
        behavior: "smooth",
    });
};

  return (
    <>

<div className={`container-fluid bg-dark text-light footer mt-5 py-5 wow fadeIn ${location?.pathname?.includes('mobile-') ? "mobilehide" : ""}`}  data-wow-delay="0.1s">
        <div className="container py-5">
            <div className="row g-5">
                <div className="col-lg-3 col-md-6 offset-lg-2">
                    <h4 className="text-white mb-4">Contact Us</h4>
              
                    {/* <p className="mb-2"><i className="fa fa-phone-alt me-3"></i>+012 345 67890</p> */}
                    <p className="mb-2"><i className="fa fa-envelope me-3"></i>support@solarspect.com</p>
                 
                </div>
                {/* <div className="col-lg-3 col-md-6">
                    <h4 className="text-white mb-4">Services</h4>
                    <a className="btn btn-link" href="">Financial Planning</a>
                    <a className="btn btn-link" href="">Cash Investment</a>
                    <a className="btn btn-link" href="">Financial Consultancy</a>
                    <a className="btn btn-link" href="">Business Loans</a>
                    <a className="btn btn-link" href="">Business Analysis</a>
                </div> */}
                <div className="col-lg-3 col-md-6">
                    <h4 className="text-white mb-4">Quick Links</h4>
                    {/* <Link className="btn btn-link" to="/about" onClick={scrollToTop}>About Us</Link>
             
                    <Link className="btn btn-link" to="/help" onClick={scrollToTop}>FAQ</Link> */}
                    <Link className="btn btn-link" to="/contact" onClick={scrollToTop}>Contact Us</Link>
                    {/* <Link className="btn btn-link" to="/legal" onClick={scrollToTop}>Legal</Link> */}
                    {/* <Link className="btn btn-link" to="/privacy-policies" onClick={scrollToTop}>Privacy policy</Link> */}
                    {/* <Link className="btn btn-link"  to="/cookies-information" onClick={scrollToTop}>Política de cookies</Link> */}
                    {/* <Link className="btn btn-link"  to="/conditions" onClick={scrollToTop}>Terms & Condition</Link> */}
                </div>
                <div className="col-lg-3 col-md-6">
                    <h4 className="text-white mb-3">Social Media</h4>
                    <div className="d-flex pt-2">
                        <a className="btn btn-square btn-outline-light rounded-circle me-2" href="https://www.facebook.com/solarspect.crm" target="_blank"><i
                                className="fab fa-facebook-f"></i></a>
                        {/* <a className="btn btn-square btn-outline-light rounded-circle me-2" href=""><i
                                className="fab fa-twitter"></i></a>
                        <a className="btn btn-square btn-outline-light rounded-circle me-2" href=""><i
                                className="fab fa-youtube"></i></a>
                        <a className="btn btn-square btn-outline-light rounded-circle me-2" href=""><i
                                className="fab fa-linkedin-in"></i></a> */}
                    </div>
                </div>
            </div>
        </div>
    </div>
       <div  className={`container-fluid copyright py-4 ${location?.pathname?.includes('mobile-') ? "mobilehide" : ""}`}>
        <div className="container">
            <div className="row">
                <div className="col-md-12  text-center mb-3 mb-md-0">
                    &copy; <a className="border-bottom" href="#">Solarspect</a>  All Right Reserved.
                </div>
               
            </div>
        </div>
    </div>
     
     
    </>
  );
}
