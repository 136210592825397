import React from "react";
import './style.css';
import ClassesTable from "./table/ClassesTable"



const Classes = ({ classes, ...props }) => {
    return (
        <React.Fragment>
            <div className='container'>
                <div className="row mb-4">
                <ClassesTable />
                </div>
                
            </div>

        </React.Fragment>
    );
}


export default Classes;