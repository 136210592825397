import React from 'react'

const Help = () => {

    return (
        <>
            <div className='container bg-yellow py-4 mb-4 ptop'>

            </div>

            <div className='container'>
                <div className='text-center mb-4'>
                    <h3 className='fw-bold'>FAQs</h3>
                </div>
                <div className='row'>
                    <div className='col-lg-10 offset-lg-1 col-12 mb-4'>
                        <div className="accordion" id="accordionExample">
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="headingOne">
                                    <button className="accordion-button bg-white fw-bold text-green" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                    What Is Solarspect?

                                    </button>
                                </h2>
                                <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                    <div className="accordion-body">
                                    Solarspect is cutting-edge solar panel cleaning reporting software.


                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="headingTwo">
                                    <button className="accordion-button bg-white fw-bold text-green collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                    How Detailed Are The Inspection Reports?                                    </button>
                                </h2>
                                <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                    <div className="accordion-body">
                                    Each report is at least 9 pages in length, with dozens of data points.
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="headingThree">
                                    <button className="accordion-button bg-white fw-bold text-green collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                    What Will My Clients Gain From My Using Solarspect?
                                    </button>
                                </h2>
                                <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                    <div className="accordion-body">
                                    Put yourself ahead of your competition by providing your clients with the most comprehensive solar
panel cleaning reporting available. You can email your reports to your clients, direct from the app.
Impress your clients by cleaning, inspecting and sending the report to them within 30 minutes of the
clean being completed!
                                    </div>
                                </div>
                            </div>


                            <div className="accordion-item">
                                <h2 className="accordion-header" id="headingThree">
                                    <button className="accordion-button bg-white fw-bold text-green collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse4" aria-expanded="false" aria-controls="collapse4">
                                    Are the Reports Country-Specific?
                                    </button>
                                </h2>
                                <div id="collapse4" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                    <div className="accordion-body">
                                    Yes! As Solarspect grows internationally, so do our report templates. We provide the latest in solar
panel cleaning reporting for your country!
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="headingThree">
                                    <button className="accordion-button bg-white fw-bold text-green collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse5" aria-expanded="false" aria-controls="collapse5">
                                    What Am I Waiting For? Why Don’t I Buy Now?
                                    </button>
                                </h2>
                                <div id="collapse5" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                    <div className="accordion-body">
                                    We don’t know! Click here to sign up!
                                    </div>
                                </div>
                            </div>
                        
                           
                        </div>
                    </div>

                </div>


            </div>

        </>

    )
}

export default Help
