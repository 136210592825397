import React from 'react'

const Cookies = () => {

  return (
    <>
      <div className='container bg-yellow py-4 mb-4 ptop'>

      </div>

      <div className='container'>
        <div className='text-center mb-4'>
          <h3 className='fw-bold'>Política de Cookies</h3>
        </div>
        <div className='row mb-5' style={{textAlign:'justify'}}>
          <div className='col-lg-10 offset-lg-1 col-12 mb-4'>

            <div className='mb-4'>
              <p className='mb-3'>Utilizamos “Cookies”, con información sobre su navegación en la Plataforma y cuya finalidad principal es facilitar su navegación en la misma. Por favor, continúe leyendo para conocer con mayor detalle las cookies que usamos, su finalidad y otra información de interés. </p>

              <h5 className='text-green'>¿Qué es una Cookie? </h5>

              <p className='mb-3'>Una Cookie es un pequeño fichero de texto que un sitio web coloca en su PC, teléfono o cualquier otro dispositivo, con información sobre su navegación en dicho sitio. Las Cookies son necesarias para facilitar la navegación y hacerla más amigable, y no dañan su ordenador. Si bien en la presente política se utiliza el término general de “Cookie”, pues es el principal método de almacenamiento de información que utiliza este sitio web, también es utilizado el espacio de “Almacenamiento local” del navegador para los mismos propósitos que las Cookies. En este sentido, toda la información incluida en esta sección es aplicable igualmente a este “Almacenamiento local”. </p>


              <h5 className='text-green'>¿Para qué se utilizan las Cookies en esta Plataforma? </h5>
              <p className='mb-3'>Las Cookies son una parte esencial de cómo funciona nuestra Plataforma. El objetivo principal de uso en nuestro sitio es el de mejorar su experiencia en la navegación. Por ejemplo, son utilizadas para recordar sus preferencias (idioma, país, etc.) durante la navegación y para futuras visitas. </p>
              <p className='mb-3'>La información recogida en las Cookies nos permite, además, mejorar la web/app mediante estimaciones sobre números y patrones de uso, la adaptación del sitio web a los intereses individuales de los usuarios, la aceleración de las búsquedas, etc. </p>
              <p className='mb-3'>En ocasiones, y si hemos obtenido su previo consentimiento informado, podríamos utilizar Cookies, tags u otros dispositivos similares para obtener información que nos permita mostrarle desde nuestro sitio web, los de terceros, o cualquier otro medio o publicidad basada en el análisis de sus hábitos de navegación. </p>

              <h5 className='text-green'>¿Quién utiliza la información almacenada en las Cookies? </h5>
              <p className='mb-3'>La información almacenada en las Cookies de nuestro sitio web es utilizada exclusivamente por nosotros, a excepción de aquellas identificadas más adelante como “Cookies de terceros”, que son utilizadas y gestionadas por entidades externas (entidades colaboradoras de CLASS FOR US) para proporcionarnos servicios solicitados por nosotros para mejorar la experiencia del usuario al navegar en nuestro sitio web y/o App. Los principales servicios para los que se utilizan estas “Cookies de terceros” son la obtención de estadísticas de accesos. </p>

              <h5 className='text-green'>¿Cómo puedo evitar el uso de Cookies en esta Plataforma? </h5>
              <p className='mb-3'>Si usted prefiere evitar el uso de Cookies en esta Plataforma teniendo en cuenta las limitaciones anteriores debe, en primer lugar, deshabilitar en su navegador el uso de Cookies y, en segundo lugar, eliminar las Cookies guardadas en su navegador asociadas a nuestra Plataforma. </p>
              <p className='mb-3'>Esta posibilidad de evitar el uso de Cookies puede ser llevada a cabo por usted en cualquier momento como más abajo se indica. </p>


              <h5 className='text-green'>¿Cómo deshabilito y elimino la utilización de Cookies? </h5>
              <p className='mb-3'>Para restringir, bloquear o borrar las Cookies de este sitio web puede hacerlo, en cualquier momento, modificando la configuración de su navegador conforme a las pautas que se indican a continuación. Si bien la parametrización de cada navegador es diferente, es habitual que la configuración de las Cookies se realice en el menú de “Preferencias” o “Herramientas”. </p>


              <h5 className='text-green'>¿Qué Cookies concretas utiliza esta Plataforma y para qué finalidades? </h5>
              <p className='mb-3'>A continuación indicamos las Cookies, tags u otros dispositivos similares utilizados por esta Plataforma, junto con la información sobre el propósito, duración y gestión (propia o por terceros) de cada uno de ellos. </p>
              <p className='mb-3'>En el caso de cambios, actualización o incorporación de nuevas cookies se actualizará en nuestro sitio web esta información con la mayor celeridad posible a medida que cambien los servicios ofrecidos en la misma. No obstante y de forma puntal durante dicha actualización, podría ocurrir que esta información dejara de incluir alguna de las cookies, tags u otros similares, si bien se tratará siempre con finalidades idénticas a las que aquí constan: </p>
              <p className='mb-3'>Cookies de Sesión: CLASS FOR US usa “G_ENABLED_IDPS” para poder identificar al usuario y que pueda de esta forma ingresar a su espacio como estudiante o profesor a través de una cuenta de Gmail. Google Inc. es una compañía adherida al Acuerdo de Puerto Seguro (“safe harbour”) sobre el procesado y la protección de datos transferidos entre la UE y EEUU y que garantiza que todos los datos transferidos serán tratados con un nivel de protección acorde a la normativa europea. Información adicional sobre la cookie: </p>

              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th>COOKIE</th>
                    <th>TIPO</th>
                    <th>DURACIÓN</th>
                    <th>DESCRIPCIÓN</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>G_ENABLED_IDPS</td>
                    <td>Terceros</td>
                    <td>Siempre</td>
                    <td>Se usa para “Iniciar sesión con Google”</td>
                  </tr>
                </tbody>
              </table>


              <p className='mb-3'>De igual manera CLASS FOR US usa “fblo_859529925371636” para poder identificar al usuario y que pueda de esta forma ingresar a su espacio como estudiante o profesor a través de una cuenta de Facebook. Meta Platforms, Inc. ("Meta") obtuvo la certificación del marco del Escudo de la privacidad de la Unión Europea y los Estados Unidos y del marco del Escudo de la privacidad de Suiza y los Estados Unidos. Información adicional sobre la cookie:</p>

              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th>COOKIE</th>
                    <th>TIPO</th>
                    <th>DURACIÓN</th>
                    <th>DESCRIPCIÓN</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>fblo_859529925371636</td>
                    <td>Terceros</td>
                    <td>Siempre</td>
                    <td>Se usa para “Iniciar sesión con Google”</td>
                  </tr>
                </tbody>
              </table>
              <h5 className='text-green'>Más información sobre el uso de cookies </h5>


              <p className='mb-3'>El usuario podrá en cualquier momento elegir qué cookies quiere que funcionen en este sitio web mediante la configuración del navegador; por ejemplo: </p>

              <p>Chrome, desde: <a href='https://support.google.com/chrome/answer/95647?hl=es' target='_blank'>https://support.google.com/chrome/answer/95647?hl=es</a></p>
              <p>Microsoft Edge, desde: <a href='https://support.microsoft.com/es-es/windows/eliminar-y-administrar-cookies-168dab11-0753-043d-7c16-ede5947fc64d#ie=ie-11' target='_blank'>https://support.microsoft.com/es-es/windows/eliminar-y-administrar-cookies-168dab11-0753-043d-7c16-ede5947fc64d#ie=ie-11</a></p>

              <p>Firefox, desde: <a href='https://support.mozilla.org/es/kb/Deshabilitar%20cookies%20de%20terceros' target='_blank'>https://support.mozilla.org/es/kb/Deshabilitar%20cookies%20de%20terceros</a></p>
              <p>Safari, desde: <a href='https://support.apple.com/es-es/guide/safari/sfri11471/mac' target='_blank'>https://support.apple.com/es-es/guide/safari/sfri11471/mac</a></p>

              <p>Opera, desde: <a href='https://help.opera.com/en/latest/web-preferences/#cookies' target='_blank'>https://help.opera.com/en/latest/web-preferences/#cookies</a></p>

              <p className='my-3'>Los navegadores web son las herramientas encargadas de almacenar las cookies y desde este lugar el usuario puede efectuar su derecho de eliminación o desactivación de las mismas. </p>
              <p className='mb-3'>Ni esta web ni sus representantes legales pueden garantizar la correcta o incorrecta manipulación de las cookies por parte de los mencionados navegadores. </p>
              <p className='mb-3'>La navegación y/o la posible compra de cualquier producto online conlleva la aceptación de la presente política de cookies e implica que el usuario ha sido informado de una forma clara y completa sobre el uso de los dispositivos de almacenamiento y recuperación de datos (cookies), así como que CLASS FOR US dispone del consentimiento del usuario para el uso de las mismas tal y como establece el artículo 22.2 de la Ley 34/2002, de 11 de julio y actualizada el 29/12/2007 y en vigor a partir del 30/12/2007, de Servicios de la Sociedad de la Información y Comercio Electrónico (LSSICE).</p>


            </div>




          </div>

        </div>
      </div>

    </>

  )
}

export default Cookies
