import React, { useState, useContext } from "react";

import TextField from "@mui/material/TextField";
import Dialog from '@mui/material/Dialog';
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { IconButton, Select, MenuItem, InputLabel, FormControl, Checkbox, FormControlLabel } from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { toast } from 'react-toastify';
import Grow from '@mui/material/Grow';
import API from "../../../../utils/api";
import { initialFormStateTemplate } from "../../../../utils/enums";
import moment from "moment";
import { AuthContext } from "../../../../context/AuthContext";
import { Col, Form } from "react-bootstrap";
import RichTextEditor from 'react-rte';
import EditIcon from '@mui/icons-material/Edit';
import './../style.css';
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Grow ref={ref} {...props} />;
});


const FormDialogAddTemplates = (props) => {
  const { getAuthUser } = useContext(AuthContext)
  const authUser = getAuthUser()
  const [existingData, setTemplates] = useState(initialFormStateTemplate);
  const [avatar, setAvatar] = useState(existingData?.image);

  const [open, setOpen] = useState(false);
  const [openselectbox, setOpenselectbox] = useState(false);
  const [errors, setErrors] = useState({})
  const [htmlDesc, setHtmlDesc] = useState(RichTextEditor.createValueFromString('', 'html'))
  const [templateType, setTemplateType] = useState(existingData?.type || "residential");
  const [serverActionIsInProgress, setServerActionIsInProgress] = useState(false);

  const onError = (err) => {
    setServerActionIsInProgress(false);
    if (err?.show_message2user) {
      props.refresh();
      setOpen(false);
      toast.error(err.message);
    }
  };

  const templateTypeChange = (templateTypeToChange) => {

      setTemplateType(templateTypeToChange);    

  }

  const handleClickOpen = () => {
    setErrors({});
    setTemplates({ ...existingData, createdBy: authUser.id });
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleInputChangeformultiselect = event => {
    const { name, value } = event.target

    if (existingData[name].includes(value)) {
      setTemplates({ ...existingData, [name]: existingData[name].filter(v => v !== value) })

    } else {
      setTemplates({ ...existingData, [name]: [...existingData[name], value] })
    }

  };

  const handleInputChange = event => {
    const { name, value } = event.target
    setTemplates({ ...existingData, [name]: value })
  };

  const handleHtmlInputChange = value => {
    setHtmlDesc(value)
    setTemplates({ ...existingData, description: value.toString('html') })
  };

  const handleInputChangeForFileType = event => {


    // if (event.target.files[0].size / 1024 > 200) {
    //   toast.warning('maximum size allowed 200 kb');
    //   return
    // }

    var formdata = new FormData();
    formdata.append("file", event.target.files[0]);
    formdata.append("documentId", Date.now());
    console.log('res/data', event.target.files[0])
    API.utility().upload(formdata)
      .then(res => {

        if (res?.data?.data?.path) {
          setTemplates({ ...existingData, image: res.data.data.path });
          setAvatar(res.data.data.path);
        }
      });
  };

  const validate = () => {
    let tempErrors = {};
    let formIsValid = true;

    if (!existingData.title) {
      formIsValid = false;
      tempErrors["title"] = "Cannot be empty";
    }

    if (!existingData.description) {
      formIsValid = false;
      tempErrors["description"] = "Cannot be empty";
    }

    if (existingData.image === 'view-media/class.jpg') {
      // formIsValid = false;
      // tempErrors["image"] = "Cannot be empty";
      // toast.warning('Thumbnail cannot be empty');
    }

    setErrors(tempErrors);
    return formIsValid;
  }

  const handleSubmit = (e) => {

    if (validate() && serverActionIsInProgress==false) {
      const onSuccess = () => {
        setServerActionIsInProgress(false);
        setTimeout(
          () => props.refresh(),
          1000
        );
        setOpen(false);
        toast.success('Template added');
        // Swal.fire({
        //   title: 'Yay!',
        //   text: 'Template added',
        //   icon: 'success',
        //   showConfirmButton: false,
        //   timer: 2000
        // });
      };
      setServerActionIsInProgress(true);
      if (existingData.id) {
        props.update(existingData.id, existingData, onSuccess, onError);
      } else {
        props.create(existingData, onSuccess, onError);
      }

    }
    e.preventDefault();

  };


  return (
    <>
      <button className="btn rounded-0 mt-2" onClick={handleClickOpen}><AddCircleIcon style={{ fontSize: "18px" }} /> {'New'}</button>
      <form onSubmit={(e) => { e.preventDefault(); handleSubmit(e) }}>
        <Dialog
          open={open}
          onClose={handleClose}
          TransitionComponent={Transition}
          aria-labelledby="form-dialog-title"
          fullWidth
          disablePortal
        >

          <DialogTitle id="form-dialog-title">{'Create Template'}</DialogTitle>

          <DialogContent >

            <div className="row">
              <div className="col-lg-12 col-12 mb-4">
                <img src={process.env.REACT_APP_IMG_URL + avatar} className="me-2" style={{ maxHeight: 60 }} onError={({ currentTarget }) => {
                      currentTarget.onerror = null; // prevents looping
                      currentTarget.src = process.env.REACT_APP_IMG_URL +"uploads/logo.png";
                }}
                />

                <input type="file" id="file" className="d-none" onChange={handleInputChangeForFileType} />
                <label htmlFor="file" className="btn bg-green rounded-0 mt-2" title="Update Logo">
                  <EditIcon />
                </label>
              </div>

            </div>
            <div className="row mb-4">
              <div className="col-lg-12 col-12">

                <div className="mb-3">
                <label className="form-label fw-bold">Title*</label>
                <select className="form-select" onChange={handleInputChange} value={existingData?.type} name="type">
                    <option value="commercial">Commercial</option>
                    <option value="residential">Residential</option>
                  </select>


                </div>


                <div className="mb-3">
                  <label className="form-label fw-bold">Title*</label>
                  <input onChange={handleInputChange} value={existingData?.title} name="title" type="text" className="form-control border-green rounded-0 mb-3" required />
                </div>

                <div className="mb-3">
                  <label className="form-label fw-bold">Description*</label>
                  <textarea rows={5} onChange={handleInputChange} value={existingData?.description} name="description" type="text" className="form-control border-green rounded-0 mb-3" required />
                </div>
              </div>

            </div>

          </DialogContent>

          <DialogActions >
            <button type="button" className="btn btn-warning" onClick={handleClose} color="primary">
              Cancel
            </button>
            <button type='submit' className="btn btn-primary" >
              Save
            </button>
          </DialogActions>

        </Dialog>
      </form>
    </>
  );
}

export default FormDialogAddTemplates;