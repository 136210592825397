import React, { useEffect, useState, useContext } from "react";

import MUIDataTable from "mui-datatables";
import { connect } from "react-redux";
import * as actions from "../../../../actions/order";
// import FormDialogAddClasses from "../formDialog/FormDialogAddClasses";
// import FormDialogEditClasses from "../formDialog/FormDialogEditClasses";
// import FormDialogDeleteClasses from "../formDialog/FormDialogDeleteClasses";
import moment from 'moment/min/moment-with-locales';
import { Link, useNavigate, useLocation } from "react-router-dom";
import PlayCircle from '@mui/icons-material/PlayCircle';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import { AuthContext } from "../../../../context/AuthContext";

const SubscriptionsTable = ({ classes, ...props }) => {
    let location = useLocation();
    const { getAuthUser } = useContext(AuthContext)
    const authUser = getAuthUser()
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(10)

    useEffect(() => {
        props.fetchPagination(1, rowsPerPage, null, null)

    }, [location?.pathname])

    const handleChangePage = async (newPage) => {
        await setPage(newPage);
        props.fetchPagination(newPage + 1, rowsPerPage, null, null)
    };

    const handleChangeRowsPerPage = async (rowsPerPage) => {
        await setRowsPerPage(rowsPerPage);
        await setPage(0);
        props.fetchPagination(1, rowsPerPage, null, null)

    };

    const handleSearch = async (searchText) => {
        await setPage(0);
        props.fetchPagination(1, rowsPerPage, null, null)
    };

    const handleFilterChange = async (title) => {
        await setPage(0);
        props.fetchPagination(1, rowsPerPage, null, null)
    };

    const refresh = async () => {
        await setPage(0);
        props.fetchPagination(1, rowsPerPage, null, null)
    }

    const popover = (classes) => (
        <Popover id="popover-basic">

            <Popover.Body>
                <table className="table table-bordered fw-normal small mb-0">
                    <tbody>
                        {classes?.map((item, index) =>

                            <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{item?.title}</td>
                                <td>{moment(item?.datetime).local().locale('es').format('lll')}</td>

                            </tr>

                        )}
                    </tbody>
                </table>

            </Popover.Body>
        </Popover>
    );

    const columns = [
        {
            name: "id",
            label: "ID",
            options: {
                display: false,
                filter: false,
                sort: false,
            }
        },
        {
            name: "transaction_no",
            label: "Transaction ID",
            options: {
                filter: false,
                sort: false,
            },

        },
        {
            name: "plan",
            label: "Plan",
            options: {
                filter: false,
                sort: false,
            },

        },
        {
           
            name: "customer_id",
            label: "User",
            options: {
                filter: true,
                sort: false,
                
                customBodyRender: (value, tableMeta, updateValue) => {
                    return  value[0].firstname;
                }
            },

        },
        {
            name: "transaction_amount",
            label: "Amount",
            options: {
                filter: false,
                sort: false,
            },

        },
       
        {
            name: "id",
            label: "ID",
            options: {
                filter: false,
                sort: false,
                display:false
            },

        },
        
        

    
        {
            name: "createdBy",
            label: "createdBy",
            options: {
                filter: false,
                sort: false,
                display: false,
            },

        },
        {
            name: "createdOn",
            label: "Created on",
            options: {
                filter: true,
                sort: true,
                display: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return moment(value).format('lll');
                }
            }
        },

        {
            name: "",
            options: {
                filter: false,
                sort: false,
                empty: true,
                display: location?.pathname === "/residential/subscriptions"?true:false,
                customHeadRender: (columnMeta, handleToggleColumn) => {
                    return (
                        <th key={columnMeta.index} style={{ paddingRight: "16px" }}>
                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end" }}>
                                {/* <FormDialogAddClasses
                                    create={props.create}
                                    refresh={refresh}
                                    type={type}
                                /> */}
                            </div>
                        </th>
                    );
                },
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end" }}>

                            {/* {type === 'multiple' && <FormDialogEditClasses
                                dataClasses={tableMeta.rowData}
                                update={props.update}
                                type={type}
                            />} */}
                            {/* <FormDialogDeleteClasses
                                dataClasses={tableMeta.rowData}
                                delete={props.delete}
                                refresh={refresh}
                            /> */}
                            <a className="btn bg-yellow text-white rounded-0" ><PlayCircle style={{ fontSize: "18px" }} /> Ir a Aula &nbsp;&nbsp;&nbsp;&nbsp;</a>

                        </div>
                    );
                }
            }
        }
    ];

    const options = {

        elevation: 1,
        search: false,
        filter: false,
        filterType: 'textField',
        responsive: 'stacked',
        selectableRows: 'none',
        rowsPerPageOptions: [5, 10, 25],
        serverSide: true,
        viewColumns: false,
        print: false,
        download: false,
        rowsPerPage: rowsPerPage,
        count: props?.meta?.totalDocs || 0,
        page: page,
        textLabels: {
            pagination: {
                next: "Next",
                previous: "previous",
                rowsPerPage: "Page per rows:",
                displayRows: "Page",
              },
        },

        onTableChange: (action, tableState) => {
            switch (action) {
                case 'changePage':
                    handleChangePage(tableState.page)
                    break;

                case 'changeRowsPerPage':
                    handleChangeRowsPerPage(tableState.rowsPerPage)
                    break;

                case 'search':
                    handleSearch(tableState.searchText)
                    break;

                case 'filterChange':
                    handleFilterChange(tableState.filterList[1], tableState.filterList[2])
                    break;

                case 'resetFilters':
                    handleSearch("")
                    break;

                default:
                    break;
            }
        },
    };

    return (
        <MUIDataTable
            // title="Clases"
            data={props.orders}
            columns={columns}
            options={options}
        />
    );
}

const mapStateToProps = state => ({
    orders: state?.order?.orders,
    meta: state?.order?.metaOrder,
    metaCount: state?.order?.metaCount
})

const mapActionToProps = {
    fetchPagination: actions.Pagination,
    create: actions.create,
    update: actions.update,
    delete: actions.Delete,
    getCount: actions.getCount,

}


export default connect(mapStateToProps, mapActionToProps)(SubscriptionsTable);