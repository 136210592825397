import { ACTION_TYPES } from "../actions/inspections";

const initialState = {
    inspections: [],
    metaCourse: {}
}

export const inspections = (state = initialState, action) => {
    switch (action.type) {
        case ACTION_TYPES.INSPECTIONS_FETCH_ALL:
            return {
                ...state,
                inspections: [...action.payload]
            }
        case ACTION_TYPES.INSPECTIONS_CREATE:
            return {
                ...state,
                inspections: [...state.inspections, action.payload]
            }
        case ACTION_TYPES.INSPECTIONS_UPDATE:
            return {
                ...state,
                inspections: state.inspections.map(x => x.id === action.payload.id ? action.payload : x)
            }
        case ACTION_TYPES.INSPECTIONS_DELETE:
            return {
                ...state,
                inspections: state.inspections.filter(x => x.id !== action.payload)
            }
        case ACTION_TYPES.INSPECTIONS_PAGINATION:

            return {
                ...state,
                inspections: [...action.payload.inspections],
                metaInspection: action.payload.meta
            }
        case ACTION_TYPES.INSPECTIONS_FETCH_RANDOM_INSPECTIONS:

            return {
                ...state,
                randomInspections: [...action.payload]
            }
        case ACTION_TYPES.INSPECTIONS_FETCH:
            return {
                ...state,
                inspectionsSingle: [action.payload]
            }
        default:
            return state;
    }
}